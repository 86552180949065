import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IRegistrationResponse } from "../../../Pages/Registration/IRegistration";

export const RegistrationSlicer = createSlice({
  name: "RegistrationSlicer",
  initialState: {
    data: {},
    isLoading: false,
  } as IReduxState<IRegistrationResponse>,
  reducers: {
    registrationStart: (state, _) => {
      state.isLoading = true;
    },
    successRegistration: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    failureRegistration: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearRegistrationStatus: (state) => {
      state.data = {};
    },
  },
});

export const {
  registrationStart,
  successRegistration,
  failureRegistration,
  clearRegistrationStatus,
} = RegistrationSlicer.actions;

export default RegistrationSlicer.reducer;
