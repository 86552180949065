import React, { FunctionComponent, useEffect, useState } from "react";
import { IAPIResponse } from "../../Redux/interfaces/IAPIResponse";
import Dialog from "../Dialog/Dialog";
import { SvgIcon } from "@mui/material";
import ButtonField from "../Button/ButtonField";
import "./ResponseDialog.scss";
import { ReactComponent as success } from "../../Assets/Images/success.svg";
import { ReactComponent as failure } from "../../Assets/Images/failure.svg";
interface ResponseDialogProps {
  response?: IAPIResponse<any>;
  parentDialog?: React.Dispatch<React.SetStateAction<boolean>>;
  okFunction?: () => void;
}

const ResponseDialog: FunctionComponent<ResponseDialogProps> = (props) => {
  const { okFunction, parentDialog, response } = props;

  const handleClose = () => {
    setOpen(false);
    response?.status && parentDialog && parentDialog(false);
    okFunction && response?.status && okFunction();
  };
  const [open, setOpen] = useState<boolean>(false);
  useEffect(() => {
    if (response && Object.keys(response).length !== 0) {
      setOpen(true);
    }
  }, [response]);

  return (
    <Dialog
      open={open}
      dialogContentClassName="response_content"
      Dialog_Content={
        <>
          {response?.message}
          <SvgIcon
            component={response?.status ? success : failure}
            inheritViewBox
            className="response_icon"
          />
        </>
      }
      Dialog_Actions={
        <ButtonField className="response_ok_btn" onClick={() => handleClose()}>
          Ok
        </ButtonField>
      }
    />
  );
};

export default ResponseDialog;
