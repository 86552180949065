import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IRegion } from "../../../Pages/User/IUser";

export const ListRegionSlice = createSlice({
  name: "ListRegionSlice",
  initialState: {} as IReduxState<IRegion[]>,
  reducers: {
    listRegion: (state) => {
      state.isLoading = true;
    },
    successlistRegion: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failurelistRegion: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
  },
});

export const { failurelistRegion, listRegion, successlistRegion } =
  ListRegionSlice.actions;

export default ListRegionSlice.reducer;
