import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IMenu } from "../../../Pages/Menu/IMenu";

export const AddMenuSlice = createSlice({
  name: "AddMenuSlice",
  initialState: {} as IReduxState<IMenu>,
  reducers: {
    addMenu: (state, _: PayloadAction<IMenu>) => {
      state.isLoading = true;
    },
    successaddMenu: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureaddMenu: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearAddMenuResponse: (state) => {
      state.data = {};
    },
  },
});

export const { addMenu, failureaddMenu, successaddMenu, clearAddMenuResponse } =
  AddMenuSlice.actions;

export default AddMenuSlice.reducer;
