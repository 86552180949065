import "./NotAuthorised.scss";

import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import ButtonField from "../../Components/Button/ButtonField";
import { useAppDispatch } from "../../Redux/Store/Hooks";
import { clearLoginStatus } from "../../Redux/Slices/LoginSlicer/LoginSlicer";
import { SvgIcon } from "@mui/material";
import { ReactComponent as Auth } from "./Images/Auth.svg";

interface NotAuthorisedProps {}

const NotAuthorised: FunctionComponent<NotAuthorisedProps> = () => {
  const dispatch = useAppDispatch();

  const nav = useNavigate();
  const handleClick = () => {
    localStorage.clear();
    dispatch(clearLoginStatus());
    nav("/login");
  };
  return (
    <div className="notAuthorised">
      <SvgIcon
        sx={{
          width: "100%",
          height: "35%",
        }}
        color="primary"
        component={Auth}
        inheritViewBox
      />
      <h1> Your not authorised for this page</h1>
      <ButtonField
        className="not_authorised_button"
        type="button"
        onClick={handleClick}
      >
        Go back
      </ButtonField>
    </div>
  );
};

export default NotAuthorised;
