import { forwardRef } from "react";
import { NavLink } from "react-router-dom";
import { INavLink } from "./ISidnavbar";

const IsActiveLink = forwardRef(
  (props: INavLink, ref: React.Ref<HTMLAnchorElement> | undefined) => {
    return (
      <NavLink
        ref={ref}
        {...props}
        className={({ isActive }) =>
          `${props.className} ${isActive && props.activeClassName}`
        }
      />
    );
  }
);

export default IsActiveLink;
