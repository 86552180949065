import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import api from "../../Axios/middleware";
import { failurelistMockExam, successlistMockExam } from "./ListMockExamSlice";

// list
const ListMockExamApi = () => {
  return api.get("/v1/api/exam/mock-exam");
};

export function* ListMockExamCall() {
  try {
    const response: AxiosResponse = yield call(ListMockExamApi);
    yield put(successlistMockExam(response.data));
  } catch (error) {
    yield put(failurelistMockExam(error));
  }
}
