import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IReExamlistID } from "../../../Pages/Exam/IExam";
import { IPendingExam } from "../../../Pages/DashboardUser/IDashboardUser";

export const PendingExamSlice = createSlice({
  name: "PendingExamSlice",
  initialState: {} as IReduxState<IPendingExam[]>,
  reducers: {
    pendingExam: (state, _: PayloadAction<IPendingExam>) => {
      state.isLoading = true;
    },
    successpendingExam: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failurependingExam: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearpendingExam: (state) => {
      state.data = {};
    },
  },
});

export const {
  failurependingExam,
  pendingExam,
  successpendingExam,
  clearpendingExam,
} = PendingExamSlice.actions;

export default PendingExamSlice.reducer;
