import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IUser } from "../../../Pages/User/IUser";

export const EditUserSlice = createSlice({
  name: "EditUserSlice",
  initialState: {} as IReduxState<IUser>,
  reducers: {
    EditUser: (state, _: PayloadAction<IUser>) => {
      state.isLoading = true;
    },
    successEditUser: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureEditUser: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearEditUserResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  EditUser,
  failureEditUser,
  successEditUser,
  clearEditUserResponse,
} = EditUserSlice.actions;

export default EditUserSlice.reducer;
