import "./MessageDialog.scss";
import { FunctionComponent, useEffect, useState } from "react";
import { MessageDialogProps } from "./IMessageDialog";
import Dialog from "../Dialog/Dialog";
import IconButtonField from "../Button/IconButtonField";
import { SvgIcon } from "@mui/material";
import { ReactComponent as Cancel } from "../../Assets/Images/Cancel.svg";
import ButtonField from "../Button/ButtonField";
import { ReactComponent as Success } from "../../Assets/Images/success.svg";
import { ReactComponent as Failure } from "../../Assets/Images/failure.svg";
import ResponseDialog from "../ResponseDialog/ResponseDialog";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../Redux/Store/Hooks";
import { listExamsubmit } from "../../Redux/Slices/ExamAnswer/ListExamsubmitSlice";
import { addCloseSection } from "../../Redux/Slices/Exam/AddCloseSectionSlice";

const MessageDialog: FunctionComponent<MessageDialogProps> = (props) => {
  const {
    open,
    setOpen,
    messageableName,
    messageheading,
    valueofdata,
    exam_id,
    setIsSubmitted,
    student_exam_id,
    remaining_time,
    question_type_id,
  } = props;
  const [responseDialog, setResponseDialog] = useState<boolean>(false);
  const handleClose = () => {
    setOpen(false);
    setResponseDialog(false);
  };
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const final: any = valueofdata;

  return (
    <>
      <Dialog
        open={open}
        dialogClassName="message_dialog"
        dialogTitleClassName="message_dialog_title"
        Dialog_Title={<>{messageheading}</>}
        dialogContentClassName="message_dialog_content"
        Dialog_Content={<>{messageableName}</>}
        dialogActionClassName="message_dialog_action"
        Dialog_Actions={
          <>
            <ButtonField
              className="cancel_button"
              onClick={() => {
                setOpen(false);
                setIsSubmitted && setIsSubmitted(false);
              }}
            >
              Cancel
            </ButtonField>
            <ButtonField
              className="message_button"
              onClick={() => {
                dispatch(
                  listExamsubmit({
                    id: final,
                  })
                );
                dispatch(
                  addCloseSection({
                    student_exam_id: student_exam_id,
                    remaining_time: remaining_time,
                    closed: true,
                    question_type_id: question_type_id,
                  })
                );

                setIsSubmitted && setIsSubmitted(false);
                navigate("/submitexam", { state: final }); // Replace with your target route
              }}
            >
              Submit
            </ButtonField>
          </>
        }
      />
    </>
  );
};

export default MessageDialog;
