import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IReExamlistID } from "../../../Pages/Exam/IExam";

export const ListReExamSlice = createSlice({
  name: "ListReExamIDSlice",
  initialState: {} as IReduxState<IReExamlistID[]>,
  reducers: {
    listReExamID: (state, _: PayloadAction<IReExamlistID>) => {
      state.isLoading = true;
    },
    successlistReExamID: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failurelistReExamID: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearListReExamID: (state) => {
      state.data = {};
    },
  },
});

export const {
  failurelistReExamID,
  listReExamID,
  successlistReExamID,
  clearListReExamID,
} = ListReExamSlice.actions;

export default ListReExamSlice.reducer;
