import { FunctionComponent, useEffect, useRef, useState } from "react";
import "./QuestionType.scss";
import Table from "../../Components/Table/Table";
import CustomToolbar from "../../Components/Table/components/CustomToolbar";
import ButtonField from "../../Components/Button/ButtonField";
import { Columns } from "../../Components/Table/ITable";
import {
  GridRenderCellParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import { IQuestionType } from "./IQuestionType";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import Dialog from "../../Components/Dialog/Dialog";
import QuestionTypeForm from "./QuestionTypeForm";
import ResponseDialog from "../../Components/ResponseDialog/ResponseDialog";
import TableSearch from "../../Components/Table/components/TableSearch";
import { ReactComponent as Delete } from "../../Assets/Images/delete.svg";
import { ReactComponent as Edit } from "../../Assets/Images/edit.svg";
import { IDeleteDialog } from "../../Components/DeleteDialog/IDeleteDialog";
import IconButtonField from "../../Components/Button/IconButtonField";
import { SvgIcon } from "@mui/material";
import DeleteDialog from "../../Components/DeleteDialog/DeleteDialog";
import { ReactComponent as RefreshButton } from "../../Assets/Images/RefreshButton.svg";
import { listQuestionType } from "../../Redux/Slices/QuestionType/ListQuestionTypeSlice";
import { clearAddQuestionTypeResponse } from "../../Redux/Slices/QuestionType/AddQuestionTypeSlice";
import {
  clearDeleteQuestionTypeResponse,
  deleteQuestionType,
} from "../../Redux/Slices/QuestionType/DeleteQuestionTypeSlice";
import { clearEditQuestionTypeResponse } from "../../Redux/Slices/QuestionType/EditQuestionTypeSlice";
import { listSideMenuPermission } from "../../Redux/Slices/SideMenuPermission/ListSideMenuPermissionSlice";

interface QuestionTypeProps {}

const QuestionType: FunctionComponent<QuestionTypeProps> = () => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [deleteDialogData, setDeleteData] = useState<IDeleteDialog>({});
  const [initialValues, setInitialValues] = useState<IQuestionType | {}>({
    status: true,
  });
  const dispatch = useAppDispatch();
  const { data: listQuestionTypes } = useAppSelector(
    (state) => state.ListQuestionTypeSlice
  );
  const { data: addQuestionType } = useAppSelector(
    (state) => state.AddQuestionTypeSlice
  );
  const { data: EditQuestionType } = useAppSelector(
    (state) => state.EditQuestionTypeSlice
  );
  const { data: deleteQuestionTypes } = useAppSelector(
    (state) => state.DeleteQuestionType
  );
  // user permisssion
  const { data: userPermissionlist } = useAppSelector(
    (state) => state.SideMenuPermission
  );

  const to = "masters";

  const filteredList = userPermissionlist.Dashboard.filter(
    (item: any) => item.to === to
  );
  const fliterdata: any = filteredList[0]?.items;
  const finaldata = fliterdata[0];
  console.log(fliterdata);

  const formRef = useRef<HTMLFormElement>(null);
  const columns: Columns = [
    {
      field: "index",
      renderHeader: () => <b>S.No</b>,
      width: 90,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "name",
      renderHeader: () => <b>QuestionType</b>,
      flex: 1,
      type: "string",
      headerAlign: "left",
    },

    {
      field: "status",
      renderHeader: () => <b>Status</b>,
      flex: 1,
      type: "string",
      headerAlign: "left",
      cellClassName: (params) =>
        `activename ${params.row.status ? "active" : "inactive"}`,

      valueFormatter: (params) =>
        params.value === true ? "Active" : "Inactive",
    },
    {
      field: "action",
      renderHeader: () => <b>Action</b>,
      flex: 1,
      type: "actions",
      renderCell: (params: GridRenderCellParams<IQuestionType>) => {
        return (
          <>
            <IconButtonField
              disabled={finaldata?.permissions?.edit === false}
              onClick={() => {
                setInitialValues({ ...params.row });
                setDialogOpen(true);
              }}
            >
              <SvgIcon sx={{ fill: "none" }} component={Edit} inheritViewBox />
            </IconButtonField>
            <IconButtonField
              disabled={finaldata?.permissions?.delete === false}
              onClick={() => {
                setDeleteData({ id: params.id, name: params?.row?.name });
                setDeleteDialogOpen(true);
              }}
            >
              <SvgIcon component={Delete} inheritViewBox />
            </IconButtonField>
          </>
        );
      },
    },
  ];

  const QuestionType = Array.isArray(listQuestionTypes?.data)
    ? listQuestionTypes?.data.map((user, index) => ({
        ...user,
        index: index + 1,
      }))
    : [];

  useEffect(() => {
    dispatch(listQuestionType());
    dispatch(listSideMenuPermission());
  }, [addQuestionType, EditQuestionType, deleteQuestionTypes, dispatch]);
  useEffect(() => {
    return () => {
      dispatch(clearAddQuestionTypeResponse());
      dispatch(clearEditQuestionTypeResponse());
      dispatch(clearDeleteQuestionTypeResponse());
    };
  }, [dispatch]);
  return (
    <>
      <Table rows={QuestionType ?? []} columns={columns}>
        <CustomToolbar>
          <h6>QuestionType</h6>
          <TableSearch />
          <ButtonField
            className="tabel_add_new_button"
            onClick={() => setDialogOpen(true)}
            toolTip="Add new"
            disabled={finaldata?.permissions?.add === false}
          >
            Add new
          </ButtonField>
          <IconButtonField
            className="refresh_btn"
            toolTip="Refresh"
            onClick={() => dispatch(listQuestionType())}
          >
            <SvgIcon
              className="refresh_svg_icon"
              component={RefreshButton}
              inheritViewBox
            />
          </IconButtonField>
        </CustomToolbar>
      </Table>

      <Dialog
        open={dialogOpen}
        dialogClassName="dialog"
        Dialog_Title={`${
          (initialValues as IQuestionType).id ? "Update" : "Add"
        } QuestionType`}
        dialogContentClassName="dialog_content"
        Dialog_Content={
          <QuestionTypeForm
            formRef={formRef}
            initialValues={initialValues}
            setInitialValues={setInitialValues}
          />
        }
        Dialog_Actions={
          <>
            <ButtonField
              className="form_cancel_button"
              onClick={() => setDialogOpen(false)}
            >
              Cancel
            </ButtonField>
            <ButtonField
              className="form_save_button"
              onClick={() => formRef.current?.requestSubmit()}
            >
              Save
            </ButtonField>
          </>
        }
      />
      <ResponseDialog response={addQuestionType} parentDialog={setDialogOpen} />
      <ResponseDialog
        response={EditQuestionType}
        parentDialog={setDialogOpen}
      />
      <DeleteDialog
        deleteableName={deleteDialogData.name ?? ""}
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        deleteFunction={() => {
          dispatch(deleteQuestionType(deleteDialogData.id));
        }}
        response={deleteQuestionTypes}
      />
    </>
  );
};

export default QuestionType;
