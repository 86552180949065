import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IExamlistsubmit } from "../../../Pages/Exam/IExam";

export const ListExamsubmitSlice = createSlice({
  name: "ListExamsubmitSlice",
  initialState: {} as IReduxState<IExamlistsubmit[]>,
  reducers: {
    listExamsubmit: (state, _: PayloadAction<IExamlistsubmit>) => {
      state.isLoading = true;
    },
    successlistExamsubmit: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failurelistExamsubmit: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearListExamsubmit: (state) => {
      state.data = {};
    },
  },
});

export const {
  failurelistExamsubmit,
  listExamsubmit,
  successlistExamsubmit,
  clearListExamsubmit,
} = ListExamsubmitSlice.actions;

export default ListExamsubmitSlice.reducer;
