import "./TextAreaInputField.scss";

import { FunctionComponent, useState } from "react";
import { TextField } from "@mui/material";
import { TextAreaInputFieldProps } from "./ITextAreaInputFieldProps";
import { Controller, useFormContext } from "react-hook-form";

const TextAreaInputField: FunctionComponent<TextAreaInputFieldProps> = (
  props
) => {
  const {
    id,
    name,
    placeholder,
    variant,
    label,
    color,
    required,
    className,
    sx,
    value,
    defaultValue,
    error,
    disabled,
    readOnly,
    fullWidth,
    type,
    inputClassName,
    helperText,
    InputProps,
    shrink,
    inputLableStyle,
    size,
    labelSize,
    onChange,
    onBlur,
    onFocus,

    rows,
    maxRows,
    minRows,
  } = props;
  const [focus, setFocus] = useState<boolean>(false);
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      name={name!}
      control={control}
      render={({ field }) => (
        <TextField
          {...field}
          multiline
          rows={rows}
          maxRows={maxRows}
          minRows={minRows}
          id={id}
          name={name}
          placeholder={placeholder}
          variant={variant}
          label={label}
          color={color}
          required={required}
          fullWidth={fullWidth}
          className={`GlobalInputStyle ${className}`}
          sx={sx}
          // value={value}
          defaultValue={defaultValue}
          error={!!errors[name]}
          helperText={errors[name] ? String(errors[name]?.message) : ""}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          disabled={disabled}
          type={type ?? "text"}
          size={size}
          InputProps={{
            ...InputProps,
            readOnly: readOnly,
            className: `GlobalInputPropsStyle ${inputClassName}`,
          }}
          InputLabelProps={{
            shrink: !!field.value || focus,
            size: labelSize,
            className: `GlobalInputLableStyle ${inputLableStyle}`,
          }}
        />
      )}
    />
  );
};

export default TextAreaInputField;
