import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IStudentExam } from "../../../Pages/Exam/IExam";

export const PopupStudentExamSlice = createSlice({
  name: "PopupStudentExamSlice",
  initialState: {} as IReduxState<IStudentExam>,
  reducers: {
    PopupStudentExam: (state, _: PayloadAction<IStudentExam>) => {
      state.isLoading = true;
    },
    successPopupStudentExam: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failurePopupStudentExam: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },

    clearPopupStudentExamResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  PopupStudentExam,
  failurePopupStudentExam,
  successPopupStudentExam,
  clearPopupStudentExamResponse,
} = PopupStudentExamSlice.actions;

export default PopupStudentExamSlice.reducer;
