import "./SessionExpired.scss";

import { FunctionComponent, useEffect } from "react";
import Dialog from "../Dialog/Dialog";
import ButtonField from "../Button/ButtonField";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import { sessionExpired } from "../../Redux/Slices/SessionExpired/SessionExpired";
import { SvgIcon } from "@mui/material";
import { ReactComponent as SessionExpired } from "../../Assets/Images/failure.svg";
import Session_expiredSound from "../../Assets/Sounds/SessionExpired.mp3";

interface SessionExpiredDialogProps {}

const SessionExpiredDialog: FunctionComponent<
  SessionExpiredDialogProps
> = () => {
  const nav = useNavigate();
  const dispatch = useAppDispatch();
  const { data } = useAppSelector((state) => state.SessionExpired);

  useEffect(() => {
    if (data) {
      const sounds = new Audio(Session_expiredSound);
      sounds.volume = 0.5;
      sounds
        .play()
        .then(() => {
          console.log("Audio played successfully");
        })
        .catch((error) => {
          console.error("Audio playback failed", error);
        });
    }
  }, [data]);

  return (
    <Dialog
      open={data}
      dialogClassName="session_expired_dialog"
      dialogTitleClassName="session_expired_dialog_title"
      Dialog_Title={"Session Expired"}
      dialogContentClassName="session_expired_dialog_content"
      Dialog_Content={
        <>
          <SvgIcon
            className="session_expired_icon"
            component={SessionExpired}
            inheritViewBox
          />
          <div className="session_expired_text">Your session has expired.</div>
          <div className="redirect_text">
            You will be redirected to the Login page
          </div>
        </>
      }
      dialogActionClassName="session_expired_action"
      Dialog_Actions={
        <ButtonField
          className="ok_button"
          onClick={() => {
            dispatch(sessionExpired(false));
            nav("/login");
            localStorage.clear();
          }}
        >
          OK
        </ButtonField>
      }
    />
  );
};

export default SessionExpiredDialog;
