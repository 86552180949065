import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { ILoginResponse } from "../../../Pages/Login/ILogin";

export const LoginSlicer = createSlice({
  name: "LoginSlicer",
  initialState: {
    data: {},
    isLoading: false,
  } as IReduxState<ILoginResponse>,
  reducers: {
    loginStart: (state, _) => {
      state.isLoading = true;
    },
    successLogin: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    failureLogin: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearLoginStatus: (state) => {
      state.data = {};
    },
  },
});

export const { loginStart, successLogin, failureLogin, clearLoginStatus } =
  LoginSlicer.actions;

export default LoginSlicer.reducer;
