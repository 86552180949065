import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { ICourseslist } from "../../../Pages/Courses/ICourses";

export const AddCoursesSlice = createSlice({
  name: "AddCoursesSlice",
  initialState: {} as IReduxState<ICourseslist>,
  reducers: {
    addCourses: (state, _: PayloadAction<ICourseslist>) => {
      state.isLoading = true;
    },
    successaddCourses: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureaddCourses: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearAddCoursesResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  addCourses,
  failureaddCourses,
  successaddCourses,
  clearAddCoursesResponse,
} = AddCoursesSlice.actions;

export default AddCoursesSlice.reducer;
