import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IPackagelist } from "../../../Pages/Package/IPackage";

export const EditPackageSlice = createSlice({
  name: "EditPackageSlice",
  initialState: {} as IReduxState<IPackagelist>,
  reducers: {
    EditPackage: (state, _: PayloadAction<IPackagelist>) => {
      state.isLoading = true;
    },
    successEditPackage: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureEditPackage: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearEditPackageResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  EditPackage,
  failureEditPackage,
  successEditPackage,
  clearEditPackageResponse,
} = EditPackageSlice.actions;

export default EditPackageSlice.reducer;
