import { FunctionComponent, useEffect, useState } from "react";

const NetworkStatus: FunctionComponent = () => {
  const [status, setStatus] = useState(true);
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    function changeStatus() {
      setStatus(navigator.onLine);
      if (navigator.onLine) {
        setShowMessage(true);
        setTimeout(() => {
          setShowMessage(false);
        }, 3000); // Show message for 5 seconds when online
      } else {
        setShowMessage(true); // Show offline message until online again
      }
    }

    window.addEventListener("online", changeStatus);
    window.addEventListener("offline", changeStatus);

    return () => {
      window.removeEventListener("online", changeStatus);
      window.removeEventListener("offline", changeStatus);
    };
  }, []);

  return (
    <div
      style={{
        position: "absolute",
        top: "0px",
        zIndex: "1111111111",
        display: "flex",
        justifyContent: "center",
        backgroundColor: status ? "green" : "red", // Change color based on status
        color: "white",
        fontSize: "12px",
        width: "100%",
      }}
    >
      {status
        ? showMessage && <div>Connected to the network!</div>
        : "You are in offline !"}
    </div>
  );
};

export default NetworkStatus;
