import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IUserProfile } from "../../../Pages/UserProfile/IUserProfile";

export const ListUserProfileSlice = createSlice({
  name: "ListUserProfileSlice",
  initialState: {} as IReduxState<IUserProfile[]>,
  reducers: {
    listUserProfile: (state) => {
      state.isLoading = true;
    },
    successlistUserProfile: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failurelistUserProfile: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  failurelistUserProfile,
  listUserProfile,
  successlistUserProfile,
} = ListUserProfileSlice.actions;

export default ListUserProfileSlice.reducer;
