import { FunctionComponent, useEffect, useRef, useState } from "react";
import "./ExamModel.scss";
import Table from "../../Components/Table/Table";
import CustomToolbar from "../../Components/Table/components/CustomToolbar";
import ButtonField from "../../Components/Button/ButtonField";
import { Columns } from "../../Components/Table/ITable";
import {
  GridRenderCellParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import { IExamModel } from "./IExamModel";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import Dialog from "../../Components/Dialog/Dialog";
import ExamModelForm from "./ExamModelForm";
import ResponseDialog from "../../Components/ResponseDialog/ResponseDialog";
import TableSearch from "../../Components/Table/components/TableSearch";
import { ReactComponent as Delete } from "../../Assets/Images/delete.svg";
import { ReactComponent as Edit } from "../../Assets/Images/edit.svg";
import { IDeleteDialog } from "../../Components/DeleteDialog/IDeleteDialog";
import IconButtonField from "../../Components/Button/IconButtonField";
import { SvgIcon } from "@mui/material";
import DeleteDialog from "../../Components/DeleteDialog/DeleteDialog";
import { ReactComponent as RefreshButton } from "../../Assets/Images/RefreshButton.svg";
import { listExamModel } from "../../Redux/Slices/ExamModel/ListExamModelSlice";
import { clearAddExamModelResponse } from "../../Redux/Slices/ExamModel/AddExamModelSlice";
import {
  clearDeleteExamModelResponse,
  deleteExamModel,
} from "../../Redux/Slices/ExamModel/DeleteExamModelSlice";
import { clearEditExamModelResponse } from "../../Redux/Slices/ExamModel/EditExamModelSlice";
import { listExam } from "../../Redux/Slices/Exam/ListExamSlice";
import { listQuestionType } from "../../Redux/Slices/QuestionType/ListQuestionTypeSlice";
import { listSideMenuPermission } from "../../Redux/Slices/SideMenuPermission/ListSideMenuPermissionSlice";

interface ExamModelProps {}

const ExamModel: FunctionComponent<ExamModelProps> = () => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [deleteDialogData, setDeleteData] = useState<IDeleteDialog>({});
  const [initialValues, setInitialValues] = useState<IExamModel | {}>({
    status: true,
  });
  const dispatch = useAppDispatch();
  const { data: listExamModels } = useAppSelector(
    (state) => state.ListExamModelSlice
  );
  const { data: addExamModel } = useAppSelector(
    (state) => state.AddExamModelSlice
  );
  const { data: EditExamModel } = useAppSelector(
    (state) => state.EditExamModelSlice
  );
  const { data: deleteExamModels } = useAppSelector(
    (state) => state.DeleteExamModel
  );
  // user permisssion
  const { data: userPermissionlist } = useAppSelector(
    (state) => state.SideMenuPermission
  );

  const to = "masters";

  const filteredList = userPermissionlist.Dashboard.filter(
    (item: any) => item.to === to
  );
  const fliterdata: any = filteredList[0]?.items;
  const finaldata = fliterdata[2];

  const formRef = useRef<HTMLFormElement>(null);
  const columns: Columns = [
    {
      field: "index",
      renderHeader: () => <b>S.No</b>,
      width: 90,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "model_name",
      renderHeader: () => <b>Model Name</b>,
      flex: 1,
      type: "string",
      headerAlign: "left",
    },
    {
      field: "status",
      renderHeader: () => <b>Status</b>,
      flex: 1,
      type: "string",
      headerAlign: "left",
      cellClassName: (params) =>
        `activename ${params.row.status ? "active" : "inactive"}`,

      valueFormatter: (params) =>
        params.value === true ? "Active" : "Inactive",
    },

    {
      field: "action",
      renderHeader: () => <b>Action</b>,
      flex: 1,
      type: "actions",
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            <IconButtonField
              disabled={finaldata?.permissions?.edit === false}
              onClick={() => {
                setInitialValues({ ...params.row });
                setDialogOpen(true);
              }}
            >
              <SvgIcon sx={{ fill: "none" }} component={Edit} inheritViewBox />
            </IconButtonField>
            <IconButtonField
              disabled={finaldata?.permissions?.delete === false}
              onClick={() => {
                setDeleteData({ id: params.id, name: params?.row?.exam });
                setDeleteDialogOpen(true);
              }}
            >
              <SvgIcon component={Delete} inheritViewBox />
            </IconButtonField>
          </>
        );
      },
    },
  ];

  const ExamModel = Array.isArray(listExamModels?.data)
    ? listExamModels?.data.map((user, index) => ({ ...user, index: index + 1 }))
    : [];

  useEffect(() => {
    dispatch(listExamModel());
    dispatch(listQuestionType());
    dispatch(listSideMenuPermission());
  }, [addExamModel, EditExamModel, deleteExamModels, dispatch]);
  useEffect(() => {
    return () => {
      dispatch(clearAddExamModelResponse());
      dispatch(clearEditExamModelResponse());
      dispatch(clearDeleteExamModelResponse());
    };
  }, [dispatch]);
  return (
    <>
      <Table rows={ExamModel ?? []} columns={columns}>
        <CustomToolbar>
          <h6>Exam Model</h6>
          <TableSearch />
          <ButtonField
            className="tabel_add_new_button"
            onClick={() => setDialogOpen(true)}
            toolTip="Add new"
            disabled={finaldata?.permissions?.add === false}
          >
            Add new
          </ButtonField>
          <IconButtonField
            className="refresh_btn"
            toolTip="Refresh"
            onClick={() => dispatch(listExamModel())}
          >
            <SvgIcon
              className="refresh_svg_icon"
              component={RefreshButton}
              inheritViewBox
            />
          </IconButtonField>
        </CustomToolbar>
      </Table>

      <Dialog
        open={dialogOpen}
        dialogClassName="dialog_mid_large"
        Dialog_Title={`${
          (initialValues as IExamModel).id ? "Update" : "Add"
        } Exam Model`}
        // dialogContentClassName="dialog_content"
        Dialog_Content={
          <ExamModelForm
            formRef={formRef}
            initialValues={initialValues}
            setInitialValues={setInitialValues}
          />
        }
        Dialog_Actions={
          <>
            <ButtonField
              className="form_cancel_button"
              onClick={() => setDialogOpen(false)}
            >
              Cancel
            </ButtonField>
            <ButtonField
              className="form_save_button"
              onClick={() => formRef.current?.requestSubmit()}
            >
              Save
            </ButtonField>
          </>
        }
      />
      <ResponseDialog response={addExamModel} parentDialog={setDialogOpen} />
      <ResponseDialog response={EditExamModel} parentDialog={setDialogOpen} />
      <DeleteDialog
        deleteableName={deleteDialogData.name ?? ""}
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        deleteFunction={() => {
          dispatch(deleteExamModel(deleteDialogData.id));
        }}
        response={deleteExamModels}
      />
    </>
  );
};

export default ExamModel;
