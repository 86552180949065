import "./Sidnavbar.scss";

import * as React from "react";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Collapse,
  ListSubheader,
  Paper,
  Popover,
  SvgIcon,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { IMenuItem } from "./ISidnavbar";
import { ReactComponent as Logo } from "../../Assets/Images/loginlogo.svg";
import { ReactComponent as MiniLogo } from "../../Assets/Images/MiniLogo.svg";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import IconButtonField from "../Button/IconButtonField";
import Avatars from "../Avatar/Avatars";
import IsActiveLink from "./IsActiveLink";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import SearchPopup from "./Search";
import Menus from "../Menu/Menu";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import { clearLoginStatus } from "../../Redux/Slices/LoginSlicer/LoginSlicer";
import { decode } from "../../Utils/encodeDecode";
// import { IUserMaster } from "../../Pages/UserMaster/IUserMaster";
import { ReactComponent as Settings } from "../../Assets/Images/Settings.svg";
import { setTheme } from "../../Utils/setTheme";
import { themeUpdate } from "../../Redux/Slices/Theme/ThemeSlice";

const drawerWidth = 200;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  borderRight: "1px dashed rgba(0, 0, 0, 0.12)",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  borderRight: "1px dashed rgba(0, 0, 0, 0.12)",
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
  smBreackPoint?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open, smBreackPoint }) => ({
  backgroundColor: "#FFFFFF",
  color: "black",
  boxShadow: "none",
  zIndex: theme.zIndex.drawer + (smBreackPoint ? 0 : 1),
  width: smBreackPoint ? "100%" : "calc(100% - 65px)",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open &&
    !smBreackPoint && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
}));

const IconWithDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  const nav = useNavigate();

  const dispatch = useAppDispatch();
  // main drawer open
  const [open, setOpen] = React.useState(true);
  // mobile drawer open
  const [mobileOpen, setMobileOpen] = React.useState(false);
  // mobile screen break point by mui
  const smBreackPoint = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  // user details
  // const [userDetails, setUserDetails] = React.useState<IUserMaster>();
  // theme state
  const [themeSettingsOpen, setThemeSettingsOpen] =
    React.useState<boolean>(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDrawerOpen = () => {
    smBreackPoint ? handleDrawerToggle() : setOpen(!open);
  };

  React.useEffect(() => {
    if (smBreackPoint) {
      setOpen(true);
    }
  }, [setOpen, smBreackPoint]);

  // React.useEffect(() => {
  //   const details = decode(localStorage.getItem("details"));
  //   if (details) {
  //     setUserDetails(details);
  //   }
  // }, []);

  const { data: menu } = useAppSelector((state) => state.SideMenuPermission);

  // drawer menu list
  const drawer = (
    <List>
      {menu &&
        Object.keys(menu).map((keyName, key) => {
          return (
            <React.Fragment key={key}>
              {open && keyName !== "Dashboard" && (
                <ListSubheader component="div" className="listSubHeader">
                  {keyName}
                </ListSubheader>
              )}
              {menu[keyName].map((item, key) => {
                return (
                  item?.permissions?.show &&
                  item?.permissions?.view && (
                    <MenuItems
                      key={key}
                      item={item}
                      mini={!open}
                      setMobileOpen={setMobileOpen}
                    />
                  )
                );
              })}
            </React.Fragment>
          );
        })}
    </List>
  );

  // search dialog open
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <CssBaseline />
      {/*     Header     */}
      <AppBar
        position="fixed"
        open={open}
        smBreackPoint={smBreackPoint}
        className="app_bar"
      >
        <Toolbar
          sx={{
            gap: "8px",
          }}
        >
          {/* drawer open close icon */}
          {smBreackPoint ? (
            <IconButton
              className="navigate_icon_button"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{}}
            >
              <MenuRoundedIcon />
            </IconButton>
          ) : (
            <IconButton
              className="navigate_icon_button"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                position: "absolute",
                left: "3px",
                padding: "0",
                width: "16px",
                height: "16px",
                border: "1px dashed rgba(0, 0, 0, 0.12)",
                // ...(open &&
                //   !smBreackPoint && { display: "none", marginRight: 5 }),
              }}
            >
              {open ? (
                <NavigateBeforeIcon sx={{ color: "#637381" }} />
              ) : (
                <NavigateNextIcon sx={{ color: "#637381" }} />
              )}
            </IconButton>
          )}
          {/* search icon */}
          {/* <IconButtonField
            onClick={() => {
              setDialogOpen(true);
            }}
          >
            <SvgIcon
              sx={{
                width: "20px",
                height: "20px",
              }}
              component={Search}
              inheritViewBox
            />
          </IconButtonField> */}
          {/* <NotificationsIcon /> */}
          {/* <Notifications
            notificationHeader={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "5px 10px",
                }}
              >
                <Typography sx={{ fontWeight: "600", color: "gray" }}>
                  Notifications
                </Typography>
                <Tooltip title="Mark all read">
                  <IconButton>
                    <SvgIcon component={Mail} inheritViewBox />
                  </IconButton>
                </Tooltip>
              </div>
            }
            notificationItems={notificationMenu}
          >
            <IconButtonField>
              <Badge badgeContent={4} color="error">
                <SvgIcon
                  // sx={{
                  //   width: "20px",
                  //   height: "20px",
                  // }}
                  component={Notification}
                  inheritViewBox
                />
              </Badge>
            </IconButtonField>
          </Notifications> */}
          {/* user profile */}
          {/* <div className="app_bar_user_details">
            {userDetails?.name && (
              <div className="name">{userDetails?.name}</div>
            )}
            {userDetails?.locname && <div>({userDetails?.locname})</div>}
          </div> */}
          <Menus
            menuItems={[
              // {
              //   menu: "Profile",
              //   onClick() {},
              // },
              {
                menu: "Logout",
                onClick: () => {
                  localStorage.clear();
                  dispatch(clearLoginStatus());
                  nav("/login");
                },
              },
            ]}
          >
            <IconButtonField onClick={() => {}}>
              <Avatars sx={{ height: "2rem", width: "2rem" }} />
            </IconButtonField>
          </Menus>

          {/* theme */}
          {/* <IconButtonField onClick={() => setThemeSettingsOpen(true)}>
            <SvgIcon
              component={Settings}
              inheritViewBox
              sx={{ fill: "none", stroke: "currentColor" }}
            />
          </IconButtonField> */}

          <Drawer
            open={themeSettingsOpen}
            onClose={() => setThemeSettingsOpen(false)}
            anchor={"right"}
            sx={{
              "& .MuiDrawer-paper": {
                padding: "8px",
                width: drawerWidth,
              },
            }}
          >
            <div style={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
              {[
                { primary_color: "#00bfff" },
                { primary_color: "#f00" },
                { primary_color: "#ff00fb" },
                { primary_color: "#ff000f" },
                { primary_color: "#259116" },
              ].map((palateColor) => {
                return (
                  <div
                    onClick={() => {
                      setTheme(palateColor.primary_color, "--primary-color");
                      dispatch(
                        themeUpdate({
                          primary_color: palateColor.primary_color,
                        })
                      );
                    }}
                    style={{
                      backgroundColor: palateColor.primary_color,
                      width: 30,
                      height: 30,
                    }}
                  />
                );
              })}
            </div>
          </Drawer>
          {/* <NotificationsIcon /> */}
        </Toolbar>
      </AppBar>

      {/*     Navbar     */}
      <Box
        component="nav"
        sx={{
          flexShrink: { sm: 0 },
        }}
        aria-label="mailbox folders"
      >
        {/* main drawer open and close */}
        <IconWithDrawer
          variant="permanent"
          open={open && !smBreackPoint}
          className="drawer"
          sx={{
            display: { xs: "none", sm: "block" },
            flexGrow: "1",
          }}
        >
          <DrawerHeader className="drawer_header">
            <>
              <SvgIcon
                sx={{
                  width: !open ? 100 : "123px",
                  height: !open ? 30 : "25px",
                  fill: "currentcolor",
                }}
                color="primary"
                component={open ? Logo : MiniLogo}
                inheritViewBox
              />
            </>
          </DrawerHeader>
          {/* <Divider /> */}
          {drawer}
        </IconWithDrawer>

        {/* mobile screen drawer */}
        <Drawer
          variant="temporary"
          className="drawer"
          open={mobileOpen && smBreackPoint}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          <DrawerHeader className="drawer_header">
            <>
              <SvgIcon
                sx={{
                  width: "123px",
                  height: "25px",
                }}
                component={Logo}
                inheritViewBox
              />
            </>
          </DrawerHeader>
          {/* <Divider /> */}
          {drawer}
        </Drawer>
      </Box>

      {/*     Main content     */}
      <Box
        component="main"
        className="mainSection"
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          height: "100%",
          // height: "fit-content",
        }}
      >
        {/*     for header space     */}
        <DrawerHeader className="drawer_header" />

        {/*     main content     */}
        {/* <TestArea /> */}
        <Outlet />
      </Box>

      {/*     Search     */}
      <SearchPopup dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} />
    </Box>
  );
}

/*    check if it has child or not     */
function hasChildren(item: IMenuItem) {
  const { items: children } = item;

  if (children === undefined) {
    return false;
  }

  if (children.constructor !== Array) {
    return false;
  }

  if (children.length === 0) {
    return false;
  }

  return true;
}

const MenuItems: React.FC<{
  item: IMenuItem;
  mini: boolean;
  child?: boolean;
  setMobileOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ item, mini, child = false, setMobileOpen }) => {
  const Component = hasChildren(item) ? MultiLevel : SingleLevel;
  return (
    <Component
      item={item}
      mini={mini}
      child={child}
      setMobileOpen={setMobileOpen}
    />
  );
};

const SingleLevel: React.FC<{
  item: IMenuItem;
  mini: boolean;
  child: boolean;
  setMobileOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ item, mini, child, setMobileOpen }) => {
  return (
    <ListItem disablePadding>
      <ListItemButton
        component={IsActiveLink}
        to={item.to!}
        className={mini ? "miniListButton" : "listButton"}
        sx={{ flexDirection: mini ? "column" : "row" }}
        activeClassName={child ? "activeSubMenu" : "activeMenu"}
        onClick={() => (setMobileOpen ? setMobileOpen(false) : undefined)}
        end
      >
        <ListItemIcon sx={{ minWidth: "0", marginRight: mini ? "0" : "8px" }}>
          {item.icon}
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            className: "listText",
            sx: { fontSize: mini ? "0.7rem" : "1rem" },
          }}
          primary={item.title}
          // sx={{ whiteSpace: mini ? "nowrap" : "pre-wrap" }}
        />
      </ListItemButton>
    </ListItem>
  );
};

// MultiLevel Component
const MultiLevel: React.FC<{
  item: IMenuItem;
  mini: boolean;
  setMobileOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ item, mini, setMobileOpen }) => {
  const { items: children } = item;
  const [open, setOpen] = React.useState(false);
  // const handleClick = () => {
  //   setOpen((prev) => !prev);
  // };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(!open);
    setAnchorEl(open ? null : event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  let handleOutsideClick = (event: MouseEvent) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(event.target as Node)
    ) {
      handleClose();
    }
    // handleClose();
  };

  React.useEffect(() => {
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const location = useLocation();
  const currentPath = location.pathname;

  const isSelected = (path: string) => {
    return currentPath.split("/").includes(path);
  };

  const checkActiveSubmenu = () => {
    if (isSelected(item.to ?? "")) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  React.useEffect(() => {
    checkActiveSubmenu();
    if (mini) {
      handleClose();
    }
  }, [currentPath, mini]);

  return (
    <React.Fragment>
      <ListItem onClick={handleClick} disablePadding>
        <ListItemButton
          selected={isSelected(item.to!)}
          className={
            mini
              ? `miniListButton ${isSelected(item.to!) && "activeMenu"}`
              : `listButton ${isSelected(item.to!) && "activeMenu"}`
          }
          sx={{
            flexDirection: mini ? "column" : "row",
          }}
        >
          <ListItemIcon sx={{ minWidth: "0", marginRight: mini ? "0" : "8px" }}>
            {item.icon}
            {mini && (open ? <NavigateBeforeIcon /> : <NavigateNextIcon />)}
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{
              className: "listText",
              sx: { fontSize: mini ? "0.7rem" : "1rem" },
            }}
            primary={item.title}
            // sx={{ whiteSpace: mini ? "nowrap" : "pre-wrap" }}
          />
          <ListItemIcon sx={{ justifyContent: "flex-end", minWidth: 0 }}>
            {!mini && (open ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
          </ListItemIcon>
        </ListItemButton>
      </ListItem>
      {mini ? (
        // mini screen
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Paper>
            <List>
              {children?.map((child, key: number) => {
                return (
                  child.permissions?.show &&
                  child.permissions?.view && (
                    <React.Fragment key={key}>
                      {child.to && !child.items?.length ? (
                        <ListItemButton
                          component={IsActiveLink}
                          activeClassName={"activeSubMenu"}
                          className={
                            !!isSelected(child.to) ? "activeSubMenu" : ""
                          }
                          to={child.to}
                          onClick={(event) => {
                            handleOutsideClick(event as unknown as MouseEvent);
                            setMobileOpen && setMobileOpen(false);
                          }}
                        >
                          <ListItemText
                            primaryTypographyProps={{
                              className: "listText",
                              sx: { fontSize: mini ? "0.7rem" : "1rem" },
                            }}
                            primary={child.title}
                          />
                        </ListItemButton>
                      ) : (
                        <MenuItems key={key} item={child} mini={mini} />
                      )}
                    </React.Fragment>
                  )
                );
              })}
            </List>
          </Paper>
        </Popover>
      ) : (
        // main screen
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ pl: 1 }}>
            {children?.map(
              (child, key: number) =>
                child.permissions?.show &&
                child.permissions?.view && (
                  <MenuItems
                    key={key}
                    item={child}
                    mini={mini}
                    child={!!item.items}
                    setMobileOpen={setMobileOpen}
                  />
                )
            )}
          </List>
        </Collapse>
      )}
    </React.Fragment>
  );
};
