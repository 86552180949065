import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import api from "../../Axios/middleware";
import {
  failureaddExamAnswer,
  successaddExamAnswer,
} from "./AddExamAnswerSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  failuredeleteExamAnswer,
  successdeleteExamAnswer,
} from "./DeleteExamAnswerSlice";
import {
  failurelistExamAnswer,
  successlistExamAnswer,
} from "./ListExamAnswerSlice";
import {
  failureEditExamAnswer,
  successEditExamAnswer,
} from "./EditExamAnswerSlice";
import { IExamAnswer } from "../../../Pages/Exam participation/IExam_participation";
import {
  failurelistExamsubmit,
  successlistExamsubmit,
} from "./ListExamsubmitSlice";
import { IExamlistsubmit } from "../../../Pages/Exam/IExam";

// list
const ListExamAnswerApi = (action: PayloadAction<IExamAnswer>) => {
  return api.get("/v1/api/exam_answer/exam-answer", { params: action.payload });
};

export function* ListExamAnswerCall(action: PayloadAction<IExamAnswer>) {
  try {
    const response: AxiosResponse = yield call(ListExamAnswerApi, action);
    yield put(successlistExamAnswer(response.data));
  } catch (error) {
    yield put(failurelistExamAnswer(error));
  }
}

// list exam submit

const ListExamsubmitApi = (action: PayloadAction<IExamlistsubmit>) => {
  return api.get("/v1/api/exam_answer/submission", { params: action.payload });
};

export function* ListExamsubmitCall(action: PayloadAction<IExamlistsubmit>) {
  try {
    const response: AxiosResponse = yield call(ListExamsubmitApi, action);
    yield put(successlistExamsubmit(response.data));
  } catch (error) {
    yield put(failurelistExamsubmit(error));
  }
}

// add
const AddExamAnswerApi = (action: PayloadAction<IExamAnswer>) => {
  return api.post("/v1/api/exam_answer/create", action.payload);
};

export function* AddExamAnswerCall(action: PayloadAction<IExamAnswer>) {
  try {
    const response: AxiosResponse = yield call(AddExamAnswerApi, action);
    yield put(successaddExamAnswer(response.data));
  } catch (error) {
    yield put(failureaddExamAnswer(error));
  }
}

// edit
const EditExamAnswerApi = (action: PayloadAction<IExamAnswer>) => {
  return api.put("/v1/api/exam_answer/update/", action.payload, {
    params: { id: action.payload.id },
  });
};

export function* EditExamAnswerCall(action: PayloadAction<IExamAnswer>) {
  try {
    const response: AxiosResponse = yield call(EditExamAnswerApi, action);
    yield put(successEditExamAnswer(response.data));
  } catch (error) {
    yield put(failureEditExamAnswer(error));
  }
}

// delete
const DeleteExamAnswerApi = (action: PayloadAction<number>) => {
  return api.delete("/v1/api/exam_answer/delete/", {
    params: { role_id: action.payload },
  });
};

export function* DeleteExamAnswerCall(action: PayloadAction<number>) {
  try {
    const response: AxiosResponse = yield call(DeleteExamAnswerApi, action);
    yield put(successdeleteExamAnswer(response.data));
  } catch (error) {
    yield put(failuredeleteExamAnswer(error));
  }
}
