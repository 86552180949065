import { FunctionComponent } from "react";
import { ICustomFooter } from "../ITable";
import { GridFooterContainer } from "@mui/x-data-grid";

const CustomFooter: FunctionComponent<ICustomFooter> = (props) => {
  const { children, sx, className } = props;
  return (
    <GridFooterContainer className={className} sx={sx}>
      {children}
    </GridFooterContainer>
  );
};

export default CustomFooter;
