import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import api from "../../Axios/middleware";
import { failureaddPackage, successaddPackage } from "./AddPackageSlice";
import { PayloadAction } from "@reduxjs/toolkit";

import {
  failuredeletePackage,
  successdeletePackage,
} from "./DeletePackageSlice";
import { failurelistPackage, successlistPackage } from "./ListPackageSlice";
import { failureEditPackage, successEditPackage } from "./EditPackageSlice";
import { IPackagelist } from "../../../Pages/Package/IPackage";

// list
const ListPackageApi = () => {
  return api.get("/v1/api/package/");
};

export function* ListPackageCall() {
  try {
    const response: AxiosResponse = yield call(ListPackageApi);
    yield put(successlistPackage(response.data));
  } catch (error) {
    yield put(failurelistPackage(error));
  }
}

// add
const AddPackageApi = (action: PayloadAction<IPackagelist>) => {
  return api.post("/v1/api/package/add", action.payload);
};

export function* AddPackageCall(action: PayloadAction<IPackagelist>) {
  try {
    const response: AxiosResponse = yield call(AddPackageApi, action);
    yield put(successaddPackage(response.data));
  } catch (error) {
    yield put(failureaddPackage(error));
  }
}

// edit
const EditPackageApi = (action: PayloadAction<IPackagelist>) => {
  return api.put("/v1/api/package/update", action.payload, {
    params: { id: action.payload.id },
  });
};

export function* EditPackageCall(action: PayloadAction<IPackagelist>) {
  try {
    const response: AxiosResponse = yield call(EditPackageApi, action);
    yield put(successEditPackage(response.data));
  } catch (error) {
    yield put(failureEditPackage(error));
  }
}

// delete
const DeletePackageApi = (action: PayloadAction<number>) => {
  return api.delete("/v1/api/package/delete", {
    params: { id: action.payload },
  });
};

export function* DeletePackageCall(action: PayloadAction<number>) {
  try {
    const response: AxiosResponse = yield call(DeletePackageApi, action);
    yield put(successdeletePackage(response.data));
  } catch (error) {
    yield put(failuredeletePackage(error));
  }
}
