import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IExamAnswer } from "../../../Pages/Exam participation/IExam_participation";

export const ListExamAnswerSlice = createSlice({
  name: "ListExamAnswerSlice",
  initialState: {} as IReduxState<IExamAnswer[]>,
  reducers: {
    listExamAnswer: (state, _: PayloadAction<IExamAnswer>) => {
      state.isLoading = true;
    },
    successlistExamAnswer: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failurelistExamAnswer: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
  },
});

export const { failurelistExamAnswer, listExamAnswer, successlistExamAnswer } =
  ListExamAnswerSlice.actions;

export default ListExamAnswerSlice.reducer;
