import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IUserProfile } from "../../../Pages/UserProfile/IUserProfile";

export const AddUserProfileSlice = createSlice({
  name: "AddUserProfileSlice",
  initialState: {} as IReduxState<IUserProfile>,
  reducers: {
    addUserProfile: (state, _: PayloadAction<IUserProfile>) => {
      state.isLoading = true;
    },
    successaddUserProfile: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureaddUserProfile: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearAddUserProfileResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  addUserProfile,
  failureaddUserProfile,
  successaddUserProfile,
  clearAddUserProfileResponse,
} = AddUserProfileSlice.actions;

export default AddUserProfileSlice.reducer;
