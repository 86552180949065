import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IExamModel } from "../../../Pages/ExamModel/IExamModel";

export const ListExamModelSlice = createSlice({
  name: "ListExamModelSlice",
  initialState: {} as IReduxState<IExamModel[]>,
  reducers: {
    listExamModel: (state) => {
      state.isLoading = true;
    },
    successlistExamModel: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failurelistExamModel: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
  },
});

export const { failurelistExamModel, listExamModel, successlistExamModel } =
  ListExamModelSlice.actions;

export default ListExamModelSlice.reducer;
