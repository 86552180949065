import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IUserProfile } from "../../../Pages/UserProfile/IUserProfile";

export const DeleteUserProfileSlice = createSlice({
  name: "DeleteUserProfile",
  initialState: {} as IReduxState<IUserProfile>,
  reducers: {
    deleteUserProfile: (state, _) => {
      state.isLoading = true;
    },
    successdeleteUserProfile: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failuredeleteUserProfile: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearDeleteUserProfileResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  deleteUserProfile,
  failuredeleteUserProfile,
  successdeleteUserProfile,
  clearDeleteUserProfileResponse,
} = DeleteUserProfileSlice.actions;

export default DeleteUserProfileSlice.reducer;
