import "../Table.scss";

import { FunctionComponent } from "react";
import { ICustomToolbar } from "../ITable";
import { GridToolbarContainer } from "@mui/x-data-grid";

const CustomToolbar: FunctionComponent<ICustomToolbar> = (props) => {
  const { children, sx, className } = props;
  return (
    <GridToolbarContainer className={`toolbar ${className}`} sx={sx}>
      {children}
    </GridToolbarContainer>
  );
};

export default CustomToolbar;
