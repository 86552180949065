import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IFeedBack } from "../../../Pages/FeedBack/IFeedBack";

export const EditFeedBackSlice = createSlice({
  name: "EditFeedBackSlice",
  initialState: {} as IReduxState<IFeedBack>,
  reducers: {
    EditFeedBack: (state, _: PayloadAction<IFeedBack>) => {
      state.isLoading = true;
    },
    successEditFeedBack: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureEditFeedBack: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearEditFeedBackResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  EditFeedBack,
  failureEditFeedBack,
  successEditFeedBack,
  clearEditFeedBackResponse,
} = EditFeedBackSlice.actions;

export default EditFeedBackSlice.reducer;
