import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IExam } from "../../../Pages/Exam/IExam";

export const DeleteExamSlice = createSlice({
  name: "DeleteExam",
  initialState: {} as IReduxState<IExam>,
  reducers: {
    deleteExam: (state, _) => {
      state.isLoading = true;
    },
    successdeleteExam: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failuredeleteExam: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearDeleteExamResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  deleteExam,
  failuredeleteExam,
  successdeleteExam,
  clearDeleteExamResponse,
} = DeleteExamSlice.actions;

export default DeleteExamSlice.reducer;
