import { PayloadAction } from "@reduxjs/toolkit";
import api from "../../Axios/middleware";
import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";

import {
  successUserPermissionRows,
  failureUserPermissionRows,
} from "./UserPermissionRowsSlicer";
import {
  failureeditUserPermission,
  successeditUserPermission,
} from "./UpdateUserPermissionSlicer";
import { IUpdateUserPermission } from "./IUserPermission";

// get user permission
const EditUserPermission = (action: PayloadAction<IUpdateUserPermission>) => {
  return api.post("/v1/api/role-permission/", action.payload);
};

export function* EditUserPermissionCall(
  action: PayloadAction<IUpdateUserPermission>
) {
  try {
    const response: AxiosResponse = yield call(EditUserPermission, action);
    yield put(successeditUserPermission(response.data));
  } catch (error) {
    yield put(failureeditUserPermission(error));
  }
}

// get User permission rows
const UserPermissionRowsApi = (action?: PayloadAction<number>) => {
  const roleId = action?.payload;
  return api.get(`/v1/api/role-permission/`, {
    params: {
      ...(roleId ? { roleId: roleId } : {}),
    },
  });
};

export function* ListUserPermissionRowsCall(action: PayloadAction<number>) {
  try {
    const response: AxiosResponse = yield call(UserPermissionRowsApi, action);
    yield put(successUserPermissionRows(response.data));
  } catch (error) {
    yield put(failureUserPermissionRows(error));
  }
}
