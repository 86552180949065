import { PayloadAction } from "@reduxjs/toolkit";
import { call } from "redux-saga/effects";
import api from "../../Axios/middleware";
import { AxiosResponse } from "axios";

const TestApi = (action: PayloadAction<number>) => {
  return api.post("/v1/api/user/login", {
    email: "santhosh@aagnia.com",
    password: "Santhosh@123",
  });
};

export function* TestCall(action: PayloadAction<number>) {
  try {
    const response: AxiosResponse = yield call(TestApi, action);
    console.log(response);
  } catch (error) {
    console.log(error);
  }
}
