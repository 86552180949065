import { FunctionComponent, useEffect, useLayoutEffect, useState } from "react";
import StarIcon from "@mui/icons-material/Star"; // Import a star icon from MUI
import "./Instruction.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import {
  clearListExamID,
  listExamID,
} from "../../Redux/Slices/Exam/ListExamIDSlice";
import { addStudentExam } from "../../Redux/Slices/StudentExam/AddStudentExamSlice";
import { decode } from "../../Utils/encodeDecode";
import { ILoginResponse } from "../Login/ILogin";
import { listInstructionExamID } from "../../Redux/Slices/Exam/ListinstructionExamIDSlice";
import {
  clearlistStudentExam,
  listStudentExam,
} from "../../Redux/Slices/StudentExam/ListStudentExamSlice";
import { CircularProgress } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";

interface InstructionProps {}

const Instruction: FunctionComponent<InstructionProps> = () => {
  // Create an array of instruction objects
  const navigate = useNavigate();
  const { state: exam_id } = useLocation();

  const { data: listInstructionExamSlice } = useAppSelector(
    (state) => state.ListInstructionExamSlice
  );
  const ListInstructionExamSlices: any = listInstructionExamSlice?.data;

  const ListInstruction = ListInstructionExamSlices?.exam_instructions;

  const { data: listexamlist, isLoading: listexamlistLoading } = useAppSelector(
    (state) => state.ListExamIDSlice
  );
  console.log(listexamlist);
  console.log(listexamlistLoading);

  const [details, setDetails] = useState<ILoginResponse>({});

  const { data: listStudentExamSlice } = useAppSelector(
    (state) => state.ListStudentExamSlice
  );
  const ListStudentExam: any = listStudentExamSlice?.data;

  useLayoutEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch(listStudentExam());
    }, 1500); // 5000 milliseconds = 5 seconds
    return () => clearTimeout(timeoutId);
  }, []);

  useLayoutEffect(() => {
    dispatch(listInstructionExamID({ exam_id: exam_id }));
    if (ListStudentExam) {
      dispatch(listExamID({ stud_exam_id: ListStudentExam?.id }));
    }
  }, [exam_id, ListStudentExam]);

  useEffect(() => {
    // Check if "details" exists in localStorage before decoding
    const storedDetails = localStorage.getItem("details");
    if (storedDetails) {
      const userDetails = decode(storedDetails);
      setDetails(userDetails);
    }
    dispatch(clearListExamID());

    // Only re-run if `ListInstructionExamSlices` is relevant
  }, [ListInstructionExamSlices]);

  const [showLoading, setShowLoading] = useState(true);

  // Set the initial "Loading questions" display
  useEffect(() => {
    const initialTimeout = setTimeout(() => {
      setShowLoading(false); // Allow normal loading state to take over
    }, 20); // 5 seconds
    return () => clearTimeout(initialTimeout);
  }, []);
  const handleButtonClick = () => {
    navigate("/examparticipation", { state: { examID: exam_id } });
    localStorage.setItem(
      "timestorage",
      ListInstructionExamSlices?.total_timing
    );
  };
  const dispatch = useAppDispatch();

  return (
    <div className="instruction-container">
      <div className="instructionheading">
        {ListInstructionExamSlices?.exam_name}
      </div>
      <div className="instructiondescription">
        {ListInstructionExamSlices?.exam_description}
      </div>
      <div className="instruction-list-container">
        <div style={{ flex: 1 }}>
          <div className="instructionheadingbold">
            Please read instructions carefully
          </div>
          <div className="instruction-liststo">
            {ListInstruction?.map((item: any, index: any) => (
              <div key={index} className="instructionitem">
                <FiberManualRecordIcon
                  sx={{ fontSize: "10px", color: "black", marginTop: "3px" }}
                />

                <div style={{ fontSize: "15px" }}>{item?.instruction}</div>
              </div>
            ))}
          </div>
        </div>

        {/* {!!!listexamlistLoading && ( */}
        <div style={{ flex: 0.3 }}>
          <div className="instructionheadingbold">Question Types</div>

          <div className="sections-type">
            {ListInstructionExamSlices?.questions_by_type.map(
              (type: any, index: number) => (
                <div key={index} className="section-items">
                  <div className="centermultiple">
                    {type?.question_type_name}
                  </div>
                  <div>Multiple Choice</div>

                  <div>
                    {" "}
                    Questions :{" "}
                    {type.question_count === null
                      ? "-"
                      : type.question_count}{" "}
                  </div>
                  <div>
                    {" "}
                    Duration : {type.duration === null ? "-" : type.duration}
                  </div>
                </div>
              )
            )}
          </div>
        </div>
        {/* )} */}
      </div>

      {listexamlist?.data ? (
        <button className="proceed-button" onClick={handleButtonClick}>
          Start Test
        </button>
      ) : (
        <button
          className="proceed-button"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>Loading questions</div>
          <div>
            <CircularProgress
              size={20}
              sx={{
                color: "white",
                marginLeft: "10px",
              }}
            />
          </div>
        </button>
      )}
    </div>
  );
};

export default Instruction;
