import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IQuestions } from "../../../Pages/Questions/IQuestions";

export const EditQuestionsSlice = createSlice({
  name: "EditQuestionsSlice",
  initialState: {} as IReduxState<IQuestions>,
  reducers: {
    EditQuestions: (state, _: PayloadAction<IQuestions>) => {
      state.isLoading = true;
    },
    successEditQuestions: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureEditQuestions: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearEditQuestionsResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  EditQuestions,
  failureEditQuestions,
  successEditQuestions,
  clearEditQuestionsResponse,
} = EditQuestionsSlice.actions;

export default EditQuestionsSlice.reducer;
