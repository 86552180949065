import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IExamModel } from "../../../Pages/ExamModel/IExamModel";

export const AddExamModelSlice = createSlice({
  name: "AddExamModelSlice",
  initialState: {} as IReduxState<IExamModel>,
  reducers: {
    addExamModel: (state, _: PayloadAction<IExamModel>) => {
      state.isLoading = true;
    },
    successaddExamModel: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureaddExamModel: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearAddExamModelResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  addExamModel,
  failureaddExamModel,
  successaddExamModel,
  clearAddExamModelResponse,
} = AddExamModelSlice.actions;

export default AddExamModelSlice.reducer;
