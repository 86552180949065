import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IExamModel } from "../../../Pages/ExamModel/IExamModel";

export const DeleteExamModelSlice = createSlice({
  name: "DeleteExamModel",
  initialState: {} as IReduxState<IExamModel>,
  reducers: {
    deleteExamModel: (state, _) => {
      state.isLoading = true;
    },
    successdeleteExamModel: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failuredeleteExamModel: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearDeleteExamModelResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  deleteExamModel,
  failuredeleteExamModel,
  successdeleteExamModel,
  clearDeleteExamModelResponse,
} = DeleteExamModelSlice.actions;

export default DeleteExamModelSlice.reducer;
