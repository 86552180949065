import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";

export const ResetPassword = createSlice({
  name: "ResetPassword",
  initialState: {
    data: {},
    isLoading: false,
  } as IReduxState<{}>,
  reducers: {
    resetPasswordStart: (state, _) => {
      state.isLoading = true;
    },
    successResetPassword: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    failureResetPassword: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    clearResetPasswordStatus: (state) => {
      state.data = {};
    },
  },
});
export const {
  clearResetPasswordStatus,
  failureResetPassword,
  resetPasswordStart,
  successResetPassword,
} = ResetPassword.actions;

export default ResetPassword.reducer;
