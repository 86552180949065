import "./Loading.scss";

import { FunctionComponent } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { LoadingProps } from "./ILoading";

const Loading: FunctionComponent<LoadingProps> = ({
  isLoading,
  circularColor,
  backgroundColor,
  circularClassName,
}) => {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.snackbar + 1 }}
      open={isLoading}
      color={backgroundColor}
    >
      <CircularProgress
        color={circularColor}
        className={`circularClassName ${circularClassName}`}
      />
    </Backdrop>
  );
};

export default Loading;
