import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IOptions } from "../../../Pages/Options/IOptions";

export const DeleteOptionsSlice = createSlice({
  name: "DeleteOptions",
  initialState: {} as IReduxState<IOptions>,
  reducers: {
    deleteOptions: (state, _) => {
      state.isLoading = true;
    },
    successdeleteOptions: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failuredeleteOptions: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearDeleteOptionsResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  deleteOptions,
  failuredeleteOptions,
  successdeleteOptions,
  clearDeleteOptionsResponse,
} = DeleteOptionsSlice.actions;

export default DeleteOptionsSlice.reducer;
