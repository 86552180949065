import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IRole } from "../../../Pages/Role/IRole";

export const EditRoleSlice = createSlice({
  name: "EditRoleSlice",
  initialState: {} as IReduxState<IRole>,
  reducers: {
    EditRole: (state, _: PayloadAction<IRole>) => {
      state.isLoading = true;
    },
    successEditRole: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureEditRole: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearEditRoleResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  EditRole,
  failureEditRole,
  successEditRole,
  clearEditRoleResponse,
} = EditRoleSlice.actions;

export default EditRoleSlice.reducer;
