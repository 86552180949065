import * as React from "react";
import "./FeedBack.scss";
import { SvgIcon } from "@mui/material";
import { ReactComponent as Good } from "../../Assets/Images/Good.svg";
import { ReactComponent as Excellent } from "../../Assets/Images/Excellent.svg";
import { ReactComponent as VeryGood } from "../../Assets/Images/VeryGood.svg";
import { ReactComponent as Satisfactory } from "../../Assets/Images/Satisfactory.svg";
import { ReactComponent as NeedsImprovement } from "../../Assets/Images/NeedsImprovement.svg";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import { addFeedBack } from "../../Redux/Slices/FeedBack/AddFeedBackSlice";
import { decode } from "../../Utils/encodeDecode";
import { ILoginResponse } from "../Login/ILogin";
import { useLocation, useNavigate } from "react-router-dom";

export default function FeedBack() {
  const dispatch = useAppDispatch();
  const [details, setDetails] = React.useState<ILoginResponse>({});
  const { data: AddStudentExamSlice } = useAppSelector(
    (state) => state.AddStudentExamSlice
  );
  const AddStudent: any = AddStudentExamSlice?.data;

  React.useEffect(() => {
    const userDetails = decode(localStorage.getItem("details"));

    setDetails(userDetails);
  }, []);

  const nameid: any = details?.id;
  const navigate = useNavigate();
  const { state: feedback_id } = useLocation();
  const { data: listexamlist } = useAppSelector(
    (state) => state.ListExamIDSlice
  );
  const List: any = listexamlist?.data?.exam_name;
  console.log(List?.data?.exam_name);

  return (
    <div className="boxofsubmitexam">
      <div className="concentofexam">
        <div className="submitexamheading">Feed Back</div>
        <div className="iconfeedback">
          <div
            className="pointer"
            onClick={() => {
              dispatch(
                addFeedBack({
                  // feedback_id: feedback_id,
                  user_id: nameid,
                  feedback: "Very Easy",
                  comments: "string",
                  student_exam_id: feedback_id,
                })
              );
              localStorage.removeItem("timestorage");
              if ("Mock Test" === List) {
                navigate("/home"); // Replace with your target route
              } else {
                navigate("/examhistory"); // Replace with your target route
              }
            }}
          >
            <div>
              <SvgIcon
                sx={{ fontSize: "6.5rem" }}
                component={Excellent}
                inheritViewBox
              />
            </div>
            <div className="textalignfeedback">Very Easy</div>
          </div>
          <div
            className="pointer"
            onClick={() => {
              dispatch(
                addFeedBack({
                  // feedback_id: feedback_id,
                  user_id: nameid,
                  feedback: "Easy",
                  comments: "string",
                  student_exam_id: feedback_id,
                })
              );
              localStorage.removeItem("timestorage");

              if ("Mock Test" === List) {
                navigate("/home"); // Replace with your target route
              } else {
                navigate("/examhistory"); // Replace with your target route
              }
            }}
          >
            <div>
              <SvgIcon
                sx={{ fontSize: "6.5rem" }}
                component={VeryGood}
                inheritViewBox
              />
            </div>
            <div className="textalignfeedback">Easy</div>
          </div>
          <div
            className="pointer"
            onClick={() => {
              dispatch(
                addFeedBack({
                  // feedback_id: feedback_id,
                  user_id: nameid,
                  feedback: "Moderate",
                  comments: "string",
                  student_exam_id: feedback_id,
                })
              );
              localStorage.removeItem("timestorage");

              if ("Mock Test" === List) {
                navigate("/home"); // Replace with your target route
              } else {
                navigate("/examhistory"); // Replace with your target route
              }
            }}
          >
            <div>
              <SvgIcon
                sx={{ fontSize: "6.5rem" }}
                component={Good}
                inheritViewBox
              />
            </div>
            <div className="textalignfeedback">Moderate</div>
          </div>
          <div
            className="pointer"
            onClick={() => {
              dispatch(
                addFeedBack({
                  // feedback_id: feedback_id,
                  user_id: nameid,
                  feedback: "Difficult",
                  comments: "string",
                  student_exam_id: feedback_id,
                })
              );
              localStorage.removeItem("timestorage");

              if ("Mock Test" === List) {
                navigate("/home"); // Replace with your target route
              } else {
                navigate("/examhistory"); // Replace with your target route
              }
            }}
          >
            <div>
              <SvgIcon
                sx={{ fontSize: "6.5rem" }}
                component={Satisfactory}
                inheritViewBox
              />
            </div>
            <div className="textalignfeedback">Difficult</div>
          </div>
          <div
            className="pointer"
            onClick={() => {
              dispatch(
                addFeedBack({
                  // feedback_id: feedback_id,
                  user_id: nameid,
                  feedback: "Very Difficult",
                  comments: "string",
                  student_exam_id: feedback_id,
                })
              );
              localStorage.removeItem("timestorage");

              if ("Mock Test" === List) {
                navigate("/home"); // Replace with your target route
              } else {
                navigate("/examhistory"); // Replace with your target route
              }
            }}
          >
            <div>
              <SvgIcon
                sx={{ fontSize: "6.5rem" }}
                component={NeedsImprovement}
                inheritViewBox
              />
            </div>
            <div className="textalignfeedback">Very Difficult</div>
          </div>
        </div>
      </div>
      <button
        className="skipheading"
        onClick={() => {
          localStorage.removeItem("timestorage");
          if ("Mock Test" === List) {
            navigate("/home"); // Replace with your target route
          } else {
            navigate("/examhistory"); // Replace with your target route
          }
        }}
      >
        Skip
      </button>
    </div>
  );
}
