import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IStudentExam } from "../../../Pages/Exam/IExam";

export const EditStudentExamSlice = createSlice({
  name: "EditStudentExamSlice",
  initialState: {} as IReduxState<IStudentExam>,
  reducers: {
    EditStudentExam: (state, _: PayloadAction<IStudentExam>) => {
      state.isLoading = true;
    },
    successEditStudentExam: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureEditStudentExam: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },

    clearEditStudentExamResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  EditStudentExam,
  failureEditStudentExam,
  successEditStudentExam,
  clearEditStudentExamResponse,
} = EditStudentExamSlice.actions;

export default EditStudentExamSlice.reducer;
