import "./DeleteDialog.scss";
import { FunctionComponent, useEffect, useState } from "react";
import { DeleteDialogProps } from "./IDeleteDialog";
import Dialog from "../Dialog/Dialog";
import IconButtonField from "../Button/IconButtonField";
import { SvgIcon } from "@mui/material";
import { ReactComponent as Cancel } from "../../Assets/Images/Cancel.svg";
import ButtonField from "../Button/ButtonField";
import { ReactComponent as Success } from "../../Assets/Images/success.svg";
import { ReactComponent as Failure } from "../../Assets/Images/failure.svg";
import ResponseDialog from "../ResponseDialog/ResponseDialog";

const DeleteDialog: FunctionComponent<DeleteDialogProps> = (props) => {
  const { open, setOpen, deleteFunction, deleteableName, response } = props;
  const [responseDialog, setResponseDialog] = useState<boolean>(false);
  const handleClose = () => {
    setOpen(false);
    setResponseDialog(false);
  };

  useEffect(() => {
    if (response && Object.keys(response).length !== 0) {
      if (response?.status) {
        setOpen(false);
      } else {
        setResponseDialog(true);
      }
    }
  }, [response]);

  return (
    <>
      <Dialog
        open={open}
        dialogClassName="delete_dialog"
        dialogTitleClassName="delete_dialog_title"
        Dialog_Title={
          <>
            Delete
            {/* <IconButtonField onClick={() => setOpen(false)}>
              <SvgIcon component={Cancel} inheritViewBox />
            </IconButtonField> */}
          </>
        }
        dialogContentClassName="delete_dialog_content"
        Dialog_Content={
          <>
            Are you sure you want to delete <b>{deleteableName}</b>?
          </>
        }
        dialogActionClassName="delete_dialog_action"
        Dialog_Actions={
          <>
            <ButtonField
              className="cancel_button"
              onClick={() => setOpen(false)}
            >
              Cancel
            </ButtonField>
            <ButtonField className="delete_button" onClick={deleteFunction}>
              Delete
            </ButtonField>
          </>
        }
      />
      <ResponseDialog response={response} parentDialog={setOpen} />
    </>
  );
};

export default DeleteDialog;
