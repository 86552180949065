import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IExam } from "../../../Pages/Exam/IExam";
import { IStartExam } from "../../../Pages/Instruction/IInstruction";

export const ListExamSlice = createSlice({
  name: "ListExamSlice",
  initialState: {} as IReduxState<IStartExam>,
  reducers: {
    listExam: (state) => {
      state.isLoading = true;
    },
    successlistExam: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failurelistExam: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
  },
});

export const { failurelistExam, listExam, successlistExam } =
  ListExamSlice.actions;

export default ListExamSlice.reducer;
