import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IUser } from "../../../Pages/User/IUser";

export const AddUserSlice = createSlice({
  name: "AddUserSlice",
  initialState: {} as IReduxState<IUser>,
  reducers: {
    addUser: (state, _: PayloadAction<IUser>) => {
      state.isLoading = true;
    },
    successaddUser: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureaddUser: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearAddUserResponse: (state) => {
      state.data = {};
    },
  },
});

export const { addUser, failureaddUser, successaddUser, clearAddUserResponse } =
  AddUserSlice.actions;

export default AddUserSlice.reducer;
