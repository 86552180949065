import { FunctionComponent } from "react";
import Dialog from "../Dialog/Dialog";
import TextInputField from "../TextInput/TextInputField";
import { SvgIcon } from "@mui/material";
import { ReactComponent as Search } from "../../Assets/Images/search.svg";

interface SearchPopupProps {
  dialogOpen: boolean;
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SearchPopup: FunctionComponent<SearchPopupProps> = (props) => {
  const { dialogOpen, setDialogOpen } = props;
  return (
    <Dialog
      open={dialogOpen}
      setOpen={setDialogOpen}
      dividers
      Dialog_Title={
        <TextInputField
          variant="standard"
          placeholder="Search..."
          InputProps={{
            sx: {
              gap: "8px",
              p: 1,
              fontSize: "24px",
            },
            startAdornment: (
              <SvgIcon
                sx={{
                  width: "20px",
                  height: "20px",
                }}
                component={Search}
                inheritViewBox
              />
            ),
            disableUnderline: true,
          }}
        />
      }
      Dialog_Content={<></>}
    />
  );
};

export default SearchPopup;
