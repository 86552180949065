import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IInstruction } from "../../../Pages/Instruction/IInstruction";

export const ListInstructionSlice = createSlice({
  name: "ListInstructionSlice",
  initialState: {} as IReduxState<IInstruction[]>,
  reducers: {
    listInstruction: (state) => {
      state.isLoading = true;
    },
    successlistInstruction: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failurelistInstruction: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  failurelistInstruction,
  listInstruction,
  successlistInstruction,
} = ListInstructionSlice.actions;

export default ListInstructionSlice.reducer;
