import React, { useEffect } from "react";
import "../Login/Login.scss";
import TextInputField from "../../Components/TextInput/TextInputField";
import {
  CircularProgress,
  Divider,
  InputAdornment,
  SvgIcon,
} from "@mui/material";
import Background from "../../Assets/Images/cartoon-ai-robot-scene.jpg";
import { Mail } from "@mui/icons-material";
import SendIcon from "@mui/icons-material/Send";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ButtonField from "../../Components/Button/ButtonField";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as LoginLogo } from "../../Assets/Images/loginlogo.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { IForgotPassword } from "./IForgotPassword";
import { z } from "zod";
import TextInputFieldC from "../../Components/TextInput/TextInputFieldC";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import {
  ClearForgotPassword,
  ForgotPasswordStart,
} from "../../Redux/Slices/LoginSlicer/ForgotPassword";
import ResponseDialog from "../../Components/ResponseDialog/ResponseDialog";
import { ReactComponent as Logmobile } from "../../Assets/Images/logomobile.svg";
import Snackbar from "../../Components/Snackbar/Snackbar";

interface ForgotProps {
  userName: string;
  email: string;
}
const ForgotPassword = () => {
  const nav = useNavigate();
  const dispatch = useAppDispatch();
  const ForGotPasswordRes = useAppSelector((state) => state.ForgotPassword);
  const { data: forgotPassRes } = useAppSelector(
    (state) => state.ForgotPassword
  );

  const loginSchema = z.object({
    email: z
      .string({
        required_error: "Please enter a valid email ID",
        message: "Please enter a valid email ID",
      })
      .email({
        message: "Please enter a valid email ID",
      }),
  });
  const methods = useForm<IForgotPassword>({
    defaultValues: {
      email: null,
    },
    resolver: zodResolver(loginSchema),
    mode: "onChange",
  });
  const onSubmit: SubmitHandler<IForgotPassword> = (val) => {
    dispatch(ForgotPasswordStart(val));
    // localStorage.setItem("token", JSON.stringify(val));
    // nav("/dashboard");
  };
  useEffect(() => {
    return () => {
      dispatch(ClearForgotPassword());
    };
  }, []);
  return (
    <>
      <Snackbar response={forgotPassRes?.data} />

      <div className="parent-div">
        <div className="child-div1s">
          <img
            src={Background}
            alt="AI Robot Scene"
            className="responsive-image"
          />
        </div>{" "}
        <div className="child-div2s">
          <div className="form-content">
            <FormProvider {...methods}>
              <form
                onSubmit={methods.handleSubmit(onSubmit)}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="mainforgotpassword">
                  <div>
                    <div className="heading">
                      <SvgIcon
                        sx={{
                          width: "70%",
                          height: "70%",
                          padding: "15px",
                          cursor: "pointer",
                        }}
                        component={LoginLogo}
                        inheritViewBox
                        onClick={() => {
                          nav("/home");
                        }}
                      />
                    </div>
                    <div className="subHeading">Forgot Password</div>
                    <div className="subHeadingforgotpassword">
                      Enter your registered email to reset your password.
                    </div>
                  </div>
                  <div className="textFields">
                    <div>
                      <div className="emailplaceholder">Email</div>

                      <TextInputFieldC
                        className="textField"
                        name="email"
                        type="text"
                        // placeholder="Email"
                        variant="outlined"
                      />
                    </div>
                  </div>
                  <div className="textFields">
                    <ButtonField
                      disabled={ForGotPasswordRes.isLoading}
                      className="buttonfield"
                      type="submit"
                    >
                      Submit
                      {ForGotPasswordRes.isLoading && (
                        <CircularProgress
                          size={24}
                          sx={{
                            color: "white",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-12px",
                            marginLeft: "-12px",
                          }}
                        />
                      )}
                    </ButtonField>

                    <div className="forgetPasswordfield">
                      Already have an account?
                      <Link
                        to={"/login"}
                        style={{
                          color: "rgba(0, 118, 192, 1)",
                          paddingLeft: "10px",
                        }}
                      >
                        Sign In
                      </Link>
                    </div>
                    {/* <div className="textFields">
                      <ButtonField
                        className="buttonfieldregistration"
                        type="submit"
                      >
                        <Link
                          to={"https://everesttutoring.com.au/contact/"}
                          style={{
                            color: "rgba(0, 118, 192, 1)",
                            paddingLeft: "10px",
                            textDecoration: "none",
                          }}
                        >
                          For Registration
                        </Link>
                      </ButtonField>
                    </div> */}
                  </div>
                </div>
              </form>
            </FormProvider>
            <ResponseDialog
              response={forgotPassRes}
              okFunction={() => nav(`/login`)}
            />
          </div>
        </div>
      </div>
      <div className="backGroundbackground">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="mainforgotpasswords">
              <div>
                <div className="heading">
                  <SvgIcon
                    sx={{
                      width: "70%",
                      height: "70%",
                      padding: "15px",
                    }}
                    component={Logmobile}
                    inheritViewBox
                  />
                </div>
                <div className="subHeading">Forgot Password</div>
                <div className="subHeadingforgotpassword">
                  Enter your registered email to reset your password.
                </div>
              </div>
              <div className="textFields">
                <div>
                  <div className="emailplaceholder">Email</div>

                  <TextInputFieldC
                    className="textField"
                    name="email"
                    type="text"
                    // placeholder="Email"
                    variant="outlined"
                  />
                </div>
              </div>
              <div className="textFields">
                <ButtonField
                  disabled={ForGotPasswordRes.isLoading}
                  className="buttonfield"
                  type="submit"
                >
                  Submit
                  {ForGotPasswordRes.isLoading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: "white",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </ButtonField>

                <div className="forgetPasswordfield">
                  Already have an account?
                  <Link
                    to={"/login"}
                    style={{
                      color: "rgba(0, 118, 192, 1)",
                      paddingLeft: "10px",
                    }}
                  >
                    Sign In
                  </Link>
                </div>
                {/* <div className="forgetPasswordfield">
                  Don't have an account yet?
                  <Link
                    to={"/registration"}
                    style={{
                      color: "rgba(0, 118, 192, 1)",
                      paddingLeft: "10px",
                    }}
                  >
                    Sign Up
                  </Link>
                </div> */}
              </div>
            </div>
          </form>
        </FormProvider>
        <ResponseDialog
          response={forgotPassRes}
          okFunction={() => nav(`/login`)}
        />
      </div>
    </>
  );
};

export default ForgotPassword;
