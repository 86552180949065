import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IExam } from "../../../Pages/Exam/IExam";

export const EditExamSlice = createSlice({
  name: "EditExamSlice",
  initialState: {} as IReduxState<IExam>,
  reducers: {
    EditExam: (state, _: PayloadAction<IExam>) => {
      state.isLoading = true;
    },
    successEditExam: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureEditExam: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearEditExamResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  EditExam,
  failureEditExam,
  successEditExam,
  clearEditExamResponse,
} = EditExamSlice.actions;

export default EditExamSlice.reducer;
