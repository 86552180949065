import { ListItemIcon, Menu, MenuItem } from "@mui/material";
import { FunctionComponent, ReactElement } from "react";
import { MenusProps } from "./IMenu";
import React from "react";

const Menus: FunctionComponent<MenusProps> = (props) => {
  const {
    MenuListProps,
    PopoverClasses,
    autoFocus,
    classes,
    disableAutoFocusItem,
    onClose,
    sx,
    variant,
    transformOrigin = { horizontal: "center", vertical: "top" },
    anchorOrigin = {
      vertical: "bottom",
      horizontal: "left",
    },
    menuItems,
    children,
  } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // Combine original children onclick with component onclick
          const originalOnClick = (child as ReactElement).props.onClick;
          const enhancedOnClick = (event: React.MouseEvent<HTMLElement>) => {
            if (originalOnClick) {
              originalOnClick(event); // children onclick
            }
            handleClick(event); // component onclick
          };
          return React.cloneElement(child as ReactElement, {
            onClick: enhancedOnClick,
          });
        }
        return child;
      })}

      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        onClick={handleClose}
        transformOrigin={transformOrigin}
        anchorOrigin={anchorOrigin}
        MenuListProps={MenuListProps}
        autoFocus={autoFocus}
        disableAutoFocusItem={disableAutoFocusItem}
        variant={variant}
        sx={sx}
        classes={classes}
        PopoverClasses={PopoverClasses}
        // PaperProps={{
        //   elevation: 0,
        //   sx: {
        //     overflow: "visible",
        //     filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
        //     mt: 1.5,
        //     "& .MuiAvatar-root": {
        //       width: 32,
        //       height: 32,
        //       ml: -0.5,
        //       mr: 1,
        //     },
        //     "&::before": {
        //       content: '""',
        //       display: "block",
        //       position: "absolute",
        //       top: 0,
        //       right: 14,
        //       width: 10,
        //       height: 10,
        //       bgcolor: "background.paper",
        //       transform: "translateY(-50%) rotate(45deg)",
        //       zIndex: 0,
        //     },
        //   },
        // }}
      >
        {menuItems?.map((menu, index) => (
          <MenuItem
            onClick={() => {
              handleClose();
              menu.onClick();
            }}
            key={index}
          >
            {menu.icon && <ListItemIcon>{menu.icon}</ListItemIcon>}
            {menu.menu}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default Menus;
