import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IEvaluation } from "../../../Pages/Exam/IExam";

export const ListEvaluationSlice = createSlice({
  name: "ListEvaluationSlice",
  initialState: {} as IReduxState<IEvaluation[]>,
  reducers: {
    listEvaluation: (state, _: PayloadAction<IEvaluation>) => {
      state.isLoading = true;
    },
    successlistEvaluation: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failurelistEvaluation: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
  },
});

export const { failurelistEvaluation, listEvaluation, successlistEvaluation } =
  ListEvaluationSlice.actions;

export default ListEvaluationSlice.reducer;
