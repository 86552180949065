import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import api from "../../Axios/middleware";
import { failureaddRole, successaddRole } from "./AddRoleSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { IRole } from "../../../Pages/Role/IRole";

import { failuredeleteRole, successdeleteRole } from "./DeleteRoleSlice";
import { failurelistRole, successlistRole } from "./ListRoleSlice";
import { failureEditRole, successEditRole } from "./EditRoleSlice";

// list
const ListRoleApi = () => {
  return api.get("/v1/api/role/");
};

export function* ListRoleCall() {
  try {
    const response: AxiosResponse = yield call(ListRoleApi);
    yield put(successlistRole(response.data));
  } catch (error) {
    yield put(failurelistRole(error));
  }
}

// add
const AddRoleApi = (action: PayloadAction<IRole>) => {
  return api.post("/v1/api/role/create_role", action.payload);
};

export function* AddRoleCall(action: PayloadAction<IRole>) {
  try {
    const response: AxiosResponse = yield call(AddRoleApi, action);
    yield put(successaddRole(response.data));
  } catch (error) {
    yield put(failureaddRole(error));
  }
}

// edit
const EditRoleApi = (action: PayloadAction<IRole>) => {
  return api.put("/v1/api/role/", action.payload, {
    params: { role_id: action.payload.id },
  });
};

export function* EditRoleCall(action: PayloadAction<IRole>) {
  try {
    const response: AxiosResponse = yield call(EditRoleApi, action);
    yield put(successEditRole(response.data));
  } catch (error) {
    yield put(failureEditRole(error));
  }
}

// delete
const DeleteRoleApi = (action: PayloadAction<number>) => {
  return api.delete("/v1/api/role/", {
    params: { role_id: action.payload },
  });
};

export function* DeleteRoleCall(action: PayloadAction<number>) {
  try {
    const response: AxiosResponse = yield call(DeleteRoleApi, action);
    yield put(successdeleteRole(response.data));
  } catch (error) {
    yield put(failuredeleteRole(error));
  }
}
