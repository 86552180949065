import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IRole } from "../../../Pages/Role/IRole";

export const ListRoleSlice = createSlice({
  name: "ListRoleSlice",
  initialState: {} as IReduxState<IRole[]>,
  reducers: {
    listRole: (state) => {
      state.isLoading = true;
    },
    successlistRole: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failurelistRole: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
  },
});

export const { failurelistRole, listRole, successlistRole } =
  ListRoleSlice.actions;

export default ListRoleSlice.reducer;
