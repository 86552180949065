import { FunctionComponent, memo, useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import TextInputFieldC from "../../Components/TextInput/TextInputFieldC";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import Select from "../../Components/Select/AutoComplete";
import { AutocompleteOption } from "../../Components/Select/ISelectProps";
import TextAreaInputField from "../../Components/TextAreaInput/TextAreaInputField";
import { IUserAdmin } from "./IUserAdmin";
import NumberInputField from "../../Components/NumberInput/NumberInputField";
import { EditUserAdmin } from "../../Redux/Slices/UserAdmin/EditUserAdminSlice";
import { addUserAdmin } from "../../Redux/Slices/UserAdmin/AddUserAdminSlice";
import { ReactComponent as ClosedEye } from "../../Assets/Images/closedEye.svg";
import { ReactComponent as OpenEye } from "../../Assets/Images/openEye.svg";
import IconButtonField from "../../Components/Button/IconButtonField";
import { SvgIcon } from "@mui/material";

interface UserAdminFormProps {
  formRef: React.RefObject<HTMLFormElement>;
  initialValues: IUserAdmin;
  setInitialValues: React.Dispatch<React.SetStateAction<IUserAdmin>>;
}

const UserAdminForm: FunctionComponent<UserAdminFormProps> = (props) => {
  const dispatch = useAppDispatch();

  const { data: listRole } = useAppSelector((state) => state.ListRoleSlice);

  const ListRole = Array.isArray(listRole?.data)
    ? listRole?.data.map((listRole) => ({
        label: listRole.name!,
        value: listRole.id,
      }))
    : [];
  const userSchema = z
    .object({
      id: z.number().optional(),
      username: z
        .string({ message: "Please enter name" })
        .min(1, { message: "Please enter name" }),
      email: z
        .string({
          required_error: "Please enter a valid email ID",
          message: "Please enter a valid email ID",
        })
        .email({
          message: "Please enter a valid email ID",
        }),
      role: z.number({ message: "Please select role" }),
      phone_no: z
        .number({ message: "Please enter phone number" })
        .nonnegative({ message: "Phone number must be exactly 10 digits" }),
      // .refine(
      //   (value) =>
      //     value.toString().length <= 10 && value.toString().length >= 10,
      //   {
      //     message: "Phone number must contain only 10 digits",
      //   }
      // ),
      password: z
        .string({
          required_error:
            "Password must be at least 8 characters long and include at least one uppercase letter, one special character, and one numeric value.",
          message:
            "Password must be at least 8 characters long and include at least one uppercase letter, one special character, and one numeric value.",
        })
        .min(8, {
          message:
            "Password must be at least 8 characters long and include at least one uppercase letter, one special character, and one numeric value.",
        })
        .regex(/[A-Z]/, {
          message:
            "Password must be at least 8 characters long and include at least one uppercase letter, one special character, and one numeric value.",
        }) // Uppercase letter
        .regex(/[0-9]/, {
          message:
            "Password must be at least 8 characters long and include at least one uppercase letter, one special character, and one numeric value.",
        }) // Number
        .regex(/[@$!%*?&#^()_+=[\]{};':"\\|,.<>/?-]/, {
          message:
            "Password must be at least 8 characters long and include at least one uppercase letter, one special character, and one numeric value.",
        }), // Special character

      confirm_password: z
        .string({
          required_error:
            "Confirm Password must be at least 8 characters long and include at least one uppercase letter, one special character, and one numeric value.",
          message:
            "Confirm Password must be at least 8 characters long and include at least one uppercase letter, one special character, and one numeric value.",
        })
        .min(8, {
          message:
            "Confirm Password must be at least 8 characters long and include at least one uppercase letter, one special character, and one numeric value.",
        })
        .regex(/[A-Z]/, {
          message:
            "Confirm Password must be at least 8 characters long and include at least one uppercase letter, one special character, and one numeric value.",
        }) // Uppercase letter
        .regex(/[0-9]/, {
          message:
            "Confirm Password must be at least 8 characters long and include at least one uppercase letter, one special character, and one numeric value.",
        }) // Number
        .regex(/[@$!%*?&#^()_+=[\]{};':"\\|,.<>/?-]/, {
          message:
            "Confirm Password must be at least 8 characters long and include at least one uppercase letter, one special character, and one numeric value.",
        }),
    })
    .refine((data) => data.password === data.confirm_password, {
      message: " Passwords does not match",
      path: ["confirm_password"], // Point to the confirm_password field
    });

  const methods = useForm<IUserAdmin>({
    defaultValues: {
      ...props.initialValues,
      status: props.initialValues?.id ? props.initialValues.status : true,
      phone_no: props.initialValues?.id
        ? Number(props.initialValues?.phone_no)
        : undefined,
    },
    resolver: zodResolver(userSchema),
    mode: "onChange",
  });

  const onSubmit: SubmitHandler<IUserAdmin> = (val) => {
    if (val.id) {
      dispatch(EditUserAdmin({ ...val, phone_no: String(val.phone_no) }));
    } else {
      dispatch(addUserAdmin({ ...val, phone_no: String(val.phone_no) }));
    }
  };

  useEffect(() => {
    return () => {
      props.setInitialValues({});
    };
  }, [dispatch, props]);

  // Status list
  const RoleSatus = [
    {
      label: "Active",
      value: true,
    },

    {
      label: "Inactive",
      value: false,
    },
  ];

  const [show, setShow] = useState<boolean>(false);
  const [shows, setShows] = useState<boolean>(false);

  return (
    <FormProvider {...methods}>
      <form
        ref={props.formRef}
        onSubmit={methods.handleSubmit(onSubmit)}
        className="form_double_input"
      >
        <TextInputFieldC name="username" label="Name" type="text" />
        <TextInputFieldC name="email" label="Email" type="text" />
        <NumberInputField
          name="phone_no"
          label="Phone Number"
          type="number"
          disabledScrollBar
        />
        <TextInputFieldC
          label="Password"
          name="password"
          type={show ? "text" : "password"}
          variant="outlined"
          InputProps={{
            endAdornment: (
              <IconButtonField
                onClick={() => setShow(!show)}
                aria-label={show ? "Hide password" : "Show password"}
              >
                <SvgIcon
                  key={show ? "open-eye-icon" : "closed-eye-icon"} // Ensures unique identity
                  sx={{
                    width: "20px",
                    height: "20px",
                    color: "white",
                  }}
                  component={show ? OpenEye : ClosedEye}
                  inheritViewBox
                />
              </IconButtonField>
            ),
          }}
        />
        <TextInputFieldC
          label="Confirm Password"
          name="confirm_password"
          type={shows ? "text" : "password"}
          variant="outlined"
          InputProps={{
            endAdornment: (
              <IconButtonField
                onClick={() => setShows(!shows)}
                aria-label={show ? "Hide password" : "Show password"}
              >
                <SvgIcon
                  key={show ? "open-eye-icon" : "closed-eye-icon"} // Ensures unique identity
                  sx={{
                    width: "20px",
                    height: "20px",
                    color: "white",
                  }}
                  component={shows ? OpenEye : ClosedEye}
                  inheritViewBox
                />
              </IconButtonField>
            ),
          }}
        />
        <Select
          name="role"
          label="Role"
          options={ListRole ?? []}
          value={
            ListRole?.find(
              (list) => list.value === methods.getValues("role")
            ) ?? null
          }
          onChange={(_, value) => {
            const val = value as AutocompleteOption;
            methods.setValue("role", val?.value, {
              shouldValidate: true,
            });
          }}
        />
        <Select
          name="status"
          label="Status"
          options={RoleSatus}
          value={
            RoleSatus?.find(
              (role) => role.value === methods.getValues("status")
            ) ?? null
          }
          onChange={(_, value) => {
            const val = value as AutocompleteOption;
            methods.setValue("status", val?.value, {
              shouldValidate: true,
            });
          }}
        />
      </form>
    </FormProvider>
  );
};

export default memo(UserAdminForm);
