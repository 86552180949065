import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IUserExamList } from "../../../Pages/Package/IPackage";

export const ListUserExamListSlice = createSlice({
  name: "ListUserExamListSlice",
  initialState: {} as IReduxState<IUserExamList[]>,
  reducers: {
    listUserExamList: (state) => {
      state.isLoading = true;
    },
    successlistUserExamList: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failurelistUserExamList: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  failurelistUserExamList,
  listUserExamList,
  successlistUserExamList,
} = ListUserExamListSlice.actions;

export default ListUserExamListSlice.reducer;
