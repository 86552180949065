import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IExam } from "../../../Pages/Exam/IExam";

export const AddExamSlice = createSlice({
  name: "AddExamSlice",
  initialState: {} as IReduxState<IExam>,
  reducers: {
    addExam: (state, _: PayloadAction<IExam>) => {
      state.isLoading = true;
    },
    successaddExam: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureaddExam: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearAddExamResponse: (state) => {
      state.data = {};
    },
  },
});

export const { addExam, failureaddExam, successaddExam, clearAddExamResponse } =
  AddExamSlice.actions;

export default AddExamSlice.reducer;
