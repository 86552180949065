import { AxiosResponse } from "axios";
import { call, delay, put } from "redux-saga/effects";
import api from "../../Axios/middleware";
import { failureaddExam, successaddExam } from "./AddExamSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  ICloseSection,
  IEvaluation,
  IExam,
  IExamlistID,
} from "../../../Pages/Exam/IExam";

import { failuredeleteExam, successdeleteExam } from "./DeleteExamSlice";
import { failurelistExam, successlistExam } from "./ListExamSlice";
import { failureEditExam, successEditExam } from "./EditExamSlice";
import { failurelistExamID, successlistExamID } from "./ListExamIDSlice";
import {
  failurelistEvaluation,
  successlistEvaluation,
} from "./ListEvaluationSlice";
import {
  failurelistInstructionExamID,
  successlistInstructionExamID,
} from "./ListinstructionExamIDSlice";
import { IAPIResponse } from "../../interfaces/IAPIResponse";
import {
  failureaddCloseSection,
  successaddCloseSection,
} from "./AddCloseSectionSlice";

// list
const ListExamApi = () => {
  return api.get("/v1/api/exam/get-exams");
};

export function* ListExamCall() {
  try {
    const response: AxiosResponse = yield call(ListExamApi);
    yield put(successlistExam(response.data));
  } catch (error) {
    yield put(failurelistExam(error));
  }
}

// list answer list
const ListEvaluationApi = (action: PayloadAction<IEvaluation>) => {
  return api.get("/v1/api/exam/evaluation", { params: action.payload });
};

export function* ListEvaluationCall(action: PayloadAction<IEvaluation>) {
  try {
    const response: AxiosResponse = yield call(ListEvaluationApi, action);
    yield put(successlistEvaluation(response.data));
  } catch (error) {
    yield put(failurelistEvaluation(error));
  }
}

// list exam id

const ListExamIDApi = (action: PayloadAction<IExamlistID>) => {
  return api.get("/v1/api/exam/start", { params: action.payload });
};

export function* ListExamIDCall(action: PayloadAction<IExamlistID>) {
  while (true) {
    try {
      const response: AxiosResponse<IAPIResponse<IExamlistID>> = yield call(
        ListExamIDApi,
        action
      );
      if (response.data.data) {
        yield put(successlistExamID(response.data));
        break;
      }
      yield delay(10000);
    } catch (error) {
      yield put(failurelistExamID(error));
    }
  }
}

// list instruction exam id

const ListInstructionExamIDApi = (action: PayloadAction<IExamlistID>) => {
  return api.get("/v1/api/exam/instruction", { params: action.payload });
};

export function* ListInstructionExamIDCall(action: PayloadAction<IExamlistID>) {
  try {
    const response: AxiosResponse = yield call(
      ListInstructionExamIDApi,
      action
    );
    yield put(successlistInstructionExamID(response.data));
  } catch (error) {
    yield put(failurelistInstructionExamID(error));
  }
}

// addCloseSection
const AddCloseSectionApi = (action: PayloadAction<ICloseSection>) => {
  return api.post("/v1/api/exam/closeSection", action.payload);
};

export function* AddCloseSectionCall(action: PayloadAction<ICloseSection>) {
  try {
    const response: AxiosResponse = yield call(AddCloseSectionApi, action);
    yield put(successaddCloseSection(response.data));
  } catch (error) {
    yield put(failureaddCloseSection(error));
  }
}

// add
const AddExamApi = (action: PayloadAction<IExam>) => {
  return api.post("/v1/api/exam/create-exam", action.payload);
};

export function* AddExamCall(action: PayloadAction<IExam>) {
  try {
    const response: AxiosResponse = yield call(AddExamApi, action);
    yield put(successaddExam(response.data));
  } catch (error) {
    yield put(failureaddExam(error));
  }
}

// edit
const EditExamApi = (action: PayloadAction<IExam>) => {
  return api.put("/v1/api/exam/", action.payload, {
    params: { id: action.payload.id },
  });
};

export function* EditExamCall(action: PayloadAction<IExam>) {
  try {
    const response: AxiosResponse = yield call(EditExamApi, action);
    yield put(successEditExam(response.data));
  } catch (error) {
    yield put(failureEditExam(error));
  }
}

// delete
const DeleteExamApi = (action: PayloadAction<number>) => {
  return api.delete("/v1/api/exam/", {
    params: { id: action.payload },
  });
};

export function* DeleteExamCall(action: PayloadAction<number>) {
  try {
    const response: AxiosResponse = yield call(DeleteExamApi, action);
    yield put(successdeleteExam(response.data));
  } catch (error) {
    yield put(failuredeleteExam(error));
  }
}
