import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IExamlistID } from "../../../Pages/Exam/IExam";

export const ListInstructionExamSlice = createSlice({
  name: "ListInstructionExamSlice",
  initialState: {} as IReduxState<IExamlistID[]>,
  reducers: {
    listInstructionExamID: (state, _: PayloadAction<IExamlistID>) => {
      state.isLoading = true;
    },
    successlistInstructionExamID: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failurelistInstructionExamID: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearlistInstructionExamID: (state) => {
      state.data = {};
    },
  },
});

export const {
  failurelistInstructionExamID,
  listInstructionExamID,
  successlistInstructionExamID,
  clearlistInstructionExamID,
} = ListInstructionExamSlice.actions;

export default ListInstructionExamSlice.reducer;
