import "../Table.scss";

import { FunctionComponent } from "react";
import { TextInputFieldProps } from "../../TextInput/ITextInputFieldProps";
import { GridToolbarQuickFilter } from "@mui/x-data-grid/components/toolbar/GridToolbarQuickFilter";

const TableSearch: FunctionComponent<TextInputFieldProps> = (props) => {
  return (
    <GridToolbarQuickFilter
      {...props}
      className={`table_search ${props.className}`}
      variant="outlined"
      size="small"
      // InputProps={{
      //   disableUnderline: true,
      // }}
    />
  );
};

export default TableSearch;
