import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IYearGroup } from "../../../Pages/User/IUser";

export const ListYearGroupSlice = createSlice({
  name: "ListYearGroupSlice",
  initialState: {} as IReduxState<IYearGroup[]>,
  reducers: {
    listYearGroup: (state) => {
      state.isLoading = true;
    },
    successlistYearGroup: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failurelistYearGroup: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
  },
});

export const { failurelistYearGroup, listYearGroup, successlistYearGroup } =
  ListYearGroupSlice.actions;

export default ListYearGroupSlice.reducer;
