import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import api from "../../Axios/middleware";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  IUserPackage,
  IUserPackageDetails,
} from "../../../Pages/Package/IPackage";
import {
  failurelistUserPackage,
  successlistUserPackage,
} from "./ListUserPackageSlice";
import {
  failurelistUserPackageDetails,
  successlistUserPackageDetails,
} from "./ListUserPackageDetailsSlice";
import {
  failurelistUserExamList,
  successlistUserExamList,
} from "./ListUserExamListSlice";
import {
  failurependingExamList,
  successpendingExamList,
} from "./PendingExamListSlice";

// list
const ListUserPackageApi = () => {
  return api.get("/v1/api/package/user-package");
};

export function* ListUserPackageCall() {
  try {
    const response: AxiosResponse = yield call(ListUserPackageApi);
    yield put(successlistUserPackage(response.data));
  } catch (error) {
    yield put(failurelistUserPackage(error));
  }
}

// listuser details
const ListUserPackageDetailsApi = (
  action: PayloadAction<IUserPackageDetails>
) => {
  return api.get("/v1/api/package/package-details", { params: action.payload });
};

export function* ListUserPackageDetailsCall(
  action: PayloadAction<IUserPackageDetails>
) {
  try {
    const response: AxiosResponse = yield call(
      ListUserPackageDetailsApi,
      action
    );
    yield put(successlistUserPackageDetails(response.data));
  } catch (error) {
    yield put(failurelistUserPackageDetails(error));
  }
}

// listexam  user
const ListUserExamListApi = () => {
  return api.get("/v1/api/package/exam-list");
};

export function* ListUserExamListCall() {
  try {
    const response: AxiosResponse = yield call(ListUserExamListApi);
    yield put(successlistUserExamList(response.data));
  } catch (error) {
    yield put(failurelistUserExamList(error));
  }
}

// listpendingexam  user
const PendingExamListApi = () => {
  return api.get("/v1/api/exam/PendingExam");
};

export function* PendingExamListApiCall() {
  try {
    const response: AxiosResponse = yield call(PendingExamListApi);
    yield put(successpendingExamList(response.data));
  } catch (error) {
    yield put(failurependingExamList(error));
  }
}
