import { FunctionComponent, memo, useEffect, useState } from "react";
import {
  FormProvider,
  SubmitHandler,
  useForm,
  useWatch,
} from "react-hook-form";
import { IOptions } from "./IOptions";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import TextInputFieldC from "../../Components/TextInput/TextInputFieldC";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import { addOptions } from "../../Redux/Slices/Options/AddOptionsSlice";
import { EditOptions } from "../../Redux/Slices/Options/EditOptionsSlice";
import Select from "../../Components/Select/AutoComplete";
import { AutocompleteOption } from "../../Components/Select/ISelectProps";
import TextAreaInputField from "../../Components/TextAreaInput/TextAreaInputField";
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { DevTool } from "@hookform/devtools";

interface OptionsFormProps {
  formRef: React.RefObject<HTMLFormElement>;
  initialValues: IOptions | {};
  setInitialValues: React.Dispatch<React.SetStateAction<IOptions | {}>>;
}

const OptionsForm: FunctionComponent<OptionsFormProps> = (props) => {
  const dispatch = useAppDispatch();

  const { data: listQuestion } = useAppSelector(
    (state) => state.ListQuestionsSlice
  );
  const QuestionList = Array.isArray(listQuestion?.data)
    ? listQuestion?.data.map((listQuestion) => ({
        label: listQuestion.question!,
        value: listQuestion.id,
      }))
    : [];

  const optionSchema = z.object({
    optionname: z.string().min(1, "Option cannot be empty"),
    optionstatus: z.boolean(),
  });
  const userSchema = z
    .object({
      id: z.number().optional(),
      question_id: z.number({ message: "Please select question" }),
      option_type: z.string({ message: "Please select option type" }),
      option: z
        .array(optionSchema)
        .max(4, "Please enter up to four options")
        .optional(),
      option_url: z
        .array(z.string().min(1, "Option image cannot be empty"))
        .max(4, "Please add four option image")
        .optional(), // Limit instructions

      answer: z.boolean({
        message: "Please select the status",
      }),
    })
    .refine(
      (data) => {
        // If option_type is "TEXT", option must be filled; if it's "IMAGE", option_url must be filled.
        if (data.option_type === "Text") {
          return data.option && data.option.length > 0;
        }
        return true;
      },
      {
        message: "Option is required when option type is text",
        path: ["option"], // Error shown on the option field
      }
    )
    .refine(
      (data) => {
        if (data.option_type === "Image") {
          return data.option_url && data.option_url.length > 0;
        }
        return true;
      },
      {
        message: "Option URL is required when option type is image",
        path: ["option_url"], // Error shown on the option_url field
      }
    );

  const methods = useForm<IOptions>({
    defaultValues: props.initialValues,
    resolver: zodResolver(userSchema),
    mode: "onChange",
  });
  const selectedOptionType = useWatch({
    control: methods.control,
    name: "option_type",
  });

  const onSubmit: SubmitHandler<IOptions> = (val) => {
    if (val.id) {
      dispatch(EditOptions(val));
    } else {
      dispatch(addOptions(val));
    }
  };

  // Reset the other field when option_type changes
  useEffect(() => {
    if (selectedOptionType === "Text") {
      methods.setValue("option_url", [], { shouldValidate: true });
    } else if (selectedOptionType === "Image") {
      methods.setValue("option", [], { shouldValidate: true });
    }
  }, [selectedOptionType, methods]);
  useEffect(() => {
    return () => {
      props.setInitialValues({});
    };
  }, [dispatch, props]);

  const Optionstype = [
    {
      label: "Text",
      value: "Text",
    },

    {
      label: "Image",
      value: "Image",
    },
  ];

  // text
  const [instructionInput, setInstructionInput] = useState<string>("");
  const [instructions, setInstructions] = useState<
    { optionname: string; optionstatus: boolean }[]
  >([]);
  const { setValue, getValues } = methods;

  const handleAddInstruction = () => {
    const currentInstructions = getValues("option") || []; // Ensure it's an array
    if (instructionInput!.trim() && currentInstructions!.length! < 4) {
      const updatedInstructions = [
        ...currentInstructions,
        { optionname: instructionInput, optionstatus: false },
      ];
      setInstructions(updatedInstructions); // Update local state
      setValue("option", updatedInstructions, { shouldValidate: true }); // Update instructions field
      setInstructionInput(""); // Clear input field
    }
  };

  const handleDeleteInstruction = (index: number) => {
    const currentInstructions = getValues("option");
    const updatedInstructions = currentInstructions.filter(
      (_, i) => i !== index
    );
    setInstructions(updatedInstructions); // Update local state
    setValue("option", updatedInstructions); // Update instructions field
    methods.trigger("option"); // Force re-render to reflect changes
  };

  const handleCheckboxChange = (index: number) => {
    const currentInstructions = getValues("option");
    const updatedInstructions = currentInstructions.map((instruction, i) => ({
      ...instruction,
      optionstatus: i === index ? true : false, // Set clicked option to true, others to false
    }));
    setInstructions(updatedInstructions); // Update local state
    setValue("option", updatedInstructions, { shouldValidate: true }); // Update instructions field
  };

  // image

  const [imageInput, setimageInput] = useState<File | null>(null); // Store the file object
  const [image, setimage] = useState<string[]>([]);

  const handleAddimage = () => {
    const currentInstructions = getValues("option_url") || [];
    if (imageInput && currentInstructions.length < 4) {
      const updatedimage = [
        ...currentInstructions,
        URL.createObjectURL(imageInput),
      ];
      setimage(updatedimage); // Update local state with image URLs
      setValue("option_url", updatedimage, { shouldValidate: true }); // Update instructions field
      setimageInput(null); // Clear input field
    }
  };

  const handleDeleteimage = (index: number) => {
    const currentInstructions = getValues("option_url");
    const updatedimage = currentInstructions.filter((_, i) => i !== index);
    setimage(updatedimage); // Update local state
    setValue("option_url", updatedimage); // Update instructions field
    methods.trigger("option_url"); // Force re-render to reflect changes
  };

  return (
    <FormProvider {...methods}>
      <form
        ref={props.formRef}
        onSubmit={methods.handleSubmit(onSubmit)}
        className="form_content"
      >
        <DevTool control={methods.control} />
        <Select
          name="question_id"
          label="Select Question"
          options={QuestionList ?? []}
          value={
            QuestionList?.find(
              (list) => list.value === methods.getValues("question_id")
            ) ?? null
          }
          onChange={(_, value) => {
            const val = value as AutocompleteOption;
            methods.setValue("question_id", val?.value, {
              shouldValidate: true,
            });
          }}
        />
        <Select
          name="option_type"
          label="Select Option Type"
          options={Optionstype ?? []}
          value={
            Optionstype?.find(
              (list) => list.value === methods.getValues("option_type")
            ) ?? null
          }
          onChange={(_, value) => {
            const val = value as AutocompleteOption;
            methods.setValue("option_type", val?.value, {
              shouldValidate: true,
            });
          }}
        />

        {/* Instruction Input Field with TextArea */}
        {selectedOptionType === "Text" && (
          <>
            <Box display="flex">
              <TextField
                label="option"
                value={instructionInput}
                onChange={(e) => setInstructionInput(e.target.value)}
                fullWidth
                size="small"
                error={!!methods.formState.errors.option}
                helperText={
                  !!methods.formState.errors.option
                    ? String(methods.formState.errors.option.message)
                    : ""
                }
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddInstruction}
                sx={{
                  ml: 1,
                  width: "10px",
                  height: "2.5rem",
                  bgcolor: "primary.main", // Set the background color to primary
                  ":hover": {
                    bgcolor: "primary.main", // Keep the hover background color the same
                  },
                }}
              >
                <AddIcon sx={{ color: "white" }} />
              </Button>
            </Box>

            <Box>
              {getValues("option")?.map((instruction, index) => (
                <Box
                  key={index}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  bgcolor="lightgrey"
                  p={0.3}
                  my={1}
                  borderRadius="4px"
                  sx={{
                    ":hover button": { visibility: "visible" }, // Visibility for smoother transitions
                  }}
                >
                  <Checkbox
                    checked={instruction.optionstatus}
                    onChange={() => handleCheckboxChange(index)}
                  />
                  <Typography
                    sx={{
                      flexGrow: 1, // Make text take available space
                      wordWrap: "break-word", // Ensure long text wraps
                      overflow: "hidden",
                    }}
                  >
                    {instruction.optionname}
                  </Typography>
                  <IconButton onClick={() => handleDeleteInstruction(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))}
            </Box>
          </>
        )}
        {selectedOptionType === "Image" && (
          <>
            <Box display="flex">
              {/* Create a custom file input using a TextField */}
              <TextField
                fullWidth
                type="file"
                size="small"
                onChange={(e: any) => {
                  const file = e.target.files ? e.target.files[0] : null;
                  if (file) {
                    setimageInput(e.target.files ? e.target.files[0] : null);
                  }
                }}
                error={!!methods.formState.errors.option_url}
                helperText={
                  !!methods.formState.errors.option_url
                    ? String(methods.formState.errors.option_url.message)
                    : ""
                }
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddimage}
                sx={{
                  ml: 1,
                  width: "10px",
                  height: "2.5rem",
                  bgcolor: "primary.main",
                  ":hover": {
                    bgcolor: "primary.main", // Keep hover background color the same
                  },
                }}
              >
                <AddIcon sx={{ color: "white" }} />
              </Button>
            </Box>

            {getValues("option_url")?.map((option, index) => (
              <Box
                key={index}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                bgcolor="lightgrey"
                p={0.3}
                my={1}
                borderRadius="4px"
                sx={{
                  ":hover button": { visibility: "visible" }, // Visibility for smoother transitions
                }}
              >
                <img
                  src={option}
                  alt={`Uploaded ${index}`}
                  style={{
                    maxHeight: "100px",
                    maxWidth: "100%",
                    marginRight: "10px",
                  }}
                />{" "}
                {/* Display image */}
                <IconButton
                  onClick={() => handleDeleteimage(index)}
                  sx={{
                    visibility: "hidden", // Use visibility instead of display
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))}
          </>
        )}
      </form>
    </FormProvider>
  );
};

export default memo(OptionsForm);
