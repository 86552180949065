import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IExamModel } from "../../../Pages/ExamModel/IExamModel";

export const EditExamModelSlice = createSlice({
  name: "EditExamModelSlice",
  initialState: {} as IReduxState<IExamModel>,
  reducers: {
    EditExamModel: (state, _: PayloadAction<IExamModel>) => {
      state.isLoading = true;
    },
    successEditExamModel: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureEditExamModel: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearEditExamModelResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  EditExamModel,
  failureEditExamModel,
  successEditExamModel,
  clearEditExamModelResponse,
} = EditExamModelSlice.actions;

export default EditExamModelSlice.reducer;
