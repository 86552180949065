import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IInstruction } from "../../../Pages/Instruction/IInstruction";

export const EditInstructionSlice = createSlice({
  name: "EditInstructionSlice",
  initialState: {} as IReduxState<IInstruction>,
  reducers: {
    EditInstruction: (state, _: PayloadAction<IInstruction>) => {
      state.isLoading = true;
    },
    successEditInstruction: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureEditInstruction: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearEditInstructionResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  EditInstruction,
  failureEditInstruction,
  successEditInstruction,
  clearEditInstructionResponse,
} = EditInstructionSlice.actions;

export default EditInstructionSlice.reducer;
