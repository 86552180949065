import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IStudentExam } from "../../../Pages/Exam/IExam";

export const AddStudentExamSlice = createSlice({
  name: "AddStudentExamSlice",
  initialState: {} as IReduxState<IStudentExam>,
  reducers: {
    addStudentExam: (state, _: PayloadAction<IStudentExam>) => {
      state.isLoading = true;
    },
    successaddStudentExam: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureaddStudentExam: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearAddStudentExamResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  addStudentExam,
  failureaddStudentExam,
  successaddStudentExam,
  clearAddStudentExamResponse,
} = AddStudentExamSlice.actions;

export default AddStudentExamSlice.reducer;
