import { FunctionComponent, memo, useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import TextInputFieldC from "../../Components/TextInput/TextInputFieldC";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import Select from "../../Components/Select/AutoComplete";
import { AutocompleteOption } from "../../Components/Select/ISelectProps";
import TextAreaInputField from "../../Components/TextAreaInput/TextAreaInputField";
import NumberInputField from "../../Components/NumberInput/NumberInputField";
import { EditUser } from "../../Redux/Slices/User/EditUserSlice";
import { addUser } from "../../Redux/Slices/User/AddUserSlice";
import { IUserAdmin } from "../UserAdmin/IUserAdmin";
import { EditUserAdmin } from "../../Redux/Slices/UserAdmin/EditUserAdminSlice";
import { addUserAdmin } from "../../Redux/Slices/UserAdmin/AddUserAdminSlice";
import { registrationStart } from "../../Redux/Slices/Registration/RegistrationSlicer";

interface UserFormProps {
  formRef: React.RefObject<HTMLFormElement>;
  initialValues: IUserAdmin;
  setInitialValues: React.Dispatch<React.SetStateAction<IUserAdmin>>;
}

const UserForm: FunctionComponent<UserFormProps> = (props) => {
  const dispatch = useAppDispatch();

  const { data: listRole } = useAppSelector((state) => state.ListRoleSlice);

  const ListRole = Array.isArray(listRole?.data)
    ? listRole?.data.map((listRole) => ({
        label: listRole.name!,
        value: listRole.id,
      }))
    : [];

  const userSchema = z.object({
    id: z.number().optional(),
    username: z
      .string({ message: "Please enter name" })
      .min(1, { message: "Please enter name" }),
    email: z
      .string({
        required_error: "Please enter a valid email ID",
        message: "Please enter a valid email ID",
      })
      .email({
        message: "Please enter a valid email ID",
      }),
    role: z.number({ message: "Please select role" }),
    phone_no: z
      .number({ message: "Please enter phone number" })
      .nonnegative({ message: "Phone number must be exactly 10 digits" }),
    // .refine(
    //   (value) =>
    //     value.toString().length <= 10 && value.toString().length >= 10,
    //   {
    //     message: "Phone number must contain only 10 digits",
    //   }
    // ),
    status: z.boolean({
      message: "Please select the status",
    }),
  });

  const methods = useForm<IUserAdmin>({
    defaultValues: {
      ...props.initialValues,
      status: props.initialValues.id ? props.initialValues.status : true,
      phone_no: props.initialValues?.id
        ? Number(props.initialValues?.phone_no)
        : undefined,
    },
    resolver: zodResolver(userSchema),
    mode: "onChange",
  });

  const onSubmit: SubmitHandler<IUserAdmin> = (val) => {
    if (val.id) {
      dispatch(EditUserAdmin({ ...val, phone_no: String(val.phone_no) }));
    } else {
      dispatch(registrationStart({ ...val, phone_no: String(val.phone_no) }));
    }
  };

  useEffect(() => {
    return () => {
      props.setInitialValues({});
    };
  }, [dispatch, props]);

  // Status list
  const RoleSatus = [
    {
      label: "Active",
      value: true,
    },

    {
      label: "Inactive",
      value: false,
    },
  ];

  return (
    <FormProvider {...methods}>
      <form
        ref={props.formRef}
        onSubmit={methods.handleSubmit(onSubmit)}
        className="form_double_input"
      >
        <TextInputFieldC name="username" label="Name" type="text" />
        <TextInputFieldC name="email" label="Email" type="text" />
        <NumberInputField
          name="phone_no"
          label="Phone Number"
          type="number"
          disabledScrollBar
        />

        <Select
          name="role"
          label="Role"
          options={ListRole ?? []}
          value={
            ListRole?.find(
              (list) => list.value === methods.getValues("role")
            ) ?? null
          }
          onChange={(_, value) => {
            const val = value as AutocompleteOption;
            methods.setValue("role", val?.value, {
              shouldValidate: true,
            });
          }}
        />
        <Select
          name="status"
          label="Status"
          options={RoleSatus}
          value={
            RoleSatus?.find(
              (role) => role.value === methods.getValues("status")
            ) ?? null
          }
          onChange={(_, value) => {
            const val = value as AutocompleteOption;
            methods.setValue("status", val?.value, {
              shouldValidate: true,
            });
          }}
        />
      </form>
    </FormProvider>
  );
};

export default memo(UserForm);
