import { FunctionComponent } from "react";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";

interface ErrorPageProps {}

const ErrorPage: FunctionComponent<ErrorPageProps> = () => {
  const error = useRouteError();
  let errorMessage: string;
  let errorCode: number;

  if (isRouteErrorResponse(error)) {
    // error is type `ErrorResponse`
    errorMessage = error.statusText;
    errorCode = error.status;
  } else if (error instanceof Error) {
    errorMessage = error.message;
  } else if (typeof error === "string") {
    errorMessage = error;
  } else {
    console.error(error);
    errorMessage = "Unknown error";
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <h1>Oops {isRouteErrorResponse(error) && error.status}!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{errorMessage || (isRouteErrorResponse(error) && error.data)}</i>
      </p>
      {/* <div>{isRouteErrorResponse(error) && error.status}</div>
      <div> {errorMessage}</div>
      <div>{isRouteErrorResponse(error) && error.data}</div> */}
    </div>
  );
};

export default ErrorPage;
