import React, { FunctionComponent, useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import ButtonField from "../../Components/Button/ButtonField";
import {
  CircularProgress,
  Divider,
  InputAdornment,
  SvgIcon,
} from "@mui/material";
import "../Login/Login.scss";
import Background from "../../Assets/Images/cartoon-ai-robot-scene.jpg";
import TextInputFieldC from "../../Components/TextInput/TextInputFieldC";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { IResePassword } from "./IResetPassword";
import { ReactComponent as Lock } from "../../Assets/Images/lock.svg";
import { ReactComponent as OpenEye } from "../../Assets/Images/openEye.svg";
import { ReactComponent as ClosedEye } from "../../Assets/Images/closedEye.svg";
import IconButtonField from "../../Components/Button/IconButtonField";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import {
  resetPasswordStart,
  clearResetPasswordStatus,
} from "../../Redux/Slices/ResetPassword/ResetPassword";
import Snackbar from "../../Components/Snackbar/Snackbar";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as LoginLogo } from "../../Assets/Images/loginlogo.svg";
import { ReactComponent as Logmobile } from "../../Assets/Images/logomobile.svg";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ResetPassword: FunctionComponent = () => {
  const [show1, setShow1] = useState<boolean>(false);
  const [show2, setShow2] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const dispatch = useAppDispatch();
  const nav = useNavigate();
  const resetPasswordResponse = useAppSelector((state) => state.ResetPassword);

  const query = useQuery();
  const Getemail = query.get("email");

  const passwordSchema = z
    .object({
      create_password: z
        .string({
          required_error:
            "Password must be at least 8 characters long and include at least one uppercase letter, one special character, and one numeric value.",
          message:
            "Password must be at least 8 characters long and include at least one uppercase letter, one special character, and one numeric value.",
        })
        .min(8, {
          message:
            "Password must be at least 8 characters long and include at least one uppercase letter, one special character, and one numeric value.",
        })
        .regex(/[A-Z]/, {
          message:
            "Password must be at least 8 characters long and include at least one uppercase letter, one special character, and one numeric value.",
        }) // Uppercase letter
        .regex(/[0-9]/, {
          message:
            "Password must be at least 8 characters long and include at least one uppercase letter, one special character, and one numeric value.",
        }) // Number
        .regex(/[@$!%*?&#^()_+=[\]{};':"\\|,.<>/?-]/, {
          message:
            "Password must be at least 8 characters long and include at least one uppercase letter, one special character, and one numeric value.",
        }), // Special character

      confirm_password: z
        .string({
          required_error:
            "Confirm Password must be at least 8 characters long and include at least one uppercase letter, one special character, and one numeric value.",
          message:
            "Confirm Password must be at least 8 characters long and include at least one uppercase letter, one special character, and one numeric value.",
        })
        .min(8, {
          message:
            "Confirm Password must be at least 8 characters long and include at least one uppercase letter, one special character, and one numeric value.",
        })
        .regex(/[A-Z]/, {
          message:
            "Confirm Password must be at least 8 characters long and include at least one uppercase letter, one special character, and one numeric value.",
        }) // Uppercase letter
        .regex(/[0-9]/, {
          message:
            "Confirm Password must be at least 8 characters long and include at least one uppercase letter, one special character, and one numeric value.",
        }) // Number
        .regex(/[@$!%*?&#^()_+=[\]{};':"\\|,.<>/?-]/, {
          message:
            "Confirm Password must be at least 8 characters long and include at least one uppercase letter, one special character, and one numeric value.",
        }),
    })
    .refine((data) => data.create_password === data.confirm_password, {
      path: ["confirm_password"],
      message: "Passwords must match",
    });
  const methods = useForm<IResePassword>({
    defaultValues: {
      create_password: null,
      confirm_password: null,
    },
    resolver: zodResolver(passwordSchema),
    mode: "onChange",
  });
  const onSubmit: SubmitHandler<IResePassword> = (val) => {
    if (val.confirm_password) {
      const resetPassData = {
        email: Getemail,
        password: val.create_password,
        confirm_password: val.confirm_password,
      };
      dispatch(resetPasswordStart(resetPassData));
    }
  };
  useEffect(() => {
    const getEmail = localStorage.getItem("email");

    if (getEmail) {
      setEmail(getEmail);
    }
  }, []);
  useEffect(() => {
    if (resetPasswordResponse.data?.status) {
      setTimeout(() => {
        nav("/login");
      }, 2000);
    }
  }, [resetPasswordResponse]);

  useEffect(() => {
    return () => {
      dispatch(clearResetPasswordStatus());
    };
  }, []);

  return (
    <>
      <div className="parent-div">
        <Snackbar response={resetPasswordResponse.data} />
        <div className="child-div1s">
          <img
            src={Background}
            alt="AI Robot Scene"
            className="responsive-image"
          />
        </div>{" "}
        <div className="child-div2s">
          <div className="form-content">
            <FormProvider {...methods}>
              <form
                onSubmit={methods.handleSubmit(onSubmit)}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="main">
                  <div>
                    <div className="heading">
                      <SvgIcon
                        sx={{
                          width: "70%",
                          height: "70%",
                          padding: "15px",
                        }}
                        component={LoginLogo}
                        inheritViewBox
                        onClick={() => {
                          nav("/home");
                        }}
                      />
                    </div>
                    <div className="subHeading">Reset Password</div>
                    <div className="subHeadingforgotpassword">
                      Create a new password{" "}
                    </div>
                  </div>

                  <div className="textFields">
                    <div>
                      <div className="emailplaceholder">Password</div>

                      <TextInputFieldC
                        className="textField"
                        name="create_password"
                        // placeholder="Password"
                        type={show1 ? "text" : "password"}
                        variant="outlined"
                        InputProps={{
                          endAdornment: (
                            <IconButtonField onClick={() => setShow1(!show1)}>
                              <SvgIcon
                                sx={{
                                  width: "20px",
                                  height: "20px",
                                  color: "white",
                                }}
                                component={show1 ? OpenEye : ClosedEye}
                                inheritViewBox
                              />
                            </IconButtonField>
                          ),
                        }}
                      />
                    </div>
                    <div>
                      <div className="emailplaceholder">Confirm Password</div>

                      <TextInputFieldC
                        className="textField"
                        name="confirm_password"
                        // placeholder="Password"
                        type={show2 ? "text" : "password"}
                        variant="outlined"
                        InputProps={{
                          endAdornment: (
                            <IconButtonField onClick={() => setShow2(!show2)}>
                              <SvgIcon
                                sx={{
                                  width: "20px",
                                  height: "20px",
                                  color: "white",
                                }}
                                component={show2 ? OpenEye : ClosedEye}
                                inheritViewBox
                              />
                            </IconButtonField>
                          ),
                        }}
                      />
                    </div>
                  </div>
                  <div className="textFields">
                    <ButtonField
                      disabled={resetPasswordResponse.isLoading}
                      className="buttonfield"
                      type="submit"
                    >
                      Submit
                      {resetPasswordResponse.isLoading && (
                        <CircularProgress
                          size={24}
                          sx={{
                            color: "white",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-12px",
                            marginLeft: "-12px",
                          }}
                        />
                      )}
                    </ButtonField>
                  </div>
                </div>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
      <div className="backGroundbackground">
        <Snackbar response={resetPasswordResponse.data} />
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="mains">
              <div>
                <div className="heading">
                  <SvgIcon
                    sx={{
                      width: "70%",
                      height: "70%",
                      padding: "15px",
                      cursor: "pointer",
                    }}
                    component={Logmobile}
                    inheritViewBox
                    onClick={() => {
                      nav("/home");
                    }}
                  />
                </div>
                <div className="subHeading">Reset Password</div>
                <div className="subHeadingforgotpassword">
                  Create a new password{" "}
                </div>
              </div>

              <div className="textFields">
                <div>
                  <div className="emailplaceholder">Password</div>

                  <TextInputFieldC
                    className="textField"
                    name="create_password"
                    // placeholder="Password"
                    type={show1 ? "text" : "password"}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <IconButtonField onClick={() => setShow1(!show1)}>
                          <SvgIcon
                            sx={{
                              width: "20px",
                              height: "20px",
                              color: "white",
                            }}
                            component={show1 ? OpenEye : ClosedEye}
                            inheritViewBox
                          />
                        </IconButtonField>
                      ),
                    }}
                  />
                </div>
                <div>
                  <div className="emailplaceholder">Confirm Password</div>

                  <TextInputFieldC
                    className="textField"
                    name="confirm_password"
                    // placeholder="Password"
                    type={show2 ? "text" : "password"}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <IconButtonField onClick={() => setShow2(!show2)}>
                          <SvgIcon
                            sx={{
                              width: "20px",
                              height: "20px",
                              color: "white",
                            }}
                            component={show2 ? OpenEye : ClosedEye}
                            inheritViewBox
                          />
                        </IconButtonField>
                      ),
                    }}
                  />
                </div>
              </div>
              <div className="textFields">
                <ButtonField
                  disabled={resetPasswordResponse.isLoading}
                  className="buttonfield"
                  type="submit"
                >
                  Submit
                  {resetPasswordResponse.isLoading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: "white",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </ButtonField>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </>
  );
};

export default ResetPassword;
