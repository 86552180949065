import "./MultiSelect.scss";

import { FunctionComponent } from "react";
import { MultiSelectProps } from "./IMultiSelectProps";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Controller, useFormContext } from "react-hook-form";

const MultiSelect: FunctionComponent<MultiSelectProps> = (props) => {
  const {
    sx,
    value,
    defaultValue,
    options,
    id,
    loading,
    readOnly,
    disabled,
    fullWidth,
    disableCloseOnSelect,
    disableClearable,
    filterSelectedOptions,
    autoHighlight,
    loadingText,
    renderOption,
    label,
    noOptionsText,
    AutoCompleteClassName,
    freeSolo,
    size,
    onChange,
    getOptionDisabled,
    onOpen,
    onClose,
    getOptionLabel,
    onFocus,
    onBlur,

    limitTags,

    /*    input    */
    name,
    placeholder,
    variant,
    color,
    required,
    className,
    inputSx,
    error,
    helperText,
    shrink,
    inputLableStyle,
    labelSize,
  } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <Controller
      name={name!}
      control={control}
      render={({ field }) => (
        <Autocomplete
          {...field}
          multiple
          limitTags={1}
          disablePortal
          id={id}
          options={options ?? []}
          getOptionLabel={getOptionLabel}
          defaultValue={defaultValue}
          value={value}
          sx={sx}
          filterSelectedOptions={filterSelectedOptions}
          loading={loading}
          className={`GlobalAutoCompleteStyle ${AutoCompleteClassName}`}
          readOnly={readOnly}
          disabled={disabled}
          disableCloseOnSelect={disableCloseOnSelect}
          disableClearable={disableClearable}
          autoHighlight={autoHighlight}
          loadingText={loadingText}
          noOptionsText={noOptionsText}
          fullWidth={fullWidth}
          freeSolo={freeSolo}
          size={size}
          getOptionDisabled={getOptionDisabled}
          onChange={onChange}
          onOpen={onOpen}
          onClose={onClose}
          onFocus={onFocus}
          onBlur={onBlur}
          renderOption={renderOption}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              name={name}
              placeholder={placeholder}
              variant={variant}
              color={color}
              required={required}
              className={`GlobalInputStyle ${className}`}
              sx={inputSx}
              error={!!errors[name!]}
              helperText={
                errors[name!]?.message ? String(errors[name!]?.message) : ""
              }
              InputLabelProps={{
                shrink: shrink,
                size: labelSize,
                className: `GlobalInputLableStyle ${inputLableStyle}`,
              }}
            />
          )}
        />
      )}
    />
  );
};

export default MultiSelect;
