import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { UserPermissionRowsType } from "./IUserPermission";

export const UserPermissionRows = createSlice({
  name: "UserPermissionRows",
  initialState: {
    data: {},
    isLoading: false,
  } as IReduxState<UserPermissionRowsType[]>,
  reducers: {
    userPermissionRowsList: (state, _) => {
      state.isLoading = true;
    },
    successUserPermissionRows: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    failureUserPermissionRows: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    updateUserPermission: (
      state,
      action: PayloadAction<{
        id: number | string;
        row: UserPermissionRowsType;
      }>
    ) => {
      if (!state.data?.data) return;

      const index = state.data.data.findIndex(
        (data) => data.id === action.payload.id
      );

      if (index !== -1) {
        // Create a new data array with the updated item
        const newData = [...state.data.data];
        newData[index] = action.payload.row;

        // Update the state immutably
        state.data = {
          ...state.data,
          data: newData,
        };
      }
    },
  },
});

export const {
  userPermissionRowsList,
  successUserPermissionRows,
  failureUserPermissionRows,
  updateUserPermission,
} = UserPermissionRows.actions;
export default UserPermissionRows.reducer;
