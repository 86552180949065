import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IFeedBack } from "../../../Pages/FeedBack/IFeedBack";

export const AddFeedBackSlice = createSlice({
  name: "AddFeedBackSlice",
  initialState: {} as IReduxState<IFeedBack>,
  reducers: {
    addFeedBack: (state, _: PayloadAction<IFeedBack>) => {
      state.isLoading = true;
    },
    successaddFeedBack: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureaddFeedBack: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearAddFeedBackResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  addFeedBack,
  failureaddFeedBack,
  successaddFeedBack,
  clearAddFeedBackResponse,
} = AddFeedBackSlice.actions;

export default AddFeedBackSlice.reducer;
