import { FunctionComponent, useEffect, useRef, useState } from "react";
import "./User.scss";
import Table from "../../Components/Table/Table";
import CustomToolbar from "../../Components/Table/components/CustomToolbar";
import ButtonField from "../../Components/Button/ButtonField";
import { Columns } from "../../Components/Table/ITable";
import {
  GridRenderCellParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import { IUser } from "./IUser";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import Dialog from "../../Components/Dialog/Dialog";
import UserForm from "./UserForm";
import ResponseDialog from "../../Components/ResponseDialog/ResponseDialog";
import TableSearch from "../../Components/Table/components/TableSearch";
import { ReactComponent as Delete } from "../../Assets/Images/delete.svg";
import { ReactComponent as Edit } from "../../Assets/Images/edit.svg";
import { IDeleteDialog } from "../../Components/DeleteDialog/IDeleteDialog";
import IconButtonField from "../../Components/Button/IconButtonField";
import { SvgIcon } from "@mui/material";
import DeleteDialog from "../../Components/DeleteDialog/DeleteDialog";
import { ReactComponent as RefreshButton } from "../../Assets/Images/RefreshButton.svg";

import { listSideMenuPermission } from "../../Redux/Slices/SideMenuPermission/ListSideMenuPermissionSlice";
import { listExam } from "../../Redux/Slices/Exam/ListExamSlice";
import { clearAddUserResponse } from "../../Redux/Slices/User/AddUserSlice";
import { clearEditUserResponse } from "../../Redux/Slices/User/EditUserSlice";
import {
  clearDeleteUserResponse,
  deleteUser,
} from "../../Redux/Slices/User/DeleteUserSlice";
import { listUser } from "../../Redux/Slices/User/ListUserSlice";
import { listUserAdmin } from "../../Redux/Slices/UserAdmin/ListUserAdminSlice";
import { listRegion } from "../../Redux/Slices/User/ListRegionSlice";
import { listYearGroup } from "../../Redux/Slices/User/ListYearGroupSlice";
import { listRole } from "../../Redux/Slices/Role/ListRoleSlice";
import { deleteUserAdmin } from "../../Redux/Slices/UserAdmin/DeleteUserAdminSlice";

interface UserProps {}

const User: FunctionComponent<UserProps> = () => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [deleteDialogData, setDeleteData] = useState<IDeleteDialog>({});
  const [initialValues, setInitialValues] = useState<IUser>({});
  const dispatch = useAppDispatch();
  const { data: listUsers } = useAppSelector(
    (state) => state.ListUserAdminSlice
  );
  const { data: addUser } = useAppSelector((state) => state.RegistrationSlicer);
  const { data: EditUser } = useAppSelector(
    (state) => state.EditUserAdminSlice
  );
  const { data: deleteUsers } = useAppSelector(
    (state) => state.DeleteUserAdmin
  );

  // user permisssion
  const { data: userPermissionlist } = useAppSelector(
    (state) => state.SideMenuPermission
  );

  const to = "userprofile";

  const filteredList = userPermissionlist.Dashboard.filter(
    (item: any) => item.to === to
  );
  const fliterdata: any = filteredList[0]?.items;
  const finaldata = fliterdata[1];

  const formRef = useRef<HTMLFormElement>(null);
  const columns: Columns = [
    {
      field: "index",
      renderHeader: () => <b>S.No</b>,
      width: 90,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "student_name",
      renderHeader: () => <b>Student Name</b>,
      flex: 1,
      type: "string",
      headerAlign: "left",
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            <div>
              {params?.row?.username === null ? "-" : params?.row?.username}
            </div>
          </>
        );
      },
    },

    {
      field: "email",
      renderHeader: () => <b>Email ID</b>,
      flex: 1,
      type: "string",
      headerAlign: "left",
    },

    {
      field: "phone_no",
      renderHeader: () => <b>Mobile Number</b>,
      flex: 1,
      type: "string",
      headerAlign: "left",
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            <div>
              {params?.row?.phone_no === null ? "-" : params?.row?.phone_no}
            </div>
          </>
        );
      },
    },

    {
      field: "status",
      renderHeader: () => <b>Status</b>,
      flex: 1,
      type: "string",
      headerAlign: "left",
      cellClassName: (params) =>
        `activename ${params.row.status ? "active" : "inactive"}`,

      valueFormatter: (params) =>
        params.value === true ? "Active" : "Inactive",
    },
    {
      field: "action",
      renderHeader: () => <b>Action</b>,
      flex: 1,
      type: "actions",
      renderCell: (params: GridRenderCellParams<IUser>) => {
        return (
          <>
            <IconButtonField
              disabled={finaldata?.permissions?.edit === false}
              onClick={() => {
                setInitialValues({ ...params.row });
                setDialogOpen(true);
              }}
            >
              <SvgIcon sx={{ fill: "none" }} component={Edit} inheritViewBox />
            </IconButtonField>
            <IconButtonField
              disabled={finaldata?.permissions?.delete === false}
              onClick={() => {
                setDeleteData({
                  id: params.id,
                  name: params?.row?.student_name,
                });
                setDeleteDialogOpen(true);
              }}
            >
              <SvgIcon component={Delete} inheritViewBox />
            </IconButtonField>
          </>
        );
      },
    },
  ];

  const User = Array.isArray(listUsers?.data)
    ? listUsers?.data.map((user, index) => ({ ...user, index: index + 1 }))
    : [];

  useEffect(() => {
    dispatch(
      listUserAdmin({
        rolename: "STUDENT",
      })
    );
    dispatch(listRole());
    dispatch(listSideMenuPermission());
  }, [addUser, EditUser, deleteUsers, dispatch]);
  useEffect(() => {
    return () => {
      dispatch(clearAddUserResponse());
      dispatch(clearEditUserResponse());
      dispatch(clearDeleteUserResponse());
    };
  }, [dispatch]);
  return (
    <>
      <Table rows={User ?? []} columns={columns}>
        <CustomToolbar>
          <h6>Student</h6>
          <TableSearch />
          <ButtonField
            className="tabel_add_new_button"
            onClick={() => setDialogOpen(true)}
            toolTip="Add new"
            disabled={finaldata?.permissions?.add === false}
          >
            Add new
          </ButtonField>
          <IconButtonField
            className="refresh_btn"
            toolTip="Refresh"
            onClick={() =>
              dispatch(
                listUserAdmin({
                  rolename: "STUDENT",
                })
              )
            }
          >
            <SvgIcon
              className="refresh_svg_icon"
              component={RefreshButton}
              inheritViewBox
            />
          </IconButtonField>
        </CustomToolbar>
      </Table>

      <Dialog
        open={dialogOpen}
        dialogClassName="dialog_double_input"
        Dialog_Title={`${(initialValues as IUser).id ? "Update" : "Add"} User`}
        Dialog_Content={
          <UserForm
            formRef={formRef}
            initialValues={initialValues}
            setInitialValues={setInitialValues}
          />
        }
        Dialog_Actions={
          <>
            <ButtonField
              className="form_cancel_button"
              onClick={() => setDialogOpen(false)}
            >
              Cancel
            </ButtonField>
            <ButtonField
              className="form_save_button"
              onClick={() => formRef.current?.requestSubmit()}
            >
              Save
            </ButtonField>
          </>
        }
      />
      <ResponseDialog response={addUser} parentDialog={setDialogOpen} />
      <ResponseDialog response={EditUser} parentDialog={setDialogOpen} />
      <DeleteDialog
        deleteableName={deleteDialogData.name ?? ""}
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        deleteFunction={() => {
          dispatch(deleteUserAdmin(deleteDialogData.id));
        }}
        response={deleteUsers}
      />
    </>
  );
};

export default User;
