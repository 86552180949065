import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { ICloseSection } from "../../../Pages/Exam/IExam";

export const AddCloseSectionSlice = createSlice({
  name: "AddCloseSectionSlice",
  initialState: {} as IReduxState<ICloseSection>,
  reducers: {
    addCloseSection: (state, _: PayloadAction<ICloseSection>) => {
      state.isLoading = true;
    },
    successaddCloseSection: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureaddCloseSection: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearAddCloseSectionResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  addCloseSection,
  successaddCloseSection,
  failureaddCloseSection,
  clearAddCloseSectionResponse,
} = AddCloseSectionSlice.actions;

export default AddCloseSectionSlice.reducer;
