import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IQuestionType } from "../../../Pages/QuestionType/IQuestionType";

export const ListQuestionTypeSlice = createSlice({
  name: "ListQuestionTypeSlice",
  initialState: {} as IReduxState<IQuestionType[]>,
  reducers: {
    listQuestionType: (state) => {
      state.isLoading = true;
    },
    successlistQuestionType: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failurelistQuestionType: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  failurelistQuestionType,
  listQuestionType,
  successlistQuestionType,
} = ListQuestionTypeSlice.actions;

export default ListQuestionTypeSlice.reducer;
