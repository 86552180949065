import { FunctionComponent, useEffect } from "react";
import "./Purchased_Exam_List.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import { listUserPackageDetails } from "../../Redux/Slices/UserPackage/ListUserPackageDetailsSlice";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { listUserExamList } from "../../Redux/Slices/UserPackage/ListUserExamListSlice";
import { LinearProgress } from "@mui/material";

interface Purchased_Exam_ListProps {}

const Purchased_Exam_List: FunctionComponent<Purchased_Exam_ListProps> = () => {
  // Create an array of instruction objects
  const navigate = useNavigate();
  const { state: packageid } = useLocation();
  const dispatch = useAppDispatch();

  const { data: listUserExamListSlice } = useAppSelector(
    (state) => state.ListUserExamListSlice
  );
  const Package = Array.isArray(listUserExamListSlice?.data)
    ? listUserExamListSlice?.data.map((user, index) => ({
        ...user,
        index: index + 1,
      }))
    : [];

  const pkglist = Package?.find((pkg: any) => {
    return pkg?.package?.id === packageid;
  });

  useEffect(() => {
    dispatch(listUserExamList());
  }, []);

  return (
    <div className="Purchased-container">
      <div className="Purchased_main">
        <div className="Purchased_leftside">
          <div>{pkglist?.package?.package_name}</div>
        </div>
        <div className="Purchased_rightside">
          <LinearProgress
            variant="determinate"
            // value={card?.answered_percentage}
            sx={{ height: "7px", borderRadius: "10px" }}
          />
          {pkglist?.exams?.map((pkg, index) => (
            <Accordion key={index}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography>{pkg?.exam_name}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{pkg?.exam_description}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Purchased_Exam_List;
