import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IStudentExam } from "../../../Pages/Exam/IExam";

export const ListStudentExamSlice = createSlice({
  name: "ListStudentExamSlice",
  initialState: {} as IReduxState<IStudentExam[]>,
  reducers: {
    listStudentExam: (state) => {
      state.isLoading = true;
    },
    successlistStudentExam: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failurelistStudentExam: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearlistStudentExam: (state) => {
      state.data = {};
    },
  },
});

export const {
  failurelistStudentExam,
  listStudentExam,
  successlistStudentExam,
  clearlistStudentExam,
} = ListStudentExamSlice.actions;

export default ListStudentExamSlice.reducer;
