import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import api from "../../Axios/middleware";
import {
  failureaddInstruction,
  successaddInstruction,
} from "./AddInstructionSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { IInstruction } from "../../../Pages/Instruction/IInstruction";

import {
  failuredeleteInstruction,
  successdeleteInstruction,
} from "./DeleteInstructionSlice";
import {
  failurelistInstruction,
  successlistInstruction,
} from "./ListInstructionSlice";
import {
  failureEditInstruction,
  successEditInstruction,
} from "./EditInstructionSlice";

// list
const ListInstructionApi = () => {
  return api.get("/v1/api/Instruction/instructions");
};

export function* ListInstructionCall() {
  try {
    const response: AxiosResponse = yield call(ListInstructionApi);
    yield put(successlistInstruction(response.data));
  } catch (error) {
    yield put(failurelistInstruction(error));
  }
}

// add
const AddInstructionApi = (action: PayloadAction<IInstruction>) => {
  return api.post("/v1/api/Instruction/create-instruction", action.payload);
};

export function* AddInstructionCall(action: PayloadAction<IInstruction>) {
  try {
    const response: AxiosResponse = yield call(AddInstructionApi, action);
    yield put(successaddInstruction(response.data));
  } catch (error) {
    yield put(failureaddInstruction(error));
  }
}

// edit
const EditInstructionApi = (action: PayloadAction<IInstruction>) => {
  return api.put("/v1/api/Instruction/update-instruction/", action.payload, {
    params: { id: action.payload.id },
  });
};

export function* EditInstructionCall(action: PayloadAction<IInstruction>) {
  try {
    const response: AxiosResponse = yield call(EditInstructionApi, action);
    yield put(successEditInstruction(response.data));
  } catch (error) {
    yield put(failureEditInstruction(error));
  }
}

// delete
const DeleteInstructionApi = (action: PayloadAction<number>) => {
  return api.delete("/v1/api/Instruction/del-instruction/", {
    params: { id: action.payload },
  });
};

export function* DeleteInstructionCall(action: PayloadAction<number>) {
  try {
    const response: AxiosResponse = yield call(DeleteInstructionApi, action);
    yield put(successdeleteInstruction(response.data));
  } catch (error) {
    yield put(failuredeleteInstruction(error));
  }
}
