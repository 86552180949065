import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IExamHistory } from "../../../Pages/Exam_Histroy/IExam_History";

export const ListExamHistorySlice = createSlice({
  name: "ListExamHistorySlice",
  initialState: {} as IReduxState<IExamHistory[]>,
  reducers: {
    listExamHistory: (state) => {
      state.isLoading = true;
    },
    successlistExamHistory: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failurelistExamHistory: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  failurelistExamHistory,
  listExamHistory,
  successlistExamHistory,
} = ListExamHistorySlice.actions;

export default ListExamHistorySlice.reducer;
