import "./Chatbot.scss";
import { Box, Divider, Drawer, SvgIcon } from "@mui/material";
import { FunctionComponent, useEffect, useRef } from "react";
// import { DrawerHeader } from "../Sidnavbar/Sidnavbar";
import IconButtonField from "../Button/IconButtonField";
import { ChatbotProps, IChatbot } from "./IChatbot";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import TextInputFieldC from "../TextInput/TextInputFieldC";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { ReactComponent as Send } from "../../Assets/Images/sendicon.svg";
import CloseIcon from "@mui/icons-material/Close";
import Markdown from "react-markdown";
import rehypeKatex from "rehype-katex";
// import rehypeKatex from "rehype-mathjax";
import remarkMath from "remark-math";
import "katex/dist/katex.min.css";
import "katex/dist/katex.css";
import {
  chatbotStart,
  clearChatbotStatus,
} from "../../Redux/Slices/Chatbot/ChatbotSlicer";
import { ReactComponent as Chatbotboticon } from "../../Assets/Images/Chatboticonresult.svg";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const drawerWidth = 450;

const Chatbot: FunctionComponent<ChatbotProps> = (props) => {
  const dispatch = useAppDispatch();
  const { data: chatbotData } = useAppSelector((state) => state.ChatbotSlicer);

  const { open, setOpen, questiondata, imagedata, passage, instruction } =
    props;

  // chatbot body scroll ref
  const divRef = useRef<HTMLDivElement>(null);

  // form init
  const methods = useForm<IChatbot>();
  const formRef = useRef<HTMLFormElement>(null);

  // form submit
  const onSubmit: SubmitHandler<IChatbot> = (data) => {
    dispatch(
      chatbotStart({
        chat: data.chat,
        question: `${questiondata}(Note: The image URL provided is for reference to be analyzed: ${imagedata})`,
        session_id: "1",
        passage: passage,
        instruction: instruction,
      })
    );
    methods.reset({ chat: "" });
  };

  useEffect(() => {
    // scroll to bottom
    divRef.current?.lastElementChild?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }, [chatbotData]);

  return (
    <>
      <Drawer
        sx={{
          width: drawerWidth,
          marginTop: "60px",
          // flexShrink: 0,
          "& .MuiDrawer-paper": {
            padding: 1,
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <header className="chat_bot_header">
          <SvgIcon
            component={Chatbotboticon}
            sx={{ fontSize: "25px", marginRight: "10px" }}
            inheritViewBox
          />
          <span style={{ fontSize: "20px" }}>Smart Tutor</span>
          <IconButtonField
            sx={{ ml: "auto" }}
            onClick={() => {
              setOpen(false);
              dispatch(clearChatbotStatus());
            }}
          >
            <CloseIcon />
          </IconButtonField>
        </header>

        <Divider />
        <div ref={divRef} className="message_area">
          {chatbotData?.data &&
            chatbotData.data.map((data) => {
              return (
                <>
                  {data.user === "user" ? (
                    <>
                      <Markdown
                        children={data.message}
                        remarkPlugins={[remarkMath]}
                        rehypePlugins={[rehypeKatex]}
                        className="user_message"
                      />
                    </>
                  ) : (
                    <Markdown
                      children={data.message}
                      remarkPlugins={[remarkMath]}
                      rehypePlugins={[rehypeKatex]}
                      className="chat_bot_message"
                    />
                  )}
                </>
              );
            })}
        </div>
        <footer className="chatbot_footer">
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)} ref={formRef}>
              <TextInputFieldC
                name="chat"
                type="text"
                className="chat_input"
                size="small"
                fullWidth
                placeholder="Ask me anything..."
                InputProps={{
                  endAdornment: (
                    <IconButtonField
                      type="submit"
                      onClick={() => formRef.current?.requestSubmit()}
                    >
                      <SvgIcon
                        component={Send}
                        sx={{ fontSize: "30px" }}
                        inheritViewBox
                      />
                    </IconButtonField>
                  ),
                }}
              />
            </form>
          </FormProvider>
          <div style={{ display: "flex", alignItems: "center" }}>
            <ErrorOutlineIcon sx={{ color: "#5481a8" }} />
            <div
              style={{
                textAlign: "left",
                padding: "0px 0px 0px 10px",
                textTransform: "capitalize",
                color: "#5481a8",
                marginTop: "10px",
              }}
            >
              AI-generated responses may be inaccurate or misleading. Be sure to
              double-check response and source
            </div>
          </div>
        </footer>
      </Drawer>
    </>
  );
};

export default Chatbot;
