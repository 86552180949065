import "./Dashboard.scss";
import { FunctionComponent, useEffect } from "react";
import React, { PureComponent } from "react";
import { Box, Card, darken, LinearProgress, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import SchoolIcon from "@mui/icons-material/School"; // example icon, replace as needed
import {
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Scatter,
  ResponsiveContainer,
  BarChart,
  Cell,
} from "recharts";
import { dashboardCard } from "../../Redux/Slices/Dashboard/DashboardCard";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArticleIcon from "@mui/icons-material/Article";
import EditNoteIcon from "@mui/icons-material/EditNote";
const Dashboard: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { data: DashboardCards } = useAppSelector(
    (state) => state.DashboardCard
  );

  const Dashboarduser = DashboardCards?.data;

  // user permisssion
  const { data: userPermissionlist } = useAppSelector(
    (state) => state.SideMenuPermission
  );
  const to = "dashboard";
  const filteredList = userPermissionlist?.Dashboard?.filter(
    (item: any) => item.to === to
  );

  useEffect(() => {
    dispatch(dashboardCard());
  }, [dispatch]);

  const data = [
    {
      name: "Total Students",
      value: Dashboarduser?.total_users,
      fill: "#5e6aa5",
    }, // Green for correct
    {
      name: "Total Exam Attempted",
      value: Dashboarduser?.total_exams_attempted,
      fill: "#7e968c",
    }, // Red for incorrect
  ];
  const total_questions: any = Dashboarduser?.total_exams_available; // Example for Y-axis max value

  return (
    <>
      <Box className="dashbordflex" sx={{ display: "flex", gap: 2 }}>
        {/* Render three cards */}
        <Card
          sx={{
            display: "flex",
            alignItems: "center",
            padding: 2,
            marginBottom: 2,
            width: "100%",
            backgroundColor: "#6a7dd4",
            height: "15rem",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
            {/* Left icon */}
            <Box sx={{ marginRight: 2 }}>
              <AccountCircleIcon sx={{ color: "white", fontSize: "5rem" }} />
            </Box>

            {/* Right side content */}
            <Box sx={{ flexGrow: 1 }}>
              <Typography
                variant="h6"
                className="dashboardfont"
                sx={{ color: "white", fontSize: "20px" }}
              >
                Total Number of Students
              </Typography>
              <Typography
                className="dashboardfont"
                variant="h4"
                sx={{ color: "white" }}
              >
                {Dashboarduser?.total_users}
              </Typography>
            </Box>
          </Box>
        </Card>
        <Card
          sx={{
            display: "flex",
            alignItems: "center",
            padding: 2,
            marginBottom: 2,
            width: "100%",
            backgroundColor: "#6e338d",
            height: "15rem",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
            {/* Left icon */}
            <Box sx={{ marginRight: 2 }}>
              <ArticleIcon sx={{ color: "white", fontSize: "5rem" }} />
            </Box>

            {/* Right side content */}
            <Box sx={{ flexGrow: 1 }}>
              <Typography
                variant="h6"
                className="dashboardfont"
                sx={{ color: "white", fontSize: "20px" }}
              >
                Total Number of Exams Available
              </Typography>
              <Typography
                className="dashboardfont"
                variant="h4"
                sx={{ color: "white" }}
              >
                {Dashboarduser?.total_exams_available}
              </Typography>
            </Box>
          </Box>
        </Card>
        <Card
          sx={{
            display: "flex",
            alignItems: "center",
            padding: 2,
            marginBottom: 2,
            width: "100%",
            backgroundColor: "#ff7070",
            height: "15rem",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
            {/* Left icon */}
            <Box sx={{ marginRight: 2 }}>
              <EditNoteIcon sx={{ color: "white", fontSize: "5rem" }} />
            </Box>

            {/* Right side content */}
            <Box sx={{ flexGrow: 1 }}>
              <Typography
                variant="h6"
                className="dashboardfont"
                sx={{ color: "white", fontSize: "20px" }}
              >
                Total Number of Attempted Exams
              </Typography>
              <Typography
                className="dashboardfont"
                variant="h4"
                sx={{ color: "white" }}
              >
                {Dashboarduser?.total_exams_attempted}
              </Typography>
            </Box>
          </Box>
        </Card>
      </Box>
      <div className="overexam">Student Exam Usage Analysis</div>
      <ResponsiveContainer width="70%" height={300}>
        <BarChart
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          layout="horizontal" // Ensures a horizontal layout
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="category" dataKey="name" /> {/* X-axis shows names */}
          <YAxis type="number" domain={[0, total_questions]} />{" "}
          {/* Y-axis shows values */}
          <Tooltip />
          {/* <Legend /> */}
          <Bar
            dataKey="value"
            isAnimationActive={true}
            label={{ position: "top", fill: "#333", fontSize: 12 }}
            barSize={40} // Adjust bar size
          >
            {data?.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.fill} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default Dashboard;
