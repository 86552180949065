import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IMenu } from "../../../Pages/Menu/IMenu";

export const DeleteMenuSlice = createSlice({
  name: "DeleteMenu",
  initialState: {} as IReduxState<IMenu>,
  reducers: {
    deleteMenu: (state, _) => {
      state.isLoading = true;
    },
    successdeleteMenu: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failuredeleteMenu: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearDeleteMenuResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  deleteMenu,
  failuredeleteMenu,
  successdeleteMenu,
  clearDeleteMenuResponse,
} = DeleteMenuSlice.actions;

export default DeleteMenuSlice.reducer;
