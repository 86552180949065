import { PayloadAction } from "@reduxjs/toolkit";
import api from "../../Axios/middleware";
import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import { encode } from "../../../Utils/encodeDecode";
import { failureRegistration, successRegistration } from "./RegistrationSlicer";

const RegistrationApi = (action: PayloadAction<{}>) => {
  return api.post("/v1/api/user/registration", action.payload);
};

export function* RegistrationCall(action: PayloadAction<{}>) {
  try {
    const respons: AxiosResponse = yield call(RegistrationApi, action);
    yield put(successRegistration(respons.data));
  } catch (error) {
    yield put(failureRegistration(error));
  }
}
