import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import api from "../../Axios/middleware";

import { PayloadAction } from "@reduxjs/toolkit";
import { failurependingExam, successpendingExam } from "./PendingExamSlice";
import { IPendingExam } from "../../../Pages/DashboardUser/IDashboardUser";

// list exam id

const PendingExamApi = (action: PayloadAction<IPendingExam>) => {
  return api.get("/v1/api/exam/ResumeExam", { params: action.payload });
};

export function* PendingExamCall(action: PayloadAction<IPendingExam>) {
  try {
    const response: AxiosResponse = yield call(PendingExamApi, action);
    yield put(successpendingExam(response.data));
  } catch (error) {
    yield put(failurependingExam(error));
  }
}
