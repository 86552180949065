import React, { useEffect, useRef, useState } from "react";
import { Card, Typography, Box, LinearProgress } from "@mui/material";
import "./Exam_History.scss"; // Import SCSS styles
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import { listExamHistory } from "../../Redux/Slices/ExamHistory/ListExamHistorySlice";
import { listExamID } from "../../Redux/Slices/Exam/ListExamIDSlice";
import { listReViewExamID } from "../../Redux/Slices/ReViewExam/ListReViewExamSlice";
import { listReExamID } from "../../Redux/Slices/ReExam/ListReExamSlice";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const ExamHistory = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { data: ListExamHistorySlice } = useAppSelector(
    (state) => state.ListExamHistorySlice
  );
  const { data: AddFeedBackSlice } = useAppSelector(
    (state) => state.AddFeedBackSlice
  );
  const ExamHistorySlicelist: any = ListExamHistorySlice?.data;

  useEffect(() => {
    dispatch(listExamHistory());
  }, [dispatch, AddFeedBackSlice]);

  // down arrow
  const [showArrow, setShowArrow] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const container = containerRef.current;
      if (container) {
        const { scrollTop, scrollHeight, clientHeight } = container;
        setShowArrow(scrollHeight - scrollTop > clientHeight + 1); // Show arrow if not at the bottom
      }
    };

    const container: any = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      handleScroll(); // Initial check
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);
  return (
    <div className="ExamHistorymain">
      <div className="histroy">
        <div className="listexam">Exams History </div>
        <button
          className="card-buttonexamlist"
          onClick={() => {
            navigate("/home"); // Replace with your target route
          }}
        >
          Go to Dashboard Page
        </button>
      </div>

      {ExamHistorySlicelist?.length === 0 ? (
        <div style={{ marginBottom: "20px", marginTop: "20px" }}>
          <div className="customCardpendinghistory">
            <div className="textContenthistory">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <Typography variant="h5" className="textsytleexamlist">
                  Exam History Not Available
                </Typography>
                {/* <div>{formattedDate}</div> */}
              </div>

              <Typography variant="body2" className="textsytle">
                {/* {history?.exam_description} */}
                <br />
              </Typography>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                {/* <div
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <div className="feedbackword"> Feedback: </div>
                  <div className="card-buttonsexamgood">{feedbackText}</div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      ) : (
        ExamHistorySlicelist?.map((history: any, index: any) => {
          // Format the entry_date
          const formattedDate = new Date(history?.exit_time).toLocaleDateString(
            "en-GB",
            {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            }
          );

          const feedbackText = Array.isArray(history?.feed_back)
            ? history.feed_back.map((item: any) => item.feedback).join(", ") ||
              "Skipped"
            : history?.feed_back || "Skipped";

          return (
            <div
              style={{ marginBottom: "20px", marginTop: "20px" }}
              key={index}
            >
              <div className="customCardpendinghistory">
                <div className="textContenthistory">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h5" className="textsytleexamlist">
                      {history?.exam_name}
                    </Typography>
                    <div>{formattedDate}</div>
                  </div>

                  <Typography variant="body2" className="textsytle">
                    {history?.exam_description}
                    <br />
                  </Typography>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <div className="feedbackword"> Feedback: </div>
                      <div
                        className="card-buttonsexamgood"
                        style={{ marginRight: "5px" }}
                      >
                        {feedbackText}
                      </div>
                      <div className="feedbackword"> Attempt count : </div>
                      <div className="card-buttonsexamgood">
                        {history?.retake_count}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <button
                        className="card-buttonexam"
                        onClick={() => {
                          navigate("/examresult", { state: history?.id });
                        }}
                      >
                        Report
                      </button>

                      <button
                        className="card-buttonexamlist"
                        onClick={() => {
                          navigate("/instructionreExam", {
                            state: history?.id,
                          }); // Replace with your target route
                        }}
                      >
                        ReTake Exam
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      )}
      {showArrow && (
        <ArrowDownwardIcon
          style={{
            position: "fixed",
            color: "#009DFF",
            cursor: "pointer",
            fontSize: "28px",
            borderRadius: "15px",
            border: "1px solid",
            backgroundColor: "white",
            right: "25px",
            bottom: "20px",
          }}
          onClick={() => {
            const container: any = containerRef.current;
            if (container) {
              container.scrollBy({ top: 100, behavior: "smooth" });
            }
          }}
        />
      )}
    </div>
  );
};

export default ExamHistory;
