import "./Registration.scss";

import { CircularProgress, LinearProgress, SvgIcon } from "@mui/material";
import { useEffect, useState } from "react";
import { ReactComponent as ClosedEye } from "../../Assets/Images/closedEye.svg";
import { ReactComponent as LoginLogo } from "../../Assets/Images/loginlogo.svg";
import { ReactComponent as OpenEye } from "../../Assets/Images/openEye.svg";
import { ReactComponent as Google } from "../../Assets/Images/Google Button.svg";
import { ReactComponent as Facebook } from "../../Assets/Images/Facebook Button.svg";
import { ReactComponent as Logmobile } from "../../Assets/Images/logomobile.svg";

import ButtonField from "../../Components/Button/ButtonField";
import { Link, useNavigate } from "react-router-dom";
import IconButtonField from "../../Components/Button/IconButtonField";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";

import { IRegistrationForm } from "./IRegistration";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import TextInputFieldC from "../../Components/TextInput/TextInputFieldC";
import Snackbar from "../../Components/Snackbar/Snackbar";
import { registrationStart } from "../../Redux/Slices/Registration/RegistrationSlicer";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "../../Components/FireBase/firebase";
import { GoogleloginStart } from "../../Redux/Slices/LoginSlicer/GoogleLoginSlicer";

const Registration: React.FC = () => {
  const [show, setShow] = useState<boolean>(false);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const nav = useNavigate();
  const dispatch = useAppDispatch();

  const loginResponse = useAppSelector((state) => state.RegistrationSlicer);
  const GoogleloginResponse = useAppSelector(
    (state) => state.GoogleLoginSlicer
  );

  const loginSchema = z.object({
    username: z
      .string({ message: "Please enter name" })
      .min(1, { message: "Please enter name" }),

    email: z
      .string({
        required_error: "Please enter a valid email ID",
        message: "Please enter a valid email ID",
      })
      .email({
        message: "Please enter a valid email ID",
      }),
    password: z
      .string({
        required_error: "Please enter password",
        message: "Please enter password",
      })
      .min(8, { message: "Password must be at least 8 characters long" })
      .regex(/[A-Z]/, {
        message: "Password must contain at least one uppercase letter",
      }) // Uppercase letter
      .regex(/[0-9]/, {
        message: "Password must contain at least one number",
      }) // Number
      .regex(/[@$!%*?&#^()_+=[\]{};':"\\|,.<>/?-]/, {
        message: "Password must contain at least one special character",
      }), // Special character
  });
  const methods = useForm<IRegistrationForm>({
    defaultValues: {
      username: "",
      email: "",
      password: "",
    },
    resolver: zodResolver(loginSchema),
    mode: "onChange",
  });
  const onSubmit: SubmitHandler<IRegistrationForm> = (val) => {
    setIsSubmit(true);
    dispatch(
      registrationStart({
        ...val, // Spread the values of the form data
        confirm_password: val.password, // Explicitly set confirm_password equal to password
      })
    ); // localStorage.setItem("token", JSON.stringify(val));
    // nav("/dashboard");
  };
  // useEffect(() => {
  //   const getToken = localStorage.getItem("token");
  //   if (loginResponse.data?.status && getToken) {
  //     setTimeout(() => {
  //       nav("/dashboard");
  //     }, 1000);
  //   }
  // }, [loginResponse, nav]);

  // useEffect(() => {
  //   return () => {
  //     if (!isSubmit) {
  //       dispatch(clearLoginStatus());
  //     }
  //   };
  // }, []);

  const passwordValue = methods.watch("password", ""); // Watching the "password" field

  const getProgress = (passwordcheck: any) => {
    let progress = 0;
    let colorprogress = "";
    let messageprogress = "";

    // Check if the password contains a lowercase letter
    if (/[a-z]/.test(passwordcheck)) {
      progress += 30;
      colorprogress = "red";
      messageprogress = "Please enter a capital letter.";
    }

    // Check if the password contains a capital letter
    if (/[A-Z]/.test(passwordcheck)) {
      progress += 30;
      colorprogress = "yellow";
      messageprogress = "Please enter a number and special character.";
    }

    // Check if the password contains a number or a special character
    if (
      /[A-Z]/.test(passwordcheck) &&
      /\d/.test(passwordcheck) &&
      /[!@#$%^&*(),.?":{}|<>]/.test(passwordcheck)
    ) {
      progress += 40;
      colorprogress = "green";
      messageprogress = "Password strength is good.";
    }

    return { progress, colorprogress, messageprogress };
  };

  const progress = getProgress(passwordValue); // Calculate progress based on the password value

  // google resgiter

  function googleLogin() {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const user: any = result.user;

        if (user) {
          dispatch(
            GoogleloginStart({
              token: user?.accessToken,
              islogin: false,
            })
          );
        }
      })
      .catch((error) => {
        // Check if the error is due to the user closing the popup
        if (error.code === "auth/popup-closed-by-user") {
          alert("Popup closed before login was completed. Please try again.");
        } else {
          alert("An error occurred during login. Please try again.");
        }
      });
  }

  useEffect(() => {
    methods.reset({ username: "", email: "", password: "" }); // Resets all form fields to default values
  }, [isSubmit, methods]);

  return (
    <>
      <Snackbar
        response={
          Object.keys(loginResponse.data || {}).length === 0
            ? GoogleloginResponse.data
            : loginResponse.data
        }
      />
      <div className="parent-div">
        <div className="child-div1"></div>
        <div className="child-div2">
          <div className="form-content">
            <FormProvider {...methods}>
              <form
                onSubmit={methods.handleSubmit(onSubmit)}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="mainRegisration">
                  <div>
                    <div className="heading">
                      <SvgIcon
                        sx={{
                          width: "70%",
                          height: "70%",
                          padding: "15px",
                        }}
                        component={LoginLogo}
                        inheritViewBox
                        onClick={() => {
                          nav("/home");
                        }}
                      />
                    </div>
                    <div className="subHeadingaccount">Create New Account</div>
                  </div>
                  <div className="textFields">
                    <TextInputFieldC
                      label="Name"
                      name="username"
                      type="text"
                      variant="outlined"
                    />
                    <TextInputFieldC
                      label="Email"
                      name="email"
                      type="text"
                      variant="outlined"
                    />
                    <TextInputFieldC
                      label="Password"
                      name="password"
                      type={show ? "text" : "password"}
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <IconButtonField
                            onClick={() => setShow(!show)}
                            aria-label={
                              show ? "Hide password" : "Show password"
                            }
                          >
                            <SvgIcon
                              key={show ? "open-eye-icon" : "closed-eye-icon"} // Ensures unique identity
                              sx={{
                                width: "20px",
                                height: "20px",
                                color: "white",
                              }}
                              component={show ? OpenEye : ClosedEye}
                              inheritViewBox
                            />
                          </IconButtonField>
                        ),
                      }}
                    />

                    <LinearProgress
                      variant="determinate"
                      value={progress.progress}
                      sx={{
                        height: "7px",
                        borderRadius: "10px",
                        backgroundColor: "#e0e0e0", // Background color for the entire bar
                        "& .MuiLinearProgress-bar": {
                          margin: "0px 0px 30px 0px",
                          backgroundColor: progress.colorprogress, // Color for the filled part of the bar
                        },
                      }}
                    />
                    <span
                      style={{
                        color: "#D32F2F",
                        width: "100%",
                        fontSize: "10px",
                      }}
                    >
                      {progress.colorprogress === "red"
                        ? "This password is weak"
                        : progress.colorprogress === "yellow"
                        ? "Please enter a number and special character"
                        : null}
                    </span>
                    {/* Display the current progress percentage */}
                  </div>

                  <div className="textFields">
                    <ButtonField
                      className="buttonfield"
                      disabled={loginResponse.isLoading}
                      type="submit"
                    >
                      Sign Up
                      {loginResponse.isLoading && (
                        <CircularProgress
                          size={24}
                          sx={{
                            color: "white",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-12px",
                            marginLeft: "-12px",
                          }}
                        />
                      )}
                    </ButtonField>
                  </div>
                  <div style={{ textAlign: "center" }}>Or</div>
                  <div style={{ textAlign: "center" }}>
                    <SvgIcon
                      sx={{
                        width: "25%",
                        height: "60px",
                        color: "white",
                        cursor: "pointer",
                      }}
                      component={Facebook}
                      inheritViewBox
                    />
                    <SvgIcon
                      sx={{
                        width: "25%",
                        height: "60px",
                        color: "white",
                        cursor: "pointer",
                      }}
                      component={Google}
                      onClick={googleLogin}
                      inheritViewBox
                    />
                  </div>
                  <div className="forgetPassword">
                    Already have an account?
                    <Link
                      to={"/login"}
                      style={{
                        color: "rgba(0, 118, 192, 1)",
                        paddingLeft: "10px",
                      }}
                    >
                      Sign in
                    </Link>
                  </div>
                </div>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
      <div className="backGround">
        <Snackbar
          response={
            Object.keys(loginResponse.data || {}).length === 0
              ? GoogleloginResponse.data
              : loginResponse.data
          }
        />
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="main">
              <div>
                <div className="heading">
                  <SvgIcon
                    sx={{
                      width: "70%",
                      height: "70%",
                      padding: "15px",
                    }}
                    component={Logmobile}
                    inheritViewBox
                  />
                </div>
                <div className="subHeading">Create New Account</div>
              </div>
              <div className="textFields">
                <TextInputFieldC
                  label="Name"
                  name="username"
                  type="text"
                  // placeholder="Email"
                  variant="outlined"
                />

                <TextInputFieldC
                  label="Email"
                  name="email"
                  type="text"
                  // placeholder="Email"
                  variant="outlined"
                />

                <TextInputFieldC
                  label="Password"
                  name="password"
                  type={show ? "text" : "password"}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <IconButtonField
                        onClick={() => setShow(!show)}
                        aria-label={show ? "Hide password" : "Show password"}
                      >
                        <SvgIcon
                          key={show ? "open-eye-icon" : "closed-eye-icon"} // Ensures unique identity
                          sx={{
                            width: "20px",
                            height: "20px",
                            color: "white",
                          }}
                          component={show ? OpenEye : ClosedEye}
                          inheritViewBox
                        />
                      </IconButtonField>
                    ),
                  }}
                />
                <div
                  style={{
                    color: progress.colorprogress,
                    textAlign: "end",
                    width: "100%",
                    marginTop: "16px",
                    marginBottom: "5px",
                  }}
                >
                  {progress.colorprogress === "red"
                    ? "weak"
                    : progress.colorprogress === "yellow"
                    ? "medium"
                    : progress.colorprogress === "green"
                    ? "strong"
                    : ""}
                </div>
                <LinearProgress
                  variant="determinate"
                  value={progress.progress}
                  sx={{
                    height: "7px",
                    borderRadius: "10px",
                    backgroundColor: "#e0e0e0", // Background color for the entire bar
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: progress.colorprogress, // Color for the filled part of the bar
                    },
                  }}
                />
                <div
                  style={{
                    color: "#D32F2F",
                    width: "100%",
                    marginTop: "3px",
                  }}
                >
                  {progress.colorprogress === "red"
                    ? "please enter the capital letter"
                    : progress.colorprogress === "yellow"
                    ? "Please enter a number and special character"
                    : null}
                </div>

                {/* Display the current progress percentage */}
              </div>

              <div className="textFields">
                <ButtonField
                  className="buttonfield"
                  disabled={loginResponse.isLoading}
                  type="submit"
                >
                  Sign Up
                  {loginResponse.isLoading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: "white",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </ButtonField>
              </div>
              <div style={{ textAlign: "center" }}>Or</div>
              <div style={{ textAlign: "center", margin: "25px 0px 25px 0px" }}>
                <SvgIcon
                  sx={{
                    width: "25%",
                    height: "60px",
                    color: "white",
                    cursor: "pointer",
                  }}
                  component={Facebook}
                  inheritViewBox
                />
                <SvgIcon
                  sx={{
                    width: "25%",
                    height: "60px",
                    color: "white",
                    cursor: "pointer",
                  }}
                  component={Google}
                  onClick={googleLogin}
                  inheritViewBox
                />
              </div>
              <div className="forgetPassword">
                Already have an account?
                <Link
                  to={"/login"}
                  style={{
                    color: "rgba(0, 118, 192, 1)",
                    paddingLeft: "10px",
                  }}
                >
                  Sign in
                </Link>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </>
  );
};

export default Registration;
