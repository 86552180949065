import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { ICourseslist } from "../../../Pages/Courses/ICourses";

export const DeleteCoursesSlice = createSlice({
  name: "DeleteCourses",
  initialState: {} as IReduxState<ICourseslist>,
  reducers: {
    deleteCourses: (state, _) => {
      state.isLoading = true;
    },
    successdeleteCourses: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failuredeleteCourses: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearDeleteCoursesResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  deleteCourses,
  failuredeleteCourses,
  successdeleteCourses,
  clearDeleteCoursesResponse,
} = DeleteCoursesSlice.actions;

export default DeleteCoursesSlice.reducer;
