"use client";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import IconButtonField from "../Button/IconButtonField";
import Menus from "../Menu/Menu";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import { clearLoginStatus } from "../../Redux/Slices/LoginSlicer/LoginSlicer";
import Avatars from "../Avatar/Avatars";
import "./AppBar.scss";
import { ReactComponent as Logo } from "../../Assets/Images/loginlogo.svg";
import { SvgIcon } from "@mui/material";
import AlterDialog from "../AlterDialog/AlterDialog";
import ButtonField from "../Button/ButtonField";
import { useEffect, useState } from "react";
import { EditStudentExam } from "../../Redux/Slices/StudentExam/EditStudentExamSlice";

const drawerWidth = 240;

export default function AppBarnav() {
  const nav = useNavigate();
  const { state: exam_id } = useLocation();
  const [ishome, setIshome] = useState<boolean>(false);
  const PendingTime = localStorage.getItem("pending_time");
  const Question_Type = Number(localStorage.getItem("question_type"));
  const StudentExamId = Number(localStorage.getItem("student_exam_id"));
  const { data: PopupStudentExamSlice } = useAppSelector(
    (state) => state.PopupStudentExamSlice
  );
  const dispatch = useAppDispatch();
  const Popupdisable = PopupStudentExamSlice;

  const { data: listexamlist } = useAppSelector(
    (state) => state.ListExamIDSlice
  );
  const List: any = listexamlist?.data?.exam_name;

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: "#FFFFFF",
            display: "flex",
            boxShadow: "0px 4px 40px 0px #0000000D",
          }}
        >
          <Toolbar className="appbarname">
            <Typography variant="h6" noWrap component="div">
              <SvgIcon
                sx={{
                  width: "123px",
                  height: "50px",
                  marginTop: "10px",
                  cursor: "pointer",
                  // fill: "currentcolor",
                }}
                color="primary"
                component={Logo}
                inheritViewBox
                onClick={() => {
                  // Check if "Mock Test" === List.data.exam_name first
                  if ("Mock Test" === List) {
                    nav("/home"); // Navigate to /home if condition is true
                  } else if (exam_id?.examID) {
                    // Check the next condition
                    setIshome(true);
                  } else {
                    // Fallback to navigate to /home
                    nav("/home");
                  }
                }}
              />
            </Typography>
            <Menus
              menuItems={[
                {
                  menu: "Profile",
                  onClick: () => {
                    nav("/studentprofile");
                  },
                },
                {
                  menu: "Logout",
                  onClick: () => {
                    localStorage.clear();
                    dispatch(clearLoginStatus());
                    nav("/login");
                  },
                },
              ]}
            >
              <IconButtonField onClick={() => {}}>
                <Avatars sx={{ height: "2rem", width: "2rem" }} />
              </IconButtonField>
            </Menus>
          </Toolbar>
        </AppBar>

        <Box
          component="main"
          sx={{ flexGrow: 1, height: "100vh", width: "100%" }}
        >
          <Outlet />
        </Box>
      </Box>
      <AlterDialog
        alterheading={"Warning"}
        alterableName={
          "You are currently in the middle of the examination. Please complete it and move forward, or resume the exam from the 'Exams in Progress' section until time is up"
        }
        open={ishome}
        setOpen={setIshome}
        buttonname={
          <>
            <ButtonField
              className="form_save_button"
              onClick={() => {
                console.log("his", PendingTime);
                dispatch(
                  EditStudentExam({
                    pending_time: PendingTime,
                    student_exam_id: StudentExamId,
                    question_type_id: Question_Type,
                  })
                );
                console.log("hi", PendingTime);

                nav("/home");
                setIshome(false);
              }}
            >
              Yes
            </ButtonField>
            <ButtonField
              className="form_cancel_button"
              onClick={() => {
                setIshome(false);
              }}
            >
              No
            </ButtonField>
          </>
        }
      />
    </>
  );
}
