import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { RouterProvider } from "react-router-dom";
import { router } from "./Routers/Routes";
import { Provider } from "react-redux";
import { store } from "./Redux/Store/Store";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import { PrimeReactProvider } from "primereact/api";
import App from "./App";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

// import * as firebase from "firebase/app";
// import "firebase/auth";

// In mui takes first prefrence on css file instead of default
const cache = createCache({
  key: "css",
  prepend: true,
});

// replace console.* for disable log on production
if (
  process.env.NODE_ENV === "production" ||
  process.env.REACT_APP_ENV === "LIVE"
) {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
// // this firebase  login method
// const firebaseConfig = JSON.parse(process.env.REACT_APP_Firebase_api_url!);
// firebase.initializeApp(firebaseConfig);

// strip
const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY!);

const options = {
  // passing the client secret obtained from the server
  clientSecret: "pi_3Q9gjyBnz1eu5sfz30nLq4ni_secret_XRA6NUjc3iTMKriwahfWAJYdO",
};

root.render(
  <React.StrictMode>
    <PrimeReactProvider>
      <CacheProvider value={cache}>
        <Provider store={store}>
          {/* <Elements stripe={stripePromise} options={options}> */}
          <App />
          {/* </Elements> */}
        </Provider>
      </CacheProvider>
    </PrimeReactProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
