import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IRole } from "../../../Pages/Role/IRole";

export const AddRoleSlice = createSlice({
  name: "AddRoleSlice",
  initialState: {} as IReduxState<IRole>,
  reducers: {
    addRole: (state, _: PayloadAction<IRole>) => {
      state.isLoading = true;
    },
    successaddRole: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureaddRole: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearAddRoleResponse: (state) => {
      state.data = {};
    },
  },
});

export const { addRole, failureaddRole, successaddRole, clearAddRoleResponse } =
  AddRoleSlice.actions;

export default AddRoleSlice.reducer;
