import { useEffect } from "react";
import "./App.scss";
import { useAppSelector } from "./Redux/Store/Hooks";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { setTheme } from "./Utils/setTheme";
import { RouterProvider } from "react-router-dom";
import { router } from "./Routers/Routes";
import palletes from "./Scss/Variables.module.scss";

function App() {
  const theme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 8,
            // "& .MuiOutlinedInput-notchedOutline": {
            //   borderColor: "blue",
            // },
            // "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            //   borderColor: "red",
            // },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 8,
          },
        },
      },
    },
    palette: {
      mode: "light",
      primary: {
        main: palletes.primary,
        light: "#F5EBFF",
        dark: "#000000",
      },
      secondary: {
        main: palletes.secondary,
      },
      success: {
        main: palletes.success,
      },
      error: {
        main: palletes.error,
      },
      warning: {
        main: palletes.warning,
      },
      info: {
        main: palletes.info,
      },
    },
    // background: {
    //   paper: '#fff',
    // },
    // text: {
    //   primary: '#173A5E',
    //   secondary: '#46505A',
    // },
    // action: {
    //   active: '#001E3C',
    // },
    // typography: {
    //   button: {
    //     textTransform: "none",
    //   },
    // },
    shape: {
      borderRadius: 8,
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        <RouterProvider router={router} />
      </div>
    </ThemeProvider>
  );
}

export default App;
