import React, { useEffect } from "react";
import UserPermission from "../../Components/UserPermission/UserPermission";
import "./UserPermissions.scss";

import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import { userPermissionRowsList } from "../../Redux/Slices/UserPermission/UserPermissionRowsSlicer";
import { useSearchParams } from "react-router-dom";
import { listRole } from "../../Redux/Slices/Role/ListRoleSlice";
import Snackbar from "../../Components/Snackbar/Snackbar";
import { listSideMenuPermission } from "../../Redux/Slices/SideMenuPermission/ListSideMenuPermissionSlice";

const UserPermissions: React.FC = () => {
  const dispatch = useAppDispatch();
  const permissionData = useAppSelector((state) => state.ListRoleSlice);
  const userPermissionRowsData = useAppSelector(
    (state) => state.UserPermissionRows
  );
  const userPermissionRowsDataUpdate = useAppSelector(
    (state) => state.EditUserPermission
  );
  const [searchParams] = useSearchParams();

  useEffect(() => {
    dispatch(listRole());
    dispatch(listSideMenuPermission());
    dispatch(userPermissionRowsList(searchParams.get("id")));
  }, [dispatch, searchParams]);

  const filteredRole = permissionData.data?.data
    ?.filter((role) => role.name !== "ADMIN") // Filter out roles where status is false
    .map((role) => ({
      value: role.id,
      label: role.name!,
      status: role.status,
    }));

  const rows = userPermissionRowsData.data?.data?.map((row) => ({
    ...row,
    add: row.add ?? false,
    edit: row.edit ?? false,
    delete: row.delete ?? false,
    view: row.view ?? false,
  }));
  console.log(userPermissionRowsDataUpdate);

  return (
    <>
      <Snackbar response={userPermissionRowsDataUpdate.data} />
      <UserPermission
        rows={rows ?? []}
        RoleOptions={filteredRole ?? []}
        getRoleValues={(val) => console.log(val)}
        getRoleOption={(val) => {
          dispatch(userPermissionRowsList(val?.value));
        }}
      />
    </>
  );
};

export default UserPermissions;
