import { FunctionComponent } from "react";
import Checkboxs from "@mui/material/Checkbox";
import { CheckboxProps } from "./ICheckbox";

const Checkbox: FunctionComponent<CheckboxProps> = (props) => {
  const {
    id,
    disabled,
    checked,
    defaultChecked,
    color,
    icon,
    checkedIcon,
    disableRipple,
    onChange,
    required,
    size,
    sx,
    checkboxCalssName,
    indeterminate,
    indeterminateIcon,
  } = props;

  return (
    <Checkboxs
      id={id}
      disabled={disabled}
      checked={checked}
      defaultChecked={defaultChecked}
      color={color}
      icon={icon}
      checkedIcon={checkedIcon}
      disableRipple={disableRipple}
      onChange={onChange}
      required={required}
      size={size}
      sx={sx}
      className={`checkbox ${checkboxCalssName}`}
      indeterminate={indeterminate}
      indeterminateIcon={indeterminateIcon}
    />
  );
};

export default Checkbox;
