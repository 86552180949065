import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import api from "../../Axios/middleware";
import { failureaddQuestions, successaddQuestions } from "./AddQuestionsSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { IQuestions } from "../../../Pages/Questions/IQuestions";

import {
  failuredeleteQuestions,
  successdeleteQuestions,
} from "./DeleteQuestionsSlice";
import {
  failurelistQuestions,
  successlistQuestions,
} from "./ListQuestionsSlice";
import {
  failureEditQuestions,
  successEditQuestions,
} from "./EditQuestionsSlice";

// list
const ListQuestionsApi = () => {
  return api.get("/v1/api/question/");
};

export function* ListQuestionsCall() {
  try {
    const response: AxiosResponse = yield call(ListQuestionsApi);
    yield put(successlistQuestions(response.data));
  } catch (error) {
    yield put(failurelistQuestions(error));
  }
}

// add
const AddQuestionsApi = (action: PayloadAction<IQuestions>) => {
  const formData = new FormData();
  formData.append("options", JSON.stringify(action.payload.options));
  action.payload.question_url &&
    formData.append("question_url", action.payload.question_url);
  if (action.payload.option_url) {
    action.payload.option_url.forEach((img) => {
      formData.append("option_url", img.option_url);
    });
  }

  return api.post("/v1/api/question/add-question", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },

    params: {
      questionType: action.payload.questionType,
      prompt: action.payload.prompt,
      question: action.payload.question,
      instruction: action.payload.instruction,
      explanation: action.payload.explanation,
      difficulty_level: action.payload.difficulty_level,
      source_type: action.payload.source_type,
      Status: action.payload.Status,
    },
  });
};

export function* AddQuestionsCall(action: PayloadAction<IQuestions>) {
  try {
    const response: AxiosResponse = yield call(AddQuestionsApi, action);
    yield put(successaddQuestions(response.data));
  } catch (error) {
    yield put(failureaddQuestions(error));
  }
}

// edit
const EditQuestionsApi = (action: PayloadAction<IQuestions>) => {
  const formData = new FormData();
  formData.append("options", JSON.stringify(action.payload.options));
  action.payload.question_url &&
    formData.append("question_url", action.payload.question_url);
  if (action.payload.option_url) {
    action.payload.option_url.forEach((img) => {
      formData.append("option_url", img.option_url);
    });
  }
  return api.put("/v1/api/question/update/", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    params: {
      id: action.payload.id,
      questionType: action.payload.questionType,
      prompt: action.payload.prompt,
      question: action.payload.question,
      instruction: action.payload.instruction,
      explanation: action.payload.explanation,
      difficulty_level: action.payload.difficulty_level,
      source_type: action.payload.source_type,
      Status: action.payload.Status,
    },
  });
};

export function* EditQuestionsCall(action: PayloadAction<IQuestions>) {
  try {
    const response: AxiosResponse = yield call(EditQuestionsApi, action);
    yield put(successEditQuestions(response.data));
  } catch (error) {
    yield put(failureEditQuestions(error));
  }
}

// delete
const DeleteQuestionsApi = (action: PayloadAction<number>) => {
  return api.delete("/v1/api/question/delete/", {
    params: { id: action.payload },
  });
};

export function* DeleteQuestionsCall(action: PayloadAction<number>) {
  try {
    const response: AxiosResponse = yield call(DeleteQuestionsApi, action);
    yield put(successdeleteQuestions(response.data));
  } catch (error) {
    yield put(failuredeleteQuestions(error));
  }
}
