import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IMockExam } from "../../../Pages/DashboardUser/IDashboardUser";

export const MockExamSlice = createSlice({
  name: "MockExamSlice",
  initialState: {} as IReduxState<IMockExam[]>,
  reducers: {
    listMockExam: (state) => {
      state.isLoading = true;
    },
    successlistMockExam: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failurelistMockExam: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
  },
});

export const { failurelistMockExam, listMockExam, successlistMockExam } =
  MockExamSlice.actions;

export default MockExamSlice.reducer;
