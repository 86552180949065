import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import api from "../../Axios/middleware";
import { failureaddFeedBack, successaddFeedBack } from "./AddFeedBackSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { IFeedBack } from "../../../Pages/FeedBack/IFeedBack";
import {
  failuredeleteFeedBack,
  successdeleteFeedBack,
} from "./DeleteFeedBackSlice";
import { failurelistFeedBack, successlistFeedBack } from "./ListFeedBackSlice";
import { failureEditFeedBack, successEditFeedBack } from "./EditFeedBackSlice";

// list
const ListFeedBackApi = () => {
  return api.get("/v1/api/feedback/get");
};

export function* ListFeedBackCall() {
  try {
    const response: AxiosResponse = yield call(ListFeedBackApi);
    yield put(successlistFeedBack(response.data));
  } catch (error) {
    yield put(failurelistFeedBack(error));
  }
}

// add
const AddFeedBackApi = (action: PayloadAction<IFeedBack>) => {
  return api.post("/v1/api/feedback/add", action.payload);
};

export function* AddFeedBackCall(action: PayloadAction<IFeedBack>) {
  try {
    const response: AxiosResponse = yield call(AddFeedBackApi, action);
    yield put(successaddFeedBack(response.data));
  } catch (error) {
    yield put(failureaddFeedBack(error));
  }
}

// edit
const EditFeedBackApi = (action: PayloadAction<IFeedBack>) => {
  return api.put("/v1/api/feedback/update", action.payload, {
    params: { id: action.payload.id },
  });
};

export function* EditFeedBackCall(action: PayloadAction<IFeedBack>) {
  try {
    const response: AxiosResponse = yield call(EditFeedBackApi, action);
    yield put(successEditFeedBack(response.data));
  } catch (error) {
    yield put(failureEditFeedBack(error));
  }
}

// delete
const DeleteFeedBackApi = (action: PayloadAction<number>) => {
  return api.delete("/v1/api/feedback/delete", {
    params: { FeedBack_id: action.payload },
  });
};

export function* DeleteFeedBackCall(action: PayloadAction<number>) {
  try {
    const response: AxiosResponse = yield call(DeleteFeedBackApi, action);
    yield put(successdeleteFeedBack(response.data));
  } catch (error) {
    yield put(failuredeleteFeedBack(error));
  }
}
