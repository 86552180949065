import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IFeedBack } from "../../../Pages/FeedBack/IFeedBack";

export const DeleteFeedBackSlice = createSlice({
  name: "DeleteFeedBack",
  initialState: {} as IReduxState<IFeedBack>,
  reducers: {
    deleteFeedBack: (state, _) => {
      state.isLoading = true;
    },
    successdeleteFeedBack: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failuredeleteFeedBack: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearDeleteFeedBackResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  deleteFeedBack,
  failuredeleteFeedBack,
  successdeleteFeedBack,
  clearDeleteFeedBackResponse,
} = DeleteFeedBackSlice.actions;

export default DeleteFeedBackSlice.reducer;
