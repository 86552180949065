import { initializeApp } from "firebase/app";
import {
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCBVb3CzLlbMveiZR5tTRQc6xe0TwLRx8E",
  authDomain: "everesttutoringwa-f1206.firebaseapp.com",
  projectId: "everesttutoringwa-f1206",
  storageBucket: "everesttutoringwa-f1206.appspot.com",
  messagingSenderId: "218397980477",
  appId: "1:218397980477:web:832d8d7d69af57c6037754",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
export const auth = getAuth(app);
export const db = getFirestore(app);

// Initialize Google Auth Provider
export const googleProvider = new GoogleAuthProvider();
export const facebookProvider = new FacebookAuthProvider();

export default app;
