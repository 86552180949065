import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IOptions } from "../../../Pages/Options/IOptions";

export const ListOptionsSlice = createSlice({
  name: "ListOptionsSlice",
  initialState: {} as IReduxState<IOptions[]>,
  reducers: {
    listOptions: (state) => {
      state.isLoading = true;
    },
    successlistOptions: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failurelistOptions: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
  },
});

export const { failurelistOptions, listOptions, successlistOptions } =
  ListOptionsSlice.actions;

export default ListOptionsSlice.reducer;
