import React, { FunctionComponent, useState } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { TextInputFieldProps } from "../TextInput/ITextInputFieldProps";
import TextInputField from "./TextInputField";
import IconButtonField from "../Button/IconButtonField";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ReactComponent as ClosedEye } from "../../Assets/Images/closedEye.svg";
import SvgIcon from "@mui/icons-material/Visibility";
interface ITextInputProps extends TextInputFieldProps {
  name: string;
}

const TextInputFieldC: FunctionComponent<ITextInputProps> = (props) => {
  const [focus, setFocus] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const { label, type, name, ...rest } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <TextInputField
          {...field}
          {...rest}
          label={label}
          type={show ? "text" : type}
          error={!!errors[name]}
          helperText={errors[name] ? String(errors[name]?.message) : ""}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          shrink={!!field.value || focus}
          // InputProps={
          //   type === "password" ? (
          //     {
          //       endAdornment: (
          //         <IconButtonField onClick={() => setShow(!show)}>
          //           <SvgIcon
          //             sx={{
          //               width: "20px",
          //               height: "20px",
          //             }}
          //             component={show ? VisibilityIcon : ClosedEye}
          //             inheritViewBox
          //           />
          //         </IconButtonField>
          //       ),
          //     }
          //   ) : (
          //     <></>
          //   )
          // }
        />
      )}
    />
  );
};

export default TextInputFieldC;
