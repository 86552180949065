import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import api from "../../Axios/middleware";
import { PayloadAction } from "@reduxjs/toolkit";
import { failureDashboardCard, successDashboardCard } from "./DashboardCard";



// cards data
const DashboardCardApi = () => {
  return api.get("/v1/api/user/dashboard");
};

export function* DashboardCardCall() {
  try {
    const response: AxiosResponse = yield call(DashboardCardApi);
    yield put(successDashboardCard(response.data));
  } catch (error) {
    yield put(failureDashboardCard(error));
  }
}


