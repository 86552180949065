import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import api from "../../Axios/middleware";
import { failureaddMenu, successaddMenu } from "./AddMenuSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { IMenu } from "../../../Pages/Menu/IMenu";

import { failuredeleteMenu, successdeleteMenu } from "./DeleteMenuSlice";
import { failurelistMenu, successlistMenu } from "./ListMenuSlice";
import { failureEditMenu, successEditMenu } from "./EditMenuSlice";

// list
const ListMenuApi = () => {
  return api.get("/v1/api/menu/get_menus");
};

export function* ListMenuCall() {
  try {
    const response: AxiosResponse = yield call(ListMenuApi);
    yield put(successlistMenu(response.data));
  } catch (error) {
    yield put(failurelistMenu(error));
  }
}

// add
const AddMenuApi = (action: PayloadAction<IMenu>) => {
  return api.post("/v1/api/menu/create_menu", action.payload);
};

export function* AddMenuCall(action: PayloadAction<IMenu>) {
  try {
    const response: AxiosResponse = yield call(AddMenuApi, action);
    yield put(successaddMenu(response.data));
  } catch (error) {
    yield put(failureaddMenu(error));
  }
}

// edit
const EditMenuApi = (action: PayloadAction<IMenu>) => {
  return api.put("/v1/api/menu/update-menu/", action.payload, {
    params: { id: action.payload.id },
  });
};

export function* EditMenuCall(action: PayloadAction<IMenu>) {
  try {
    const response: AxiosResponse = yield call(EditMenuApi, action);
    yield put(successEditMenu(response.data));
  } catch (error) {
    yield put(failureEditMenu(error));
  }
}

// delete
const DeleteMenuApi = (action: PayloadAction<number>) => {
  return api.delete("/v1/api/menu/user/", {
    params: { id: action.payload },
  });
};

export function* DeleteMenuCall(action: PayloadAction<number>) {
  try {
    const response: AxiosResponse = yield call(DeleteMenuApi, action);
    yield put(successdeleteMenu(response.data));
  } catch (error) {
    yield put(failuredeleteMenu(error));
  }
}
