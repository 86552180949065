import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IRole } from "../../../Pages/Role/IRole";
import { IExamAnswer } from "../../../Pages/Exam participation/IExam_participation";

export const AddExamAnswerSlice = createSlice({
  name: "AddExamAnswerSlice",
  initialState: {} as IReduxState<IExamAnswer>,
  reducers: {
    addExamAnswer: (state, _: PayloadAction<IExamAnswer>) => {
      state.isLoading = true;
    },
    successaddExamAnswer: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureaddExamAnswer: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearAddExamAnswerResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  addExamAnswer,
  failureaddExamAnswer,
  successaddExamAnswer,
  clearAddExamAnswerResponse,
} = AddExamAnswerSlice.actions;

export default AddExamAnswerSlice.reducer;
