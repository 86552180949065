import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IUser } from "../../../Pages/User/IUser";

export const DeleteUserSlice = createSlice({
  name: "DeleteUser",
  initialState: {} as IReduxState<IUser>,
  reducers: {
    deleteUser: (state, _) => {
      state.isLoading = true;
    },
    successdeleteUser: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failuredeleteUser: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearDeleteUserResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  deleteUser,
  failuredeleteUser,
  successdeleteUser,
  clearDeleteUserResponse,
} = DeleteUserSlice.actions;

export default DeleteUserSlice.reducer;
