import { FunctionComponent, memo, useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import { addExamModel } from "../../Redux/Slices/ExamModel/AddExamModelSlice";
import { EditExamModel } from "../../Redux/Slices/ExamModel/EditExamModelSlice";
import Select from "../../Components/Select/AutoComplete";
import { AutocompleteOption } from "../../Components/Select/ISelectProps";
import TextInputFieldC from "../../Components/TextInput/TextInputFieldC";
import { IExamModel } from "./IExamModel";
import { Button, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import NumberInputField from "../../Components/NumberInput/NumberInputField";
interface ExamModelFormProps {
  formRef: React.RefObject<HTMLFormElement>;
  initialValues: IExamModel;
  setInitialValues: React.Dispatch<React.SetStateAction<IExamModel>>;
}

// Define the Zod schema with minimum value requirements
const userSchema = z.object({
  id: z.number().optional(),
  model_name: z.string({ message: "Please enter model name" }),
  questionTypes: z.any().optional(),
  question_counts: z.any().optional(),
  durations: z.any().optional(),
  sequence: z.any().optional(),
  status: z.boolean({ message: "Please select status" }),
});

const ExamModelForm: FunctionComponent<ExamModelFormProps> = (props) => {
  const dispatch = useAppDispatch();

  // Fetching question types from Redux
  const { data: listQuestions } = useAppSelector(
    (state) => state.ListQuestionTypeSlice
  );
  const QuestionList = Array.isArray(listQuestions?.data)
    ? listQuestions?.data
        ?.filter((role) => role.status !== false)
        .map((listQuestions) => ({
          label: listQuestions?.name!,
          value: listQuestions.id,
        }))
    : [];

  const methods = useForm<IExamModel>({
    defaultValues: {
      ...props.initialValues,
      status: props.initialValues.id ? props.initialValues.status : true,
    },
    resolver: zodResolver(userSchema),
    mode: "onChange",
  });

  // State to store list of added entries
  const [displayDataList, setDisplayDataList] = useState<
    {
      questionTypes?: { label: string; value: number } | null;
      question_counts?: number | null;
      durations?: number | null;
      sequence?: number | null;
    }[]
  >([]);

  // Add new data to the list
  const handleAddData = () => {
    const questionTypes = methods.getValues("questionTypes");
    const question_counts = methods.getValues("question_counts");
    const durations = methods.getValues("durations");
    const sequence = methods.getValues("sequence");

    const selectedQuestionType = QuestionList?.find(
      (list) => list.value === questionTypes
    );

    // Check if the selected question type already exists in the display data list
    const isDuplicate = displayDataList.some(
      (item) => item.questionTypes?.value === questionTypes
    );

    // If not duplicate and both values are present, add the new data
    if (questionTypes && question_counts && !isDuplicate) {
      setDisplayDataList((prevList: any) => [
        ...prevList,
        {
          questionTypes: selectedQuestionType || null,
          question_counts,
          durations,
          sequence,
        },
      ]);
    }
    // reset
    methods.resetField("question_counts", { defaultValue: "" });
    methods.resetField("questionTypes");
    methods.resetField("durations", { defaultValue: "" });
    methods.resetField("sequence", { defaultValue: "" });
  };

  // Delete data by index
  const handleDeleteData = (index: number) => {
    setDisplayDataList((prevList) => prevList.filter((_, i) => i !== index));
  };

  // Submit handler
  const onSubmit: SubmitHandler<IExamModel> = (val) => {
    if (displayDataList.length >= 1) {
      if (val.id) {
        const editalue: any = {
          id: val.id,
          model_name: val.model_name,
          questionTypes: displayDataList.map(
            (item) => item.questionTypes?.value
          ),
          question_counts: displayDataList.map((item) => item.question_counts),
          durations: displayDataList.map((item) => item.durations),
          sequence: displayDataList.map((item) => item.sequence),
          status: val.status,
        };
        dispatch(EditExamModel(editalue));
      } else {
        const addvalue: any = {
          model_name: val.model_name,
          questionTypes: displayDataList.map(
            (item) => item.questionTypes?.value
          ),
          question_counts: displayDataList.map((item) => item.question_counts),
          durations: displayDataList.map((item) => item.durations),
          sequence: displayDataList.map((item) => item.sequence),
          status: val.status,
        };
        dispatch(addExamModel(addvalue));
      }
    }
  };

  const questionTypes = methods.getValues("questionTypes");
  const isDuplicate = displayDataList.some(
    (item) => item.questionTypes?.value === questionTypes
  );

  useEffect(() => {
    // Perform the effect
    if (methods.getValues("id")) {
      const questionTypes: any = methods.getValues("questionTypes"); // [10, 9, 13]
      const question_counts: any = methods.getValues("question_counts"); // [11, 111, 2]
      const durations: any = methods.getValues("durations"); // [11, 111, 2]
      const sequence: any = methods.getValues("sequence");

      // Map both arrays into the required format
      const mappedData = questionTypes?.map((typeValue: any, index: any) => {
        const selectedQuestionType = QuestionList?.find(
          (list) => list.value === typeValue
        );

        return {
          questionTypes: selectedQuestionType || {
            label: "Unknown",
            value: typeValue,
          },
          question_counts: question_counts[index]?.toString() || "N/A",
          durations: durations[index]?.toString() || "N/A",
          sequence: sequence[index]?.toString() || "NULL",
        };
      });
      setDisplayDataList(mappedData);
      // You can now use the mappedData for further logic if needed
    }

    // Cleanup function
    return () => {
      props.setInitialValues({});
    };
  }, []); // Empty dependency array to run this effect once on mount

  // Status list
  const RoleSatus = [
    {
      label: "Active",
      value: true,
    },

    {
      label: "Inactive",
      value: false,
    },
  ];
  return (
    <FormProvider {...methods}>
      <form
        ref={props.formRef}
        onSubmit={methods.handleSubmit(onSubmit)}
        // className="form_double_input"
        style={{ display: "grid", gap: "20px", marginTop: "10px" }}
      >
        {/* Input for Model Name */}
        <TextInputFieldC name="model_name" label="Model Name" type="text" />

        <div className="twofieldlist">
          {/* Select for Question Type */}
          <Select
            name="questionTypes"
            label="Question Type"
            sx={{ width: "50%" }}
            options={QuestionList ?? []}
            value={
              QuestionList?.find(
                (list) => list.value === methods.getValues("questionTypes")
              ) ?? null
            }
            onChange={(_, value) => {
              const val = value as AutocompleteOption;
              methods.setValue("questionTypes", val?.value, {
                shouldValidate: true,
              });
            }}
          />

          {/* Input for Question Counts */}
          <NumberInputField
            type="number"
            sx={{ width: "50%" }}
            name="question_counts"
            label="Question Count"
          />
          <NumberInputField
            type="number"
            sx={{ width: "50%" }}
            name="durations"
            label="Durations in Mintues"
          />
          <NumberInputField
            type="number"
            sx={{ width: "50%" }}
            name="sequence"
            label="Sequence"
          />
          <AddCircleOutlineIcon
            sx={{ fontSize: "30px", color: "#009DFF" }}
            onClick={handleAddData}
          />
        </div>
        {isDuplicate === true && (
          <div className="Altermeassge">
            <ReportGmailerrorredIcon
              fontSize="small"
              sx={{
                marginTop: "-1px",
                marginRight: "5px",
                color: "rgb(0 129 255)",
              }}
            />
            <div style={{ color: "rgb(0 129 255)" }}>
              Question type already exists. Please delete the existing type
              before adding a new one with the updated question count
            </div>
          </div>
        )}

        {displayDataList.length >= 1 ? (
          ""
        ) : (
          <div className="Altermeassge">
            <ReportGmailerrorredIcon
              fontSize="small"
              sx={{
                marginTop: "-1px",
                marginRight: "5px",
                color: "rgb(0 129 255)",
              }}
            />
            <div style={{ color: "rgb(0 129 255)" }}>
              Please add question types and their corresponding question counts
              with duration
            </div>
          </div>
        )}
        <Select
          name="status"
          label="Status"
          options={RoleSatus}
          value={
            RoleSatus?.find(
              (role) => role.value === methods.getValues("status")
            ) ?? null
          }
          onChange={(_, value) => {
            const val = value as AutocompleteOption;
            methods.setValue("status", val?.value, { shouldValidate: true });
          }}
        />
      </form>

      {/* Display the list of added data */}
      {displayDataList.length > 0 && (
        <div style={{ marginTop: "20px" }}>
          {displayDataList.map((data, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "10px",
                backgroundColor: "lightgrey",
                borderRadius: "4px",
                padding: "5px",
              }}
            >
              <div>
                <div>Question Type : {data.questionTypes?.label}</div>
                <div>Question Count : {data.question_counts}</div>
                <div>Durations : {data.durations} Mintues</div>
                <div>Sequence : {data.sequence} </div>
              </div>

              {/* Delete button for each entry */}
              <IconButton onClick={() => handleDeleteData(index)}>
                <DeleteIcon color="error" />
              </IconButton>
            </div>
          ))}
        </div>
      )}
    </FormProvider>
  );
};

export default memo(ExamModelForm);
