import { FunctionComponent, useEffect, useRef, useState } from "react";
import "./Package.scss";
import Table from "../../Components/Table/Table";
import CustomToolbar from "../../Components/Table/components/CustomToolbar";
import ButtonField from "../../Components/Button/ButtonField";
import { Columns } from "../../Components/Table/ITable";
import {
  GridRenderCellParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import { IPackagelist } from "./IPackage";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import Dialog from "../../Components/Dialog/Dialog";
import PackageForm from "./PackageForm";
import ResponseDialog from "../../Components/ResponseDialog/ResponseDialog";
import TableSearch from "../../Components/Table/components/TableSearch";
import { ReactComponent as Delete } from "../../Assets/Images/delete.svg";
import { ReactComponent as Edit } from "../../Assets/Images/edit.svg";
import { IDeleteDialog } from "../../Components/DeleteDialog/IDeleteDialog";
import IconButtonField from "../../Components/Button/IconButtonField";
import { SvgIcon } from "@mui/material";
import DeleteDialog from "../../Components/DeleteDialog/DeleteDialog";
import { ReactComponent as RefreshButton } from "../../Assets/Images/RefreshButton.svg";
import { listPackage } from "../../Redux/Slices/Package/ListPackageSlice";
import { clearAddPackageResponse } from "../../Redux/Slices/Package/AddPackageSlice";
import {
  clearDeletePackageResponse,
  deletePackage,
} from "../../Redux/Slices/Package/DeletePackageSlice";
import { clearEditPackageResponse } from "../../Redux/Slices/Package/EditPackageSlice";
import { listSideMenuPermission } from "../../Redux/Slices/SideMenuPermission/ListSideMenuPermissionSlice";
import { listExam } from "../../Redux/Slices/Exam/ListExamSlice";
import { listCourses } from "../../Redux/Slices/Courses/ListCoursesSlice";

interface PackageProps {}

const Package: FunctionComponent<PackageProps> = () => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [deleteDialogData, setDeleteData] = useState<IDeleteDialog>({});
  const [initialValues, setInitialValues] = useState<IPackagelist | {}>({
    course_ids: [],
    status: true,
  });
  const dispatch = useAppDispatch();
  const { data: listPackages } = useAppSelector(
    (state) => state.ListPackageSlice
  );
  const { data: addPackage } = useAppSelector((state) => state.AddPackageSlice);
  const { data: EditPackage } = useAppSelector(
    (state) => state.EditPackageSlice
  );
  const { data: deletePackages } = useAppSelector(
    (state) => state.DeletePackage
  );

  // user permisssion
  const { data: userPermissionlist } = useAppSelector(
    (state) => state.SideMenuPermission
  );

  const to = "masters";

  const filteredList = userPermissionlist.Dashboard.filter(
    (item: any) => item.to === to
  );
  const fliterdata: any = filteredList[0]?.items;
  const finaldata = fliterdata[5];

  const formRef = useRef<HTMLFormElement>(null);
  const columns: Columns = [
    {
      field: "index",
      renderHeader: () => <b>S.No</b>,
      width: 90,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "name",
      renderHeader: () => <b>Package</b>,
      flex: 1,
      type: "string",
      headerAlign: "left",
    },
    {
      field: "package_duration",
      renderHeader: () => <b>Duration</b>,
      flex: 1,
      type: "string",
      headerAlign: "left",
    },
    {
      field: "cost",
      renderHeader: () => <b>Cost</b>,
      flex: 1,
      type: "string",
      headerAlign: "left",
    },
    {
      field: "age",
      renderHeader: () => <b>Age</b>,
      flex: 1,
      type: "string",
      headerAlign: "left",
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            <div>
              {params.row.from_age} To {params.row.to_age}
            </div>
          </>
        );
      },
    },
    {
      field: "status",
      renderHeader: () => <b>Status</b>,
      flex: 1,
      type: "string",
      headerAlign: "left",
      cellClassName: (params) =>
        `activename ${params.row.status ? "active" : "inactive"}`,

      valueFormatter: (params) =>
        params.value === true ? "Active" : "Inactive",
    },
    {
      field: "action",
      renderHeader: () => <b>Action</b>,
      flex: 1,
      type: "actions",
      renderCell: (params: GridRenderCellParams<IPackagelist>) => {
        return (
          <>
            <IconButtonField
              disabled={finaldata?.permissions?.edit === false}
              onClick={() => {
                setInitialValues({ ...params.row });
                setDialogOpen(true);
              }}
            >
              <SvgIcon sx={{ fill: "none" }} component={Edit} inheritViewBox />
            </IconButtonField>
            <IconButtonField
              disabled={finaldata?.permissions?.delete === false}
              onClick={() => {
                setDeleteData({ id: params.id, name: params?.row?.name });
                setDeleteDialogOpen(true);
              }}
            >
              <SvgIcon component={Delete} inheritViewBox />
            </IconButtonField>
          </>
        );
      },
    },
  ];

  const Package = Array.isArray(listPackages?.data)
    ? listPackages?.data.map((user, index) => ({ ...user, index: index + 1 }))
    : [];

  useEffect(() => {
    dispatch(listCourses());
    dispatch(listPackage());
    dispatch(listSideMenuPermission());
  }, [addPackage, EditPackage, deletePackages, dispatch]);
  useEffect(() => {
    return () => {
      dispatch(clearAddPackageResponse());
      dispatch(clearEditPackageResponse());
      dispatch(clearDeletePackageResponse());
    };
  }, [dispatch]);
  return (
    <>
      <Table rows={Package ?? []} columns={columns}>
        <CustomToolbar>
          <h6>Package</h6>
          <TableSearch />
          <ButtonField
            className="tabel_add_new_button"
            onClick={() => setDialogOpen(true)}
            toolTip="Add new"
            disabled={finaldata?.permissions?.add === false}
          >
            Add new
          </ButtonField>
          <IconButtonField
            className="refresh_btn"
            toolTip="Refresh"
            onClick={() => dispatch(listPackage())}
          >
            <SvgIcon
              className="refresh_svg_icon"
              component={RefreshButton}
              inheritViewBox
            />
          </IconButtonField>
        </CustomToolbar>
      </Table>

      <Dialog
        open={dialogOpen}
        dialogClassName="dialog_double_input"
        Dialog_Title={`${
          (initialValues as IPackagelist).id ? "Update" : "Add"
        } Package`}
        Dialog_Content={
          <PackageForm
            formRef={formRef}
            initialValues={initialValues}
            setInitialValues={setInitialValues}
          />
        }
        Dialog_Actions={
          <>
            <ButtonField
              className="form_cancel_button"
              onClick={() => setDialogOpen(false)}
            >
              Cancel
            </ButtonField>
            <ButtonField
              className="form_save_button"
              onClick={() => formRef.current?.requestSubmit()}
            >
              Save
            </ButtonField>
          </>
        }
      />
      <ResponseDialog response={addPackage} parentDialog={setDialogOpen} />
      <ResponseDialog response={EditPackage} parentDialog={setDialogOpen} />
      <DeleteDialog
        deleteableName={deleteDialogData.name ?? ""}
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        deleteFunction={() => {
          dispatch(deletePackage(deleteDialogData.id));
        }}
        response={deletePackages}
      />
    </>
  );
};

export default Package;
