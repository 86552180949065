import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IMenu } from "../../../Pages/Menu/IMenu";

export const EditMenuSlice = createSlice({
  name: "EditMenuSlice",
  initialState: {} as IReduxState<IMenu>,
  reducers: {
    EditMenu: (state, _: PayloadAction<IMenu>) => {
      state.isLoading = true;
    },
    successEditMenu: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureEditMenu: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearEditMenuResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  EditMenu,
  failureEditMenu,
  successEditMenu,
  clearEditMenuResponse,
} = EditMenuSlice.actions;

export default EditMenuSlice.reducer;
