import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import api from "../../Axios/middleware";
import { PayloadAction } from "@reduxjs/toolkit";
import { IExam, IStudentExam } from "../../../Pages/Exam/IExam";
import {
  failurelistStudentExam,
  successlistStudentExam,
} from "./ListStudentExamSlice";
import {
  failureaddStudentExam,
  successaddStudentExam,
} from "./AddStudentExamSlice";
import {
  failureEditStudentExam,
  successEditStudentExam,
} from "./EditStudentExamSlice";

// list
const ListStudentExamApi = () => {
  return api.get("/v1/api/student-exam/get");
};

export function* ListStudentExamCall() {
  try {
    const response: AxiosResponse = yield call(ListStudentExamApi);
    yield put(successlistStudentExam(response.data));
  } catch (error) {
    yield put(failurelistStudentExam(error));
  }
}

// add
const AddStudentExamApi = (action: PayloadAction<IStudentExam>) => {
  return api.post("/v1/api/student-exam/", action.payload);
};

export function* AddStudentExamCall(action: PayloadAction<IStudentExam>) {
  try {
    const response: AxiosResponse = yield call(AddStudentExamApi, action);
    yield put(successaddStudentExam(response.data));
  } catch (error) {
    yield put(failureaddStudentExam(error));
  }
}

// edit
const EditStudentExamApi = (action: PayloadAction<IStudentExam>) => {
  return api.put("/v1/api/student-exam/skipexam", action.payload);
};

export function* EditStudentExamCall(action: PayloadAction<IStudentExam>) {
  try {
    const response: AxiosResponse = yield call(EditStudentExamApi, action);
    yield put(successEditStudentExam(response.data));
  } catch (error) {
    yield put(failureEditStudentExam(error));
  }
}
