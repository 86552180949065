import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import api from "../../Axios/middleware";
import { failureaddOptions, successaddOptions } from "./AddOptionsSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { IOptions } from "../../../Pages/Options/IOptions";

import {
  failuredeleteOptions,
  successdeleteOptions,
} from "./DeleteOptionsSlice";
import { failurelistOptions, successlistOptions } from "./ListOptionsSlice";
import { failureEditOptions, successEditOptions } from "./EditOptionsSlice";

// list
const ListOptionsApi = () => {
  return api.get("/v1/api/options/");
};

export function* ListOptionsCall() {
  try {
    const response: AxiosResponse = yield call(ListOptionsApi);
    yield put(successlistOptions(response.data));
  } catch (error) {
    yield put(failurelistOptions(error));
  }
}

// add
const AddOptionsApi = (action: PayloadAction<IOptions>) => {
  return api.post("/v1/api/options/create", action.payload);
};

export function* AddOptionsCall(action: PayloadAction<IOptions>) {
  try {
    const response: AxiosResponse = yield call(AddOptionsApi, action);
    yield put(successaddOptions(response.data));
  } catch (error) {
    yield put(failureaddOptions(error));
  }
}

// edit
const EditOptionsApi = (action: PayloadAction<IOptions>) => {
  return api.put("/v1/api/options/edit", action.payload, {
    params: { id: action.payload.id },
  });
};

export function* EditOptionsCall(action: PayloadAction<IOptions>) {
  try {
    const response: AxiosResponse = yield call(EditOptionsApi, action);
    yield put(successEditOptions(response.data));
  } catch (error) {
    yield put(failureEditOptions(error));
  }
}

// delete
const DeleteOptionsApi = (action: PayloadAction<number>) => {
  return api.delete("/v1/api/options/delete", {
    params: { id: action.payload },
  });
};

export function* DeleteOptionsCall(action: PayloadAction<number>) {
  try {
    const response: AxiosResponse = yield call(DeleteOptionsApi, action);
    yield put(successdeleteOptions(response.data));
  } catch (error) {
    yield put(failuredeleteOptions(error));
  }
}
