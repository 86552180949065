import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IOptions } from "../../../Pages/Options/IOptions";

export const EditOptionsSlice = createSlice({
  name: "EditOptionsSlice",
  initialState: {} as IReduxState<IOptions>,
  reducers: {
    EditOptions: (state, _: PayloadAction<IOptions>) => {
      state.isLoading = true;
    },
    successEditOptions: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureEditOptions: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearEditOptionsResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  EditOptions,
  failureEditOptions,
  successEditOptions,
  clearEditOptionsResponse,
} = EditOptionsSlice.actions;

export default EditOptionsSlice.reducer;
