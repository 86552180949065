import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IExamHistory } from "../../../Pages/Exam_Histroy/IExam_History";

export const DeleteExamHistorySlice = createSlice({
  name: "DeleteExamHistory",
  initialState: {} as IReduxState<IExamHistory>,
  reducers: {
    deleteExamHistory: (state, _) => {
      state.isLoading = true;
    },
    successdeleteExamHistory: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failuredeleteExamHistory: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearDeleteExamHistoryResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  deleteExamHistory,
  failuredeleteExamHistory,
  successdeleteExamHistory,
  clearDeleteExamHistoryResponse,
} = DeleteExamHistorySlice.actions;

export default DeleteExamHistorySlice.reducer;
