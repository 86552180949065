import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IExamAnswer } from "../../../Pages/Exam participation/IExam_participation";

export const DeleteExamAnswerSlice = createSlice({
  name: "DeleteExamAnswerSlicer",
  initialState: {} as IReduxState<IExamAnswer>,
  reducers: {
    deleteExamAnswer: (state, _) => {
      state.isLoading = true;
    },
    successdeleteExamAnswer: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failuredeleteExamAnswer: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearDeleteExamAnswerResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  deleteExamAnswer,
  failuredeleteExamAnswer,
  successdeleteExamAnswer,
  clearDeleteExamAnswerResponse,
} = DeleteExamAnswerSlice.actions;

export default DeleteExamAnswerSlice.reducer;
