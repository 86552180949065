import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { ICourseslist } from "../../../Pages/Courses/ICourses";

export const ListCoursesSlice = createSlice({
  name: "ListCoursesSlice",
  initialState: {} as IReduxState<ICourseslist[]>,
  reducers: {
    listCourses: (state) => {
      state.isLoading = true;
    },
    successlistCourses: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failurelistCourses: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
  },
});

export const { failurelistCourses, listCourses, successlistCourses } =
  ListCoursesSlice.actions;

export default ListCoursesSlice.reducer;
