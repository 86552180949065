import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IInstruction } from "../../../Pages/Instruction/IInstruction";

export const AddInstructionSlice = createSlice({
  name: "AddInstructionSlice",
  initialState: {} as IReduxState<IInstruction>,
  reducers: {
    addInstruction: (state, _: PayloadAction<IInstruction>) => {
      state.isLoading = true;
    },
    successaddInstruction: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureaddInstruction: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearAddInstructionResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  addInstruction,
  failureaddInstruction,
  successaddInstruction,
  clearAddInstructionResponse,
} = AddInstructionSlice.actions;

export default AddInstructionSlice.reducer;
