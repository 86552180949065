import { FunctionComponent, memo, useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import TextInputFieldC from "../../Components/TextInput/TextInputFieldC";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import { addPackage } from "../../Redux/Slices/Package/AddPackageSlice";
import { EditPackage } from "../../Redux/Slices/Package/EditPackageSlice";
import Select from "../../Components/Select/AutoComplete";
import { AutocompleteOption } from "../../Components/Select/ISelectProps";
import TextAreaInputField from "../../Components/TextAreaInput/TextAreaInputField";
import { IPackagelist } from "./IPackage";
import NumberInputField from "../../Components/NumberInput/NumberInputField";
import MultiSelect from "../../Components/MultiSelect/MultiSelect";

interface PackageFormProps {
  formRef: React.RefObject<HTMLFormElement>;
  initialValues: IPackagelist;
  setInitialValues: React.Dispatch<React.SetStateAction<IPackagelist>>;
}

const PackageForm: FunctionComponent<PackageFormProps> = (props) => {
  const dispatch = useAppDispatch();

  const { data: listExams } = useAppSelector((state) => state.ListCoursesSlice);

  const ExamList = Array.isArray(listExams?.data)
    ? listExams?.data.map((listExams) => ({
        label: listExams.name!,
        value: listExams.id,
      }))
    : [];
  const userSchema = z
    .object({
      id: z.number().optional(),
      name: z
        .string({ message: "Please enter package name" })
        .min(1, { message: "Please enter package name" }),
      course_ids: z
        .array(z.number({ message: "Please select courses" }), {
          message: "Please select courses",
        })
        .min(1, { message: "Please select courses" }),
      description: z
        .string({ message: "Please enter description" })
        .min(1, { message: "Please enter description" }),
      cost: z.number({ message: "Please enter cost" }),
      from_age: z.number({ message: "Enter from age" }),
      to_age: z.number({ message: "Enter to age" }),
      duration: z
        .number({ message: "Please enter duration" })
        .min(1, { message: "Please enter duration" }),
      about: z
        .string({ message: "Please enter package details here" })
        .min(1, { message: "Please enter package details here" }),
      status: z.boolean({
        message: "Please select the status",
      }),
    })
    .refine((data) => data.from_age < data.to_age, {
      message: "From age must be smaller than To age",
      path: ["from_age"],
    })
    .refine((data) => data.from_age < data.to_age, {
      message: "To age must be greater than from age",
      path: ["to_age"],
    });

  const methods = useForm<IPackagelist>({
    defaultValues: {
      ...props.initialValues,
      status: props.initialValues.id ? props.initialValues.status : true,
    },

    resolver: zodResolver(userSchema),
    mode: "onChange",
  });

  const onSubmit: SubmitHandler<IPackagelist> = (val) => {
    if (val.id) {
      dispatch(EditPackage(val));
    } else {
      dispatch(addPackage(val));
    }
  };

  useEffect(() => {
    return () => {
      props.setInitialValues({});
    };
  }, [dispatch, props]);

  // Status list
  const PackageSatus = [
    {
      label: "Active",
      value: true,
    },

    {
      label: "Inactive",
      value: false,
    },
  ];

  return (
    <FormProvider {...methods}>
      <form ref={props.formRef} onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="form_double_input">
          <TextInputFieldC name="name" label="Name" type="text" />
          <MultiSelect
            name="course_ids"
            label="Courses"
            options={ExamList ?? []}
            value={
              ExamList?.filter((list) =>
                methods.getValues("course_ids")?.includes(list?.value!)
              ) ?? []
            }
            onChange={(_, value) => {
              const val = value as AutocompleteOption[];
              methods.setValue(
                "course_ids",
                val.map((val) => val.value) ?? null,
                {
                  shouldValidate: true,
                }
              );
            }}
          />
          <TextAreaInputField
            name="description"
            label="Description"
            type="text"
            minRows={1}
            maxRows={6}
          />

          <NumberInputField
            name="duration"
            label="Duration in days"
            type="number"
            sx={{ width: "100%" }}
          />

          <NumberInputField name="cost" label="Cost" type="number" />
          <TextAreaInputField
            name="about"
            label="About"
            type="text"
            minRows={1}
            maxRows={6}
          />
        </div>
        <div style={{ display: "flex", width: "100%", marginTop: "20px" }}>
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              fontSize: "15px",
              color: "#4785ff",
              width: "100%",
            }}
          >
            Age
            <NumberInputField
              name="from_age"
              label="From"
              type="number"
              sx={{ width: "100%" }}
            />{" "}
          </div>
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              fontSize: "15px",
              color: "#4785ff",
              margin: "0px 5px 0px 5px",
            }}
          >
            To
          </div>
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              fontSize: "15px",
              color: "#4785ff",
              width: "100%",
            }}
          >
            <NumberInputField
              name="to_age"
              label="To"
              type="number"
              sx={{ width: "100%" }}
            />
            Years
          </div>
        </div>
        <Select
          sx={{ width: "50%", margin: "20px 0px 0px 0px" }}
          name="status"
          label="Status"
          options={PackageSatus}
          value={
            PackageSatus?.find(
              (role) => role.value === methods.getValues("status")
            ) ?? null
          }
          onChange={(_, value) => {
            const val = value as AutocompleteOption;
            methods.setValue("status", val?.value, { shouldValidate: true });
          }}
        />
      </form>
    </FormProvider>
  );
};

export default memo(PackageForm);
