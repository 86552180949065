import { FunctionComponent, memo, useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import TextInputFieldC from "../../Components/TextInput/TextInputFieldC";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import { addCourses } from "../../Redux/Slices/Courses/AddCoursesSlice";
import { EditCourses } from "../../Redux/Slices/Courses/EditCoursesSlice";
import Select from "../../Components/Select/AutoComplete";
import { AutocompleteOption } from "../../Components/Select/ISelectProps";
import TextAreaInputField from "../../Components/TextAreaInput/TextAreaInputField";
import { ICourseslist } from "./ICourses";
import NumberInputField from "../../Components/NumberInput/NumberInputField";
import MultiSelect from "../../Components/MultiSelect/MultiSelect";

interface CoursesFormProps {
  formRef: React.RefObject<HTMLFormElement>;
  initialValues: ICourseslist;
  setInitialValues: React.Dispatch<React.SetStateAction<ICourseslist>>;
}

const CoursesForm: FunctionComponent<CoursesFormProps> = (props) => {
  const dispatch = useAppDispatch();

  const { data: listExams } = useAppSelector((state) => state.ListExamSlice);

  const ExamList = Array.isArray(listExams?.data)
    ? listExams?.data.map((listExams) => ({
        label: listExams.name!,
        value: listExams.id,
      }))
    : [];
  const userSchema = z.object({
    id: z.number().optional(),
    name: z
      .string({ message: "Please enter role" })
      .min(1, { message: "Please enter role" }),
    exam_ids: z
      .array(z.number({ message: "Please select exams" }))
      .min(1, { message: "Please select exams" }),
    description: z
      .string({ message: "Please enter description" })
      .min(1, { message: "Please enter description" }),
    cost: z.number({ message: "Please enter cost" }),

    duration: z.number({ message: "Please enter duration" }),
    status: z.boolean({
      message: "Please select the status",
    }),
  });
  const methods = useForm<ICourseslist>({
    defaultValues: {
      ...props.initialValues,
      status: props.initialValues.id ? props.initialValues.status : true,
    },
    resolver: zodResolver(userSchema),
    mode: "onChange",
  });

  const onSubmit: SubmitHandler<ICourseslist> = (val) => {
    if (val.id) {
      dispatch(EditCourses(val));
    } else {
      dispatch(addCourses(val));
    }
  };

  useEffect(() => {
    return () => {
      props.setInitialValues({});
    };
  }, [dispatch, props]);

  // Status list
  const CoursesSatus = [
    {
      label: "Active",
      value: true,
    },

    {
      label: "Inactive",
      value: false,
    },
  ];

  return (
    <FormProvider {...methods}>
      <form
        ref={props.formRef}
        onSubmit={methods.handleSubmit(onSubmit)}
        className="form_double_input"
      >
        <TextInputFieldC name="name" label="Name" type="text" />
        <MultiSelect
          name="exam_ids"
          label="Exams"
          options={ExamList ?? []}
          value={
            ExamList?.filter((list) =>
              methods.getValues("exam_ids")?.includes(list?.value)
            ) ?? []
          }
          onChange={(_, value) => {
            const val = value as AutocompleteOption[];
            methods.setValue("exam_ids", val.map((val) => val.value) ?? null, {
              shouldValidate: true,
            });
          }}
        />

        <NumberInputField
          name="duration"
          label="Duration in days"
          type="number"
          sx={{ width: "100%" }}
        />

        <NumberInputField name="cost" label="Cost" type="number" />
        <TextAreaInputField
          name="description"
          label="Description"
          type="text"
          minRows={1}
          maxRows={6}
        />
        <Select
          name="status"
          label="Status"
          options={CoursesSatus}
          value={
            CoursesSatus?.find(
              (role) => role.value === methods.getValues("status")
            ) ?? null
          }
          onChange={(_, value) => {
            const val = value as AutocompleteOption;
            methods.setValue("status", val?.value, { shouldValidate: true });
          }}
        />
      </form>
    </FormProvider>
  );
};

export default memo(CoursesForm);
