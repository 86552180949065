import React, { useEffect, useState } from "react";
import {
  Card,
  Typography,
  Box,
  LinearProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import "./DashboardUser.scss"; // Import SCSS styles
import StudentImage from "../../Assets/Images/05.svg"; // Adjust the path based on the folder structure
import Paper from "./ImageforDashboard/Paper.svg"; // Adjust the path based on the folder structure
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import {
  clearListExamID,
  listExamID,
} from "../../Redux/Slices/Exam/ListExamIDSlice";
import { decode } from "../../Utils/encodeDecode";
import { ILoginResponse } from "../Login/ILogin";
import { listPackage } from "../../Redux/Slices/Package/ListPackageSlice";
import { listUserPackage } from "../../Redux/Slices/UserPackage/ListUserPackageSlice";
import { ReactComponent as cardbg } from "../../Assets/Images/Card.svg";
import { pendingExamList } from "../../Redux/Slices/UserPackage/PendingExamListSlice";
import { listMockExam } from "../../Redux/Slices/MockExam/ListMockExamSlice";
import { listInstructionExamID } from "../../Redux/Slices/Exam/ListinstructionExamIDSlice";
import { pendingExam } from "../../Redux/Slices/PendingExam/PendingExamSlice";
import { addStudentExam } from "../../Redux/Slices/StudentExam/AddStudentExamSlice";
import IconButtonField from "../../Components/Button/IconButtonField";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { listUserExamList } from "../../Redux/Slices/UserPackage/ListUserExamListSlice";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { listUser } from "../../Redux/Slices/User/ListUserSlice";

const DashboardUser = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [details, setDetails] = useState<ILoginResponse>({});

  const { data: mockExamSlice } = useAppSelector(
    (state) => state.MockExamSlice
  );
  const Mock: any = mockExamSlice?.data;

  const { data: ListUserSliceLoading } = useAppSelector(
    (state) => state.ListUserSlice
  );

  const ListUser: any = Array.isArray(ListUserSliceLoading?.data)
    ? ListUserSliceLoading?.data.map((user, index) => ({
        ...user,
        index: index + 1,
      }))
    : [];

  useEffect(() => {
    dispatch(listMockExam());
    const userDetails = decode(localStorage.getItem("details"));
    setDetails(userDetails);
  }, []);

  const name: any = details?.username;
  const finalname = name?.split("@")[0];
  const { data: listUserExamListSlice } = useAppSelector(
    (state) => state.ListUserExamListSlice
  );
  const Packages = Array.isArray(listUserExamListSlice?.data)
    ? listUserExamListSlice?.data
        .filter((user) => !user.expiry) // Filter out courses where expiry is false
        .map((user, index) => ({
          ...user,
          index: index + 1,
        }))
    : [];

  // my enrolledment
  const { data: pendingExamLists } = useAppSelector(
    (state) => state.PendingExamList
  );
  const Pending = Array.isArray(pendingExamLists?.data)
    ? pendingExamLists?.data.map((user, index) => ({
        ...user,
        index: index + 1,
      }))
    : [];

  useEffect(() => {
    dispatch(listUser());
    dispatch(listUserExamList());
    dispatch(pendingExamList());
    localStorage.removeItem("pending_time");
    localStorage.removeItem("student_exam_id");
    localStorage.removeItem("sectionreexamId");
    localStorage.removeItem("sectionId");

    const userDetails = decode(localStorage.getItem("details"));
    setDetails(userDetails);
  }, []);

  const [value, setValue] = React.useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <div className="DashboardUsermain">
      <div className="customCard">
        <div style={{ display: "flex" }}>
          <img className="imagesleftside" src={StudentImage} alt="Left Image" />
          <div className="textContent">
            <Typography variant="body1" className="textsytles">
              Hi,{" "}
              <span style={{ textTransform: "capitalize", fontSize: "14px" }}>
                {name}
              </span>
            </Typography>
            <Typography variant="h5" className="textsytleheading">
              Welcome to Everest Tutoring’s AI GATE Question Bank!
            </Typography>
            <Typography variant="body2" className="textsytles">
              Select one of the courses below to complete a timed mock test,
              powered by AI! Review your performance and solutions at the end,
              and ask our Smart Tutor for help with any question!
            </Typography>
          </div>
        </div>

        <img className="image" src={Paper} alt="Right Image" />
      </div>

      {/* .....mock */}
      <div className="mock-exam-container">
        <div className="cards-wrapper">
          <div className="multi-card">
            <div className="mock-exam" style={{ textAlign: "start" }}>
              <Typography variant="h5" className="textsytleheading">
                Try Mock Exam
              </Typography>
              <p style={{ fontSize: "15px" }}>
                Begin your mock exam to test your knowledge.
              </p>
              <button
                className="card-button"
                onClick={() => {
                  dispatch(listInstructionExamID({ exam_id: Mock[0]?.id }));
                  dispatch(
                    addStudentExam({
                      exam_id: Mock[0]?.id,
                      student_id: details?.id,
                      closed: false,
                    })
                  );
                  navigate("/instruction", { state: Mock[0]?.id }); // Replace with your target route
                  dispatch(clearListExamID());
                }}
              >
                Start Now
              </button>
            </div>
            <div className="exam-history">
              <Typography variant="h5" className="textsytleheading">
                Exam History
              </Typography>
              <p style={{ fontSize: "15px" }}>To view exam history</p>
              <IconButtonField onClick={() => navigate("/examhistory")}>
                <DoubleArrowIcon />
              </IconButtonField>
            </div>
          </div>
          <div className="card">
            <Typography
              variant="h4"
              className="textsytleheading"
              sx={{ color: "white" }}
            >
              {/* My Enrollments */}
            </Typography>
            <Typography
              variant="h4"
              className="textsytleheading"
              sx={{ color: "white" }}
            >
              My Enrollments
            </Typography>
            <button
              className="card-button"
              onClick={() => {
                navigate("/totalexam"); // Replace with your target route
              }}
            >
              See All
            </button>
          </div>
        </div>
      </div>
      {/* <div className="courseslist">Exams Pending List </div> */}
      {/* <div className="pendingexamsscrollbar">
        {Package?.length === 0 ? (
          <div style={{ marginBottom: "20px" }}>
            <div className="customCardpending">
              <Card className="dashboard_cardspending">
                <Typography className="heading_name" variant="h6">
                  Pending Exam Not Available
                </Typography>
              </Card>
            </div>
          </div>
        ) : (
          Pending?.map((card, index) => (
            <div style={{ marginBottom: "20px" }} key={index}>
              <div className="customCardpending">
                <Card
                  className="dashboard_cardspending"
                  sx={{ bgcolor: "#7777ec" }}
                >
                  <Typography className="heading_name" variant="h6">
                    {card?.exam_name}
                  </Typography>
                </Card>
                <div className="textContent">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h5" className="textsytleheading">
                      {card?.index}. {card?.exam_name}
                    </Typography>
                    <button className="card-buttons">
                      Total Question {card?.total_questions}
                    </button>
                  </div>

                  <Typography variant="body2" className="textsytle">
                    {card?.exam_description}
                  </Typography>
                  <Box sx={{ width: "100%", marginTop: "10px" }}>
                    <Box display="flex" alignItems="center">
                      <Box width="100%" mr={1}>
                        <LinearProgress
                          variant="determinate"
                          value={card?.answered_percentage}
                          sx={{ height: "7px", borderRadius: "10px" }}
                        />
                      </Box>
                      <Box minWidth={35}>
                        <Typography variant="body2" color="text.secondary">
                          {card?.answered_percentage} %
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <button
                    className="card-buttons"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate("/instructionpending", { state: card?.id }); // Replace with your target route
                    }}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          ))
        )}
      </div> */}

      <div className="courseslist">Exams in Progress </div>
      <div className="pendingexamsscrollbar">
        {Pending?.length === 0 ? (
          <div style={{ marginBottom: "20px" }}>
            <div className="customCardpending">
              <Card
                className="dashboard_cardspending"
                sx={{ bgcolor: "#f5f5f5" }}
              >
                <Typography className="heading_name" variant="h6">
                  Pending Exam Not Available
                </Typography>
              </Card>
            </div>
          </div>
        ) : (
          Pending?.map((card, index) => (
            <div style={{ marginBottom: "20px" }} key={index}>
              <div className="customCardpending">
                <Card
                  className="dashboard_cardspending"
                  sx={{ bgcolor: "#f5f5f5" }}
                >
                  <Typography className="heading_name" variant="h6">
                    {card?.exam_name}
                  </Typography>
                </Card>
                <div className="textContent">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h5" className="textsytleheading">
                      {card?.exam_name}
                    </Typography>
                    <button className="card-buttons">
                      Total Question {card?.total_questions}
                    </button>
                  </div>

                  <Typography variant="body2" className="textsytle">
                    {card?.exam_description}
                  </Typography>
                  <Box sx={{ width: "100%", marginTop: "10px" }}>
                    <Box display="flex" alignItems="center">
                      <Box width="100%" mr={1}>
                        <LinearProgress
                          variant="determinate"
                          value={card?.answered_percentage}
                          sx={{ height: "7px", borderRadius: "10px" }}
                        />
                      </Box>
                      <Box minWidth={35}>
                        <Typography variant="body2" color="text.secondary">
                          {card?.answered_percentage} %
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <button
                    className="card-buttons"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate("/instructionpending", { state: card?.id }); // Replace with your target route
                    }}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          ))
        )}
      </div>

      <div className="courseslist">Enrolled Courses</div>
      {Packages?.length === 0 ? (
        <>
          <div style={{ marginBottom: "20px" }}>
            <div className="customCardpending">
              <Card className="dashboard_cardspending">
                <Typography className="heading_name" variant="h6">
                  Courses Not Available
                </Typography>
              </Card>
            </div>
          </div>
        </>
      ) : (
        Packages?.map((pkg, index) => (
          <div key={index}>
            <div
              style={{ marginBottom: "20px", cursor: "pointer" }}
              // onClick={() => {
              //   navigate("/purchasedexam", { state: pkg?.package?.id }); // Replace with your target route
              // }}
            >
              <div className="customCardpendingtotal">
                <Card
                  className="dashboard_cardspending"
                  sx={{ bgcolor: "#f5f5f5" }}
                >
                  <Typography className="heading_name" variant="h6">
                    {pkg?.course_name}
                  </Typography>
                </Card>
                <div className="textContent">
                  <Typography
                    variant="h5"
                    sx={{ color: "#0088ff" }}
                    className="textsytleheading"
                  >
                    {pkg?.course_name}
                  </Typography>

                  <Typography variant="body2" className="textsytle">
                    {pkg?.course_description}
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{ color: "#0088ff" }}
                    className="textsytleheading"
                  >
                    {pkg?.exams?.length === 0 ? "Exams Not Available" : "Exams"}
                  </Typography>
                  {/* Use parentheses to directly return the JSX from the map function */}
                  {pkg?.exams?.map((listexam, examIndex) => (
                    <Accordion key={examIndex} sx={{ width: "100%" }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id={`panel-${index}-header`}
                        sx={{
                          color: "#0088ff",
                        }}
                      >
                        {listexam?.exam_name}
                      </AccordionSummary>
                      <AccordionDetails>
                        <div>{listexam?.exam_description}</div>
                        {listexam?.retake_count === 0 ? (
                          <div className="EnrolledHistory">
                            <button
                              className="card-buttonexamlist"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                dispatch(clearListExamID());
                                dispatch(
                                  listInstructionExamID({
                                    exam_id: listexam?.exam_id,
                                  })
                                );
                                dispatch(
                                  addStudentExam({
                                    exam_id: listexam?.exam_id,
                                    student_id: details?.id,
                                    closed: false,
                                  })
                                );
                                navigate("/instruction", {
                                  state: listexam?.exam_id,
                                }); // Replace with your target route
                              }}
                            >
                              Start Now
                            </button>
                            <div className="Attempcountexam">
                              Attempt Count : {listexam?.retake_count}
                            </div>
                          </div>
                        ) : (
                          <div className="Buttonlist">
                            <button
                              className="card-buttonexamlist"
                              onClick={() => {
                                navigate("/instructionreExam", {
                                  state: listexam?.student_exam_id,
                                }); // Replace with your target route
                              }}
                            >
                              ReTake Exam
                            </button>
                            <button
                              className="card-buttonexam"
                              onClick={() => {
                                navigate("/examresult", {
                                  state: listexam?.student_exam_id,
                                });
                              }}
                            >
                              Report
                            </button>
                            <div className="Attempcount">
                              Attempt Count : {listexam?.retake_count}
                            </div>
                          </div>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default DashboardUser;
