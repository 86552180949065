import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import rootSage from "../Saga/Saga";
import TestReducer from "../Slices/TestSlicers/TestSlicers";
import SessionExpiredReducer from "../Slices/SessionExpired/SessionExpired";
import EditUserPermissionsReducer from "../Slices/UserPermission/UpdateUserPermissionSlicer";
import UserPermissionRowsReducer from "../Slices/UserPermission/UserPermissionRowsSlicer";
import LoginReducer from "../Slices/LoginSlicer/LoginSlicer";
import GoogleLoginReducer from "../Slices/LoginSlicer/GoogleLoginSlicer"
import RegistrationSlicerReducer from "../Slices/Registration/RegistrationSlicer"
import ResetPasswordReducer from "../Slices/ResetPassword/ResetPassword";
import SideMenuPermissionReducer from "../Slices/SideMenuPermission/ListSideMenuPermissionSlice";
import PermissionsToPageReducer from "../Slices/UserPermission/PermissionsToPage";
import ForgotPasswordReducer from "../Slices/LoginSlicer/ForgotPassword";
import DashboardCardReducer from "../Slices/Dashboard/DashboardCard";
import ThemeReducer from "../Slices/Theme/ThemeSlice";
 // Menu
import ListMenuSliceReducer from "../Slices/Menu/ListMenuSlice"
import AddMenuSlice from "../Slices/Menu/AddMenuSlice"
import EditMenuSliceReducer from "../Slices/Menu/EditMenuSlice"
import DeleteMenuReducer from "../Slices/Menu/DeleteMenuSlice"

 // Questions
 import ListQuestionsSliceReducer from "../Slices/Questions/ListQuestionsSlice"
 import AddQuestionsSlice from "../Slices/Questions/AddQuestionsSlice"
 import EditQuestionsSliceReducer from "../Slices/Questions/EditQuestionsSlice"
 import DeleteQuestionsReducer from "../Slices/Questions/DeleteQuestionsSlice"
 
  // ExamHistory
  import ListExamHistorySliceReducer from "../Slices/ExamHistory/ListExamHistorySlice"
  import AddExamHistorySlice from "../Slices/ExamHistory/AddExamHistorySlice"
  import EditExamHistorySliceReducer from "../Slices/ExamHistory/EditExamHistorySlice"
  import DeleteExamHistoryReducer from "../Slices/ExamHistory/DeleteExamHistorySlice"
  
 // Options
 import ListOptionsSliceReducer from "../Slices/Options/ListOptionsSlice"
 import AddOptionsSlice from "../Slices/Options/AddOptionsSlice"
 import EditOptionsSliceReducer from "../Slices/Options/EditOptionsSlice"
 import DeleteOptionsReducer from "../Slices/Options/DeleteOptionsSlice"
 
  // Exam
  import ListReViewExamIDSliceReducer from "../Slices/ReViewExam/ListReViewExamSlice"
  import ListReExamIDSliceReducer from "../Slices/ReExam/ListReExamSlice"
  import PendingExamSliceReducer from "../Slices/PendingExam/PendingExamSlice"
  import ListExamsubmitSliceReducer from "../Slices/ExamAnswer/ListExamsubmitSlice"
  import ListExamIDSliceReducer from "../Slices/Exam/ListExamIDSlice"
  import ListEvaluationSliceReducer from "../Slices/Exam/ListEvaluationSlice"
  import ListExamSliceReducer from "../Slices/Exam/ListExamSlice"
  import AddExamSlice from "../Slices/Exam/AddExamSlice"
  import AddCloseSectionSliceReducer from "../Slices/Exam/AddCloseSectionSlice"
  import EditExamSliceReducer from "../Slices/Exam/EditExamSlice"
  import DeleteExamReducer from "../Slices/Exam/DeleteExamSlice"
  
  // Student Exam 
  import PopupStudentExamSliceReducer from "../Slices/StudentExam/PopupStudentExamSlice"
  import EditStudentExamSliceReducer from "../Slices/StudentExam/EditStudentExamSlice"
  import ListStudentExamSliceReducer from "../Slices/StudentExam/ListStudentExamSlice"
  import AddStudentExamSlice from "../Slices/StudentExam/AddStudentExamSlice"
   // Instruction
   import ListInstructionSliceReducer from "../Slices/Instruction/ListInstructionSlice"
   import AddInstructionSlice from "../Slices/Instruction/AddInstructionSlice"
   import EditInstructionSliceReducer from "../Slices/Instruction/EditInstructionSlice"
   import DeleteInstructionReducer from "../Slices/Instruction/DeleteInstructionSlice"
   
      // ExamAnswer
      import ListExamAnswerSliceReducer from "../Slices/ExamAnswer/ListExamAnswerSlice"
      import AddExamAnswerSlice from "../Slices/ExamAnswer/AddExamAnswerSlice"
      import EditExamAnswerSliceReducer from "../Slices/ExamAnswer/EditExamAnswerSlice"
      import DeleteExamAnswerReducer from "../Slices/ExamAnswer/DeleteExamAnswerSlice"
   
       // QuestionType
 import ListQuestionTypeSliceReducer from "../Slices/QuestionType/ListQuestionTypeSlice"
 import AddQuestionTypeSlice from "../Slices/QuestionType/AddQuestionTypeSlice"
 import EditQuestionTypeSliceReducer from "../Slices/QuestionType/EditQuestionTypeSlice"
 import DeleteQuestionTypeReducer from "../Slices/QuestionType/DeleteQuestionTypeSlice"
  // ExamModel
  import ListExamModelSliceReducer from "../Slices/ExamModel/ListExamModelSlice"
  import AddExamModelSlice from "../Slices/ExamModel/AddExamModelSlice"
  import EditExamModelSliceReducer from "../Slices/ExamModel/EditExamModelSlice"
  import DeleteExamModelReducer from "../Slices/ExamModel/DeleteExamModelSlice"
  
       // FeedBack
       import ListFeedBackSliceReducer from "../Slices/FeedBack/ListFeedBackSlice"
       import AddFeedBackSlice from "../Slices/FeedBack/AddFeedBackSlice"
       import EditFeedBackSliceReducer from "../Slices/FeedBack/EditFeedBackSlice"
       import DeleteFeedBackReducer from "../Slices/FeedBack/DeleteFeedBackSlice"
    
  // UserProfile
  import ListUserProfileSliceReducer from "../Slices/UserProfile/ListUserProfileSlice"
  import AddUserProfileSlice from "../Slices/UserProfile/AddUserProfileSlice"
  import EditUserProfileSliceReducer from "../Slices/UserProfile/EditUserProfileSlice"
  import DeleteUserProfileReducer from "../Slices/UserProfile/DeleteUserProfileSlice"

   // User Admin
   import ListUserAdminSliceReducer from "../Slices/UserAdmin/ListUserAdminSlice"
   import AddUserAdminSliceReducer from "../Slices/UserAdmin/AddUserAdminSlice"
   import EditUserAdminSliceReducer from "../Slices/UserAdmin/EditUserAdminSlice"
   import DeleteUserAdminReducer from "../Slices/UserAdmin/DeleteUserAdminSlice"
  

  // User
  import ListUserSliceReducer from "../Slices/User/ListUserSlice"
  import AddUserSlice from "../Slices/User/AddUserSlice"
  import EditUserSliceReducer from "../Slices/User/EditUserSlice"
  import DeleteUserReducer from "../Slices/User/DeleteUserSlice"
  import ListRegionSliceReducer from "../Slices/User/ListRegionSlice"
  import ListYearGroupSliceReducer from "../Slices/User/ListYearGroupSlice"
    // Package
    import ListPackageSliceReducer from "../Slices/Package/ListPackageSlice"
    import AddPackageSlice from "../Slices/Package/AddPackageSlice"
    import EditPackageSliceReducer from "../Slices/Package/EditPackageSlice"
    import DeletePackageReducer from "../Slices/Package/DeletePackageSlice"
  
       // Courses
       import ListCoursesSliceReducer from "../Slices/Courses/ListCoursesSlice"
       import AddCoursesSlice from "../Slices/Courses/AddCoursesSlice"
       import EditCoursesSliceReducer from "../Slices/Courses/EditCoursesSlice"
       import DeleteCoursesReducer from "../Slices/Courses/DeleteCoursesSlice"
     
       // userPackage
   import PendingExamListReducer from "../Slices/UserPackage/PendingExamListSlice"
   import ListUserPackageSliceReducer from "../Slices/UserPackage/ListUserPackageSlice"
   import ListUserPackageDetailsSliceReducer from "../Slices/UserPackage/ListUserPackageDetailsSlice"
   import ListUserExamListSliceReducer from "../Slices/UserPackage/ListUserExamListSlice"
     // Role
     import ListRoleSliceReducer from "../Slices/Role/ListRoleSlice"
     import AddRoleSlice from "../Slices/Role/AddRoleSlice"
     import EditRoleSliceReducer from "../Slices/Role/EditRoleSlice"
     import DeleteRoleReducer from "../Slices/Role/DeleteRoleSlice"
  //  mock exam 
  import MockExamSliceReducer from "../Slices/MockExam/ListMockExamSlice"
import ListInstructionExamSliceReducer from "../Slices/Exam/ListinstructionExamIDSlice"
  
//  chatbot
import ChatbotSlicerReducer from "../Slices/Chatbot/ChatbotSlicer"
     
  
const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    Test: TestReducer,
    Theme: ThemeReducer,
    // Dashboard
    DashboardCard: DashboardCardReducer,
    // Permissions
    PermissionsToPage: PermissionsToPageReducer,
    // SessionExpired
    SessionExpired: SessionExpiredReducer,
    // User Permission
    EditUserPermission: EditUserPermissionsReducer,
    UserPermissionRows: UserPermissionRowsReducer,
    // Login
    LoginSlicer: LoginReducer,
     // google
     GoogleLoginSlicer: GoogleLoginReducer,
   // Registration
   RegistrationSlicer:RegistrationSlicerReducer,
    // Reset Password
    ResetPassword: ResetPasswordReducer,
    ForgotPassword: ForgotPasswordReducer,

    // Menu
    ListMenuSlice: ListMenuSliceReducer,
    AddMenuSlice: AddMenuSlice,
    EditMenuSlice:EditMenuSliceReducer,
    DeleteMenu: DeleteMenuReducer,
       // QuestionType
ListQuestionTypeSlice: ListQuestionTypeSliceReducer,
AddQuestionTypeSlice: AddQuestionTypeSlice,
EditQuestionTypeSlice:EditQuestionTypeSliceReducer,
DeleteQuestionType: DeleteQuestionTypeReducer,
  // Exam
  ListExamsubmitSlice: ListExamsubmitSliceReducer,
  ListReViewExamIDSlice:ListReViewExamIDSliceReducer,
  ListReExamIDSlice:ListReExamIDSliceReducer,
  PendingExamSlice:PendingExamSliceReducer,
  ListExamIDSlice: ListExamIDSliceReducer,
  ListInstructionExamSlice: ListInstructionExamSliceReducer,
  ListEvaluationSlice: ListEvaluationSliceReducer,
  ListExamSlice: ListExamSliceReducer,
  AddExamSlice: AddExamSlice,
  AddCloseSectionSlice: AddCloseSectionSliceReducer,
  EditExamSlice:EditExamSliceReducer,
  DeleteExam: DeleteExamReducer,
  // chatbot
  ChatbotSlicer:ChatbotSlicerReducer,
  // student exam 
  ListStudentExamSlice: ListStudentExamSliceReducer,
  AddStudentExamSlice: AddStudentExamSlice,
  EditStudentExamSlice:EditStudentExamSliceReducer,
  PopupStudentExamSlice:PopupStudentExamSliceReducer,
   // ExamModel
   ListExamModelSlice: ListExamModelSliceReducer,
   AddExamModelSlice: AddExamModelSlice,
   EditExamModelSlice:EditExamModelSliceReducer,
   DeleteExamModel: DeleteExamModelReducer,

       // ExamHistory
       ListExamHistorySlice: ListExamHistorySliceReducer,
       AddExamHistorySlice: AddExamHistorySlice,
       EditExamHistorySlice:EditExamHistorySliceReducer,
       DeleteExamHistory: DeleteExamHistoryReducer,
              // Questions
ListQuestionsSlice: ListQuestionsSliceReducer,
AddQuestionsSlice: AddQuestionsSlice,
EditQuestionsSlice:EditQuestionsSliceReducer,
DeleteQuestions: DeleteQuestionsReducer,


    // Options
ListOptionsSlice: ListOptionsSliceReducer,
AddOptionsSlice: AddOptionsSlice,
EditOptionsSlice:EditOptionsSliceReducer,
DeleteOptions: DeleteOptionsReducer,
    // Instruction
    ListInstructionSlice: ListInstructionSliceReducer,
    AddInstructionSlice: AddInstructionSlice,
    EditInstructionSlice:EditInstructionSliceReducer,
    DeleteInstruction: DeleteInstructionReducer,
     // ExamAnswer
   ListExamAnswerSlice: ListExamAnswerSliceReducer,
   AddExamAnswerSlice: AddExamAnswerSlice,
   EditExamAnswerSlice:EditExamAnswerSliceReducer,
   DeleteExamAnswer: DeleteExamAnswerReducer,
   
             // FeedBack
             ListFeedBackSlice: ListFeedBackSliceReducer,
             AddFeedBackSlice: AddFeedBackSlice,
             EditFeedBackSlice:EditFeedBackSliceReducer,
             DeleteFeedBack: DeleteFeedBackReducer,
             

      // Package
      ListPackageSlice: ListPackageSliceReducer,
      AddPackageSlice: AddPackageSlice,
      EditPackageSlice:EditPackageSliceReducer,
      DeletePackage: DeletePackageReducer,
      
      // Courses
      ListCoursesSlice: ListCoursesSliceReducer,
      AddCoursesSlice: AddCoursesSlice,
      EditCoursesSlice:EditCoursesSliceReducer,
      DeleteCourses: DeleteCoursesReducer,
      
          // UserAdmin
          ListUserAdminSlice: ListUserAdminSliceReducer,
          AddUserAdminSlice: AddUserAdminSliceReducer,
          EditUserAdminSlice:EditUserAdminSliceReducer,
          DeleteUserAdmin: DeleteUserAdminReducer,
       // User
       ListUserSlice: ListUserSliceReducer,
       AddUserSlice: AddUserSlice,
       EditUserSlice:EditUserSliceReducer,
       DeleteUser: DeleteUserReducer,
      //  list user
      ListRegionSlice:ListRegionSliceReducer,
      ListYearGroupSlice:ListYearGroupSliceReducer,

      //user package 
      ListUserPackageSlice: ListUserPackageSliceReducer,
      ListUserPackageDetailsSlice: ListUserPackageDetailsSliceReducer,
      ListUserExamListSlice: ListUserExamListSliceReducer,
      PendingExamList: PendingExamListReducer,
        // mock exam 
        MockExamSlice: MockExamSliceReducer ,


          // Role
          ListRoleSlice: ListRoleSliceReducer,
          AddRoleSlice: AddRoleSlice,
          EditRoleSlice:EditRoleSliceReducer,
          DeleteRole: DeleteRoleReducer,
          
     // UserProfile
     ListUserProfileSlice: ListUserProfileSliceReducer,
     AddUserProfileSlice: AddUserProfileSlice,
     EditUserProfileSlice:EditUserProfileSliceReducer,
     DeleteUserProfile: DeleteUserProfileReducer,
     
    // SideMenu Permission
    SideMenuPermission: SideMenuPermissionReducer,

     },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware),
});

sagaMiddleware.run(rootSage);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
