import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IReViewExamlistID } from "../../../Pages/Exam/IExam";

export const ListReViewExamSlice = createSlice({
  name: "ListReViewExamIDSlice",
  initialState: {} as IReduxState<IReViewExamlistID[]>,
  reducers: {
    listReViewExamID: (state, _: PayloadAction<IReViewExamlistID>) => {
      state.isLoading = true;
    },
    successlistReViewExamID: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failurelistReViewExamID: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearListReViewExamID: (state) => {
      state.data = {};
    },
  },
});

export const {
  failurelistReViewExamID,
  listReViewExamID,
  successlistReViewExamID,
  clearListReViewExamID,
} = ListReViewExamSlice.actions;

export default ListReViewExamSlice.reducer;
