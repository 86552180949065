import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IExamHistory } from "../../../Pages/Exam_Histroy/IExam_History";

export const AddExamHistorySlice = createSlice({
  name: "AddExamHistorySlice",
  initialState: {} as IReduxState<IExamHistory>,
  reducers: {
    addExamHistory: (state, _: PayloadAction<IExamHistory>) => {
      state.isLoading = true;
    },
    successaddExamHistory: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureaddExamHistory: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearAddExamHistoryResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  addExamHistory,
  failureaddExamHistory,
  successaddExamHistory,
  clearAddExamHistoryResponse,
} = AddExamHistorySlice.actions;

export default AddExamHistorySlice.reducer;
