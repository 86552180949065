import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IQuestions } from "../../../Pages/Questions/IQuestions";

export const DeleteQuestionsSlice = createSlice({
  name: "DeleteQuestions",
  initialState: {} as IReduxState<IQuestions>,
  reducers: {
    deleteQuestions: (state, _) => {
      state.isLoading = true;
    },
    successdeleteQuestions: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failuredeleteQuestions: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearDeleteQuestionsResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  deleteQuestions,
  failuredeleteQuestions,
  successdeleteQuestions,
  clearDeleteQuestionsResponse,
} = DeleteQuestionsSlice.actions;

export default DeleteQuestionsSlice.reducer;
