import "../../Select/Select.scss";

import { Autocomplete, TextField } from "@mui/material";
import { FunctionComponent } from "react";
import { SelectProps } from "../../Select/ISelectProps";

const Select: FunctionComponent<SelectProps> = (props) => {
  const {
    sx,
    value,
    defaultValue,
    options,
    id,
    loading,
    readOnly,
    disabled,
    fullWidth,
    disableCloseOnSelect,
    disableClearable,
    filterSelectedOptions,
    autoHighlight,
    loadingText,
    renderOption,
    label,
    noOptionsText,
    AutoCompleteClassName,
    freeSolo,
    onChange,
    getOptionDisabled,
    onOpen,
    onClose,
    getOptionLabel,
    onFocus,
    onBlur,
    inputClassName,
    onInputChange,
    autoFocus,

    /*    input    */
    name,
    placeholder,
    variant,
    color,
    required,
    className,
    inputSx,
    error,
    helperText,
    shrink,
    inputLableStyle,
    size,
    labelSize,
    filterOptions,
  } = props;

  return (
    <Autocomplete
      disablePortal
      id={id}
      options={options ?? []}
      sx={sx}
      autoFocus={autoFocus}
      value={value}
      defaultValue={defaultValue}
      filterSelectedOptions={filterSelectedOptions}
      filterOptions={filterOptions}
      loading={loading}
      className={`GlobalAutoCompleteStyle ${AutoCompleteClassName}`}
      readOnly={readOnly}
      disabled={disabled}
      disableCloseOnSelect={disableCloseOnSelect}
      disableClearable={disableClearable}
      autoHighlight={autoHighlight}
      loadingText={loadingText}
      noOptionsText={noOptionsText}
      fullWidth={fullWidth}
      freeSolo={freeSolo}
      size={size}
      getOptionDisabled={getOptionDisabled}
      getOptionLabel={getOptionLabel}
      onChange={onChange}
      onOpen={onOpen}
      onClose={onClose}
      onFocus={onFocus}
      onInputChange={onInputChange}
      onBlur={onBlur}
      renderOption={renderOption}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          name={name}
          placeholder={placeholder}
          variant={variant}
          color={color}
          required={required}
          className={`GlobalInputStyle ${className}`}
          sx={inputSx}
          error={error}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            className: `GlobalInputPropsStyle ${inputClassName}`,
            disableUnderline: true,
          }}
          InputLabelProps={{
            shrink: shrink,
            size: labelSize,
            className: `GlobalInputLableStyle ${inputLableStyle}`,
          }}
        />
      )}
    />
  );
};

export default Select;
