import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IExamHistory } from "../../../Pages/Exam_Histroy/IExam_History";

export const EditExamHistorySlice = createSlice({
  name: "EditExamHistorySlice",
  initialState: {} as IReduxState<IExamHistory>,
  reducers: {
    EditExamHistory: (state, _: PayloadAction<IExamHistory>) => {
      state.isLoading = true;
    },
    successEditExamHistory: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureEditExamHistory: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearEditExamHistoryResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  EditExamHistory,
  failureEditExamHistory,
  successEditExamHistory,
  clearEditExamHistoryResponse,
} = EditExamHistorySlice.actions;

export default EditExamHistorySlice.reducer;
