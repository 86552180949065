import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMenuItem } from "../../../Components/Sidnavbar/ISidnavbar";

export const PermissionsToPageSlice = createSlice({
  name: "PermissionsToPage",
  initialState: {} as IMenuItem["permissions"],
  reducers: {
    permissionsToPage: (
      state,
      action: PayloadAction<IMenuItem["permissions"]>
    ) => {
      state = action.payload;
    },
  },
});

export const { permissionsToPage } = PermissionsToPageSlice.actions;

export default PermissionsToPageSlice.reducer;
