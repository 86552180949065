import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IPackagelist } from "../../../Pages/Package/IPackage";

export const ListPackageSlice = createSlice({
  name: "ListPackageSlice",
  initialState: {} as IReduxState<IPackagelist[]>,
  reducers: {
    listPackage: (state) => {
      state.isLoading = true;
    },
    successlistPackage: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failurelistPackage: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
  },
});

export const { failurelistPackage, listPackage, successlistPackage } =
  ListPackageSlice.actions;

export default ListPackageSlice.reducer;
