import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IExamlistID } from "../../../Pages/Exam/IExam";
import { IStartExam } from "../../../Pages/Instruction/IInstruction";

export const ListExamSlice = createSlice({
  name: "ListExamIDSlice",
  initialState: {} as IReduxState<IStartExam>,
  reducers: {
    listExamID: (state, _: PayloadAction<IExamlistID>) => {
      state.isLoading = true;
    },
    successlistExamID: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failurelistExamID: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearListExamID: (state) => {
      state.data = {};
    },
  },
});

export const {
  failurelistExamID,
  listExamID,
  successlistExamID,
  clearListExamID,
} = ListExamSlice.actions;

export default ListExamSlice.reducer;
