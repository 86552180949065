import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import api from "../../Axios/middleware";
import { failureaddExamModel, successaddExamModel } from "./AddExamModelSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { IExamModel } from "../../../Pages/ExamModel/IExamModel";

import {
  failuredeleteExamModel,
  successdeleteExamModel,
} from "./DeleteExamModelSlice";
import {
  failurelistExamModel,
  successlistExamModel,
} from "./ListExamModelSlice";
import {
  failureEditExamModel,
  successEditExamModel,
} from "./EditExamModelSlice";

// list
const ListExamModelApi = () => {
  return api.get("/v1/api/exam_model/");
};

export function* ListExamModelCall() {
  try {
    const response: AxiosResponse = yield call(ListExamModelApi);
    yield put(successlistExamModel(response.data));
  } catch (error) {
    yield put(failurelistExamModel(error));
  }
}

// add
const AddExamModelApi = (action: PayloadAction<IExamModel>) => {
  return api.post("/v1/api/exam_model/create_exam_model", action.payload);
};

export function* AddExamModelCall(action: PayloadAction<IExamModel>) {
  try {
    const response: AxiosResponse = yield call(AddExamModelApi, action);
    yield put(successaddExamModel(response.data));
  } catch (error) {
    yield put(failureaddExamModel(error));
  }
}

// edit
const EditExamModelApi = (action: PayloadAction<IExamModel>) => {
  return api.put("/v1/api/exam_model/edit", action.payload, {
    params: { id: action.payload.id },
  });
};

export function* EditExamModelCall(action: PayloadAction<IExamModel>) {
  try {
    const response: AxiosResponse = yield call(EditExamModelApi, action);
    yield put(successEditExamModel(response.data));
  } catch (error) {
    yield put(failureEditExamModel(error));
  }
}

// delete
const DeleteExamModelApi = (action: PayloadAction<number>) => {
  return api.delete("/v1/api/exam_model/delete", {
    params: { id: action.payload },
  });
};

export function* DeleteExamModelCall(action: PayloadAction<number>) {
  try {
    const response: AxiosResponse = yield call(DeleteExamModelApi, action);
    yield put(successdeleteExamModel(response.data));
  } catch (error) {
    yield put(failuredeleteExamModel(error));
  }
}
