import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IUserPackage } from "../../../Pages/Package/IPackage";

export const ListUserPackageSlice = createSlice({
  name: "ListUserPackageSlice",
  initialState: {} as IReduxState<IUserPackage[]>,
  reducers: {
    listUserPackage: (state) => {
      state.isLoading = true;
    },
    successlistUserPackage: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failurelistUserPackage: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  failurelistUserPackage,
  listUserPackage,
  successlistUserPackage,
} = ListUserPackageSlice.actions;

export default ListUserPackageSlice.reducer;
