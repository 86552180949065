import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IUserPackageDetails } from "../../../Pages/Package/IPackage";

export const ListUserPackageDetailsSlice = createSlice({
  name: "ListUserPackageDetailsSlice",
  initialState: {} as IReduxState<IUserPackageDetails[]>,
  reducers: {
    listUserPackageDetails: (state, _: PayloadAction<IUserPackageDetails>) => {
      state.isLoading = true;
    },
    successlistUserPackageDetails: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failurelistUserPackageDetails: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  failurelistUserPackageDetails,
  listUserPackageDetails,
  successlistUserPackageDetails,
} = ListUserPackageDetailsSlice.actions;

export default ListUserPackageDetailsSlice.reducer;
