import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IExamAnswer } from "../../../Pages/Exam participation/IExam_participation";

export const EditExamAnswerSlice = createSlice({
  name: "EditExamAnswerSlice",
  initialState: {} as IReduxState<IExamAnswer>,
  reducers: {
    EditExamAnswer: (state, _: PayloadAction<IExamAnswer>) => {
      state.isLoading = true;
    },
    successEditExamAnswer: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureEditExamAnswer: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearEditExamAnswerResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  EditExamAnswer,
  failureEditExamAnswer,
  successEditExamAnswer,
  clearEditExamAnswerResponse,
} = EditExamAnswerSlice.actions;

export default EditExamAnswerSlice.reducer;
