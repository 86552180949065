import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IRole } from "../../../Pages/Role/IRole";

export const DeleteRoleSlice = createSlice({
  name: "DeleteRole",
  initialState: {} as IReduxState<IRole>,
  reducers: {
    deleteRole: (state, _) => {
      state.isLoading = true;
    },
    successdeleteRole: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failuredeleteRole: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearDeleteRoleResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  deleteRole,
  failuredeleteRole,
  successdeleteRole,
  clearDeleteRoleResponse,
} = DeleteRoleSlice.actions;

export default DeleteRoleSlice.reducer;
