import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export const SessionExpiredSlice = createSlice({
  name: "SessionExpired",
  initialState: { data: false },
  reducers: {
    sessionExpired: (state, action: PayloadAction<boolean>) => {
      state.data = action.payload;
    },
  },
});

export const { sessionExpired } = SessionExpiredSlice.actions;

export default SessionExpiredSlice.reducer;
