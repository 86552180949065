import { FunctionComponent, memo, useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { IRole } from "./IRole";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import TextInputFieldC from "../../Components/TextInput/TextInputFieldC";
import { useAppDispatch } from "../../Redux/Store/Hooks";
import { addRole } from "../../Redux/Slices/Role/AddRoleSlice";
import { EditRole } from "../../Redux/Slices/Role/EditRoleSlice";
import Select from "../../Components/Select/AutoComplete";
import { AutocompleteOption } from "../../Components/Select/ISelectProps";

interface RoleFormProps {
  formRef: React.RefObject<HTMLFormElement>;
  initialValues: IRole;
  setInitialValues: React.Dispatch<React.SetStateAction<IRole>>;
}

const RoleForm: FunctionComponent<RoleFormProps> = (props) => {
  const dispatch = useAppDispatch();

  const userSchema = z.object({
    id: z.number().optional(),
    name: z
      .string({ message: "Please enter role" })
      .min(1, { message: "Please enter role" }),
    status: z.boolean({
      message: "Please select the status",
    }),
  });
  const methods = useForm<IRole>({
    defaultValues: {
      ...props.initialValues,
      status: props.initialValues.id ? props.initialValues.status : true,
    },
    resolver: zodResolver(userSchema),
    mode: "onChange",
  });

  const onSubmit: SubmitHandler<IRole> = (val) => {
    if (val.id) {
      dispatch(EditRole(val));
    } else {
      dispatch(addRole(val));
    }
  };

  useEffect(() => {
    return () => {
      props.setInitialValues({});
    };
  }, [dispatch, props]);

  // Status list
  const RoleSatus = [
    {
      label: "Active",
      value: true,
    },

    {
      label: "Inactive",
      value: false,
    },
  ];

  return (
    <FormProvider {...methods}>
      <form
        ref={props.formRef}
        onSubmit={methods.handleSubmit(onSubmit)}
        className="form_content"
      >
        <TextInputFieldC name="name" label="Name" type="text" />

        <Select
          name="status"
          label="Status"
          options={RoleSatus}
          value={
            RoleSatus?.find(
              (role) => role.value === methods.getValues("status")
            ) ?? null
          }
          onChange={(_, value) => {
            const val = value as AutocompleteOption;
            methods.setValue("status", val?.value, { shouldValidate: true });
          }}
        />
      </form>
    </FormProvider>
  );
};

export default memo(RoleForm);
