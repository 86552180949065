import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import api from "../../Axios/middleware";
import { failureaddUserAdmin, successaddUserAdmin } from "./AddUserAdminSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  failuredeleteUserAdmin,
  successdeleteUserAdmin,
} from "./DeleteUserAdminSlice";
import {
  failurelistUserAdmin,
  successlistUserAdmin,
} from "./ListUserAdminSlice";
import {
  failureEditUserAdmin,
  successEditUserAdmin,
} from "./EditUserAdminSlice";
import { IUserAdmin } from "../../../Pages/UserAdmin/IUserAdmin";

// list
const ListUserAdminApi = (action: PayloadAction<IUserAdmin>) => {
  return api.get("/v1/api/user/users", {
    params: { role: action.payload.rolename },
  });
};

export function* ListUserAdminCall(action: PayloadAction<IUserAdmin>) {
  try {
    const response: AxiosResponse = yield call(ListUserAdminApi, action);
    yield put(successlistUserAdmin(response.data));
  } catch (error) {
    yield put(failurelistUserAdmin(error));
  }
}

// add
const AddUserAdminApi = (action: PayloadAction<IUserAdmin>) => {
  return api.post("/v1/api/user/create", action.payload);
};

export function* AddUserAdminCall(action: PayloadAction<IUserAdmin>) {
  try {
    const response: AxiosResponse = yield call(AddUserAdminApi, action);
    yield put(successaddUserAdmin(response.data));
  } catch (error) {
    yield put(failureaddUserAdmin(error));
  }
}

// edit
const EditUserAdminApi = (action: PayloadAction<IUserAdmin>) => {
  return api.put("/v1/api/user/update", action.payload, {
    params: { id: action.payload.id },
  });
};

export function* EditUserAdminCall(action: PayloadAction<IUserAdmin>) {
  try {
    const response: AxiosResponse = yield call(EditUserAdminApi, action);
    yield put(successEditUserAdmin(response.data));
  } catch (error) {
    yield put(failureEditUserAdmin(error));
  }
}

// delete
const DeleteUserAdminApi = (action: PayloadAction<number>) => {
  return api.delete("/v1/api/user/delete", {
    params: { id: action.payload },
  });
};

export function* DeleteUserAdminCall(action: PayloadAction<number>) {
  try {
    const response: AxiosResponse = yield call(DeleteUserAdminApi, action);
    yield put(successdeleteUserAdmin(response.data));
  } catch (error) {
    yield put(failuredeleteUserAdmin(error));
  }
}
