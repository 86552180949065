import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IUserAdmin } from "../../../Pages/UserAdmin/IUserAdmin";

export const EditUserAdminSlice = createSlice({
  name: "EditUserAdminSlice",
  initialState: {} as IReduxState<IUserAdmin>,
  reducers: {
    EditUserAdmin: (state, _: PayloadAction<IUserAdmin>) => {
      state.isLoading = true;
    },
    successEditUserAdmin: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureEditUserAdmin: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearEditUserAdminResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  EditUserAdmin,
  failureEditUserAdmin,
  successEditUserAdmin,
  clearEditUserAdminResponse,
} = EditUserAdminSlice.actions;

export default EditUserAdminSlice.reducer;
