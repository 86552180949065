import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IQuestions } from "../../../Pages/Questions/IQuestions";

export const AddQuestionsSlice = createSlice({
  name: "AddQuestionsSlice",
  initialState: {} as IReduxState<IQuestions>,
  reducers: {
    addQuestions: (state, _: PayloadAction<IQuestions>) => {
      state.isLoading = true;
    },
    successaddQuestions: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureaddQuestions: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearAddQuestionsResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  addQuestions,
  failureaddQuestions,
  successaddQuestions,
  clearAddQuestionsResponse,
} = AddQuestionsSlice.actions;

export default AddQuestionsSlice.reducer;
