import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IPackagelist } from "../../../Pages/Package/IPackage";

export const DeletePackageSlice = createSlice({
  name: "DeletePackage",
  initialState: {} as IReduxState<IPackagelist>,
  reducers: {
    deletePackage: (state, _) => {
      state.isLoading = true;
    },
    successdeletePackage: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failuredeletePackage: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearDeletePackageResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  deletePackage,
  failuredeletePackage,
  successdeletePackage,
  clearDeletePackageResponse,
} = DeletePackageSlice.actions;

export default DeletePackageSlice.reducer;
