import "./ButtonField.scss";

import { FunctionComponent } from "react";
import { ButtonFieldProps } from "./IButtonField";
import IconButton from "@mui/material/IconButton";
import { Tooltip } from "@mui/material";

const IconButtonField: FunctionComponent<ButtonFieldProps> = (props) => {
  const {
    children,
    sx,
    disabled,
    onClick,
    color,
    size,
    disableRipple,
    className,
    toolTip,
    ref,
  } = props;

  return (
    <Tooltip title={toolTip}>
      <IconButton
        ref={ref}
        className={`iconButton ${className}`}
        sx={sx}
        disabled={disabled}
        onClick={onClick}
        color={color}
        size={size}
        disableRipple={disableRipple}
      >
        {children}
      </IconButton>
    </Tooltip>
  );
};

export default IconButtonField;
