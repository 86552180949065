import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { ICourseslist } from "../../../Pages/Courses/ICourses";

export const EditCoursesSlice = createSlice({
  name: "EditCoursesSlice",
  initialState: {} as IReduxState<ICourseslist>,
  reducers: {
    EditCourses: (state, _: PayloadAction<ICourseslist>) => {
      state.isLoading = true;
    },
    successEditCourses: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureEditCourses: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearEditCoursesResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  EditCourses,
  failureEditCourses,
  successEditCourses,
  clearEditCoursesResponse,
} = EditCoursesSlice.actions;

export default EditCoursesSlice.reducer;
