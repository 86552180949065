import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import api from "../../Axios/middleware";
import { failureaddUser, successaddUser } from "./AddUserSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { failuredeleteUser, successdeleteUser } from "./DeleteUserSlice";
import { failurelistUser, successlistUser } from "./ListUserSlice";
import { failureEditUser, successEditUser } from "./EditUserSlice";
import { IUser } from "../../../Pages/User/IUser";
import {
  failurelistYearGroup,
  successlistYearGroup,
} from "./ListYearGroupSlice";
import { failurelistRegion, successlistRegion } from "./ListRegionSlice";

// list Region
const ListRegionApi = () => {
  return api.get("/v1/api/category/regions");
};

export function* ListRegionCall() {
  try {
    const response: AxiosResponse = yield call(ListRegionApi);
    yield put(successlistRegion(response.data));
  } catch (error) {
    yield put(failurelistRegion(error));
  }
}

// list Year Group
const ListYearGroupApi = () => {
  return api.get("/v1/api/category/year-group");
};

export function* ListYearGroupCall() {
  try {
    const response: AxiosResponse = yield call(ListYearGroupApi);
    yield put(successlistYearGroup(response.data));
  } catch (error) {
    yield put(failurelistYearGroup(error));
  }
}

// list
const ListUserApi = () => {
  return api.get("/v1/api/userProfile/");
};

export function* ListUserCall() {
  try {
    const response: AxiosResponse = yield call(ListUserApi);
    yield put(successlistUser(response.data));
  } catch (error) {
    yield put(failurelistUser(error));
  }
}

// add
const AddUserApi = (action: PayloadAction<IUser>) => {
  return api.post("/v1/api/userProfile/create", action.payload);
};

export function* AddUserCall(action: PayloadAction<IUser>) {
  try {
    const response: AxiosResponse = yield call(AddUserApi, action);
    yield put(successaddUser(response.data));
  } catch (error) {
    yield put(failureaddUser(error));
  }
}

// edit
const EditUserApi = (action: PayloadAction<IUser>) => {
  return api.put("/v1/api/userProfile/update", action.payload, {
    params: { id: action.payload.id },
  });
};

export function* EditUserCall(action: PayloadAction<IUser>) {
  try {
    const response: AxiosResponse = yield call(EditUserApi, action);
    yield put(successEditUser(response.data));
  } catch (error) {
    yield put(failureEditUser(error));
  }
}

// delete
const DeleteUserApi = (action: PayloadAction<number>) => {
  return api.delete("/v1/api/userProfile/delete", {
    params: { id: action.payload },
  });
};

export function* DeleteUserCall(action: PayloadAction<number>) {
  try {
    const response: AxiosResponse = yield call(DeleteUserApi, action);
    yield put(successdeleteUser(response.data));
  } catch (error) {
    yield put(failuredeleteUser(error));
  }
}
