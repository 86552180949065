import { FunctionComponent, useLayoutEffect } from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../Redux/Store/Hooks";
import { permissionsToPage } from "../Redux/Slices/UserPermission/PermissionsToPage";
import {
  clearMenu,
  listSideMenuPermission,
} from "../Redux/Slices/SideMenuPermission/ListSideMenuPermissionSlice";
import Loading from "../Components/Loading/Loading";

interface PrivateRouteProps {
  element: React.ReactNode;
}

//    Must be outside the component
const isAutendicated = () => {
  return localStorage.getItem("token");
};

const PrivateRoute: FunctionComponent<PrivateRouteProps> = ({ element }) => {
  const location = useLocation();
  const params = useParams();
  const dispatch = useAppDispatch();
  const nav = useNavigate();
  const { data: menu, isLoading } = useAppSelector(
    (state) => state.SideMenuPermission
  );
  const locationName = Object.values(params).reduce(
    (path, param) => path?.replace("/" + param, ""),
    location.pathname
  );

  useLayoutEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      dispatch(listSideMenuPermission());
    } else {
      nav("/login");
    }
    return () => {
      dispatch(clearMenu());
    };
  }, [dispatch, nav]);

  if (!isAutendicated()) {
    return <Navigate to={"/login"} replace />;
  }

  const hasViewPermission = (locationName: string) => {
    // Check if the route has view permission
    const menuItem = Object.values(menu!)
      .flat()
      .flatMap((item) => {
        if (item.items) {
          return item.items.map((menu) => menu);
        }
        return item;
      })
      .find((item) => `/${item.to}` === locationName);

    return menuItem?.permissions;
  };

  if (Object.keys(menu).length === 0) {
    return <Loading isLoading={!!isLoading} />;
  }

  if (locationName === "/login") {
    return <>{element}</>;
  }

  const permissions = hasViewPermission(locationName!);

  if (permissions?.view) {
    dispatch(permissionsToPage(permissions));
    return <>{element}</>;
  } else {
    return <Navigate to={"/login"} replace />;
  }
};

export default PrivateRoute;
