import "./Snackbar.scss";

import { FunctionComponent, useEffect, useState } from "react";
import { ISnackbarProps } from "./ISnackbar";
import { Alert, AlertTitle, Snackbar as MuiSnackbar } from "@mui/material";
import ButtonField from "../Button/ButtonField";

const Snackbar: FunctionComponent<ISnackbarProps> = (props) => {
  const {
    autoHideDuration = 6000,
    response,
    message,
    anchorOrigin = { vertical: "top", horizontal: "right" },

    icon,
    severity = response?.status ? "success" : "error",
    variant = "filled",
    color = "success",
    action,
    title,
    titleSx,
    titleClassName,
  } = props;
  const [open, setOpen] = useState(false);
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if (response && Object.keys(response).length) {
      setOpen(true);
    }
  }, [response]);
  return (
    <MuiSnackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      color={color}
      message={message}
      anchorOrigin={anchorOrigin}
      action={action}
    >
      <Alert
        icon={icon}
        severity={severity}
        variant={variant}
        action={action}
        onClose={handleClose}
      >
        {title && (
          <AlertTitle sx={titleSx} className={titleClassName}>
            {title}
          </AlertTitle>
        )}
        {response?.message}
      </Alert>
    </MuiSnackbar>
  );
};

export default Snackbar;
