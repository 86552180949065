import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IUserProfile } from "../../../Pages/UserProfile/IUserProfile";

export const EditUserProfileSlice = createSlice({
  name: "EditUserProfileSlice",
  initialState: {} as IReduxState<IUserProfile>,
  reducers: {
    EditUserProfile: (state, _: PayloadAction<IUserProfile>) => {
      state.isLoading = true;
    },
    successEditUserProfile: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureEditUserProfile: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearEditUserProfileResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  EditUserProfile,
  failureEditUserProfile,
  successEditUserProfile,
  clearEditUserProfileResponse,
} = EditUserProfileSlice.actions;

export default EditUserProfileSlice.reducer;
