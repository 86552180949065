import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IFeedBack } from "../../../Pages/FeedBack/IFeedBack";

export const ListFeedBackSlice = createSlice({
  name: "ListFeedBackSlice",
  initialState: {} as IReduxState<IFeedBack[]>,
  reducers: {
    listFeedBack: (state) => {
      state.isLoading = true;
    },
    successlistFeedBack: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failurelistFeedBack: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
  },
});

export const { failurelistFeedBack, listFeedBack, successlistFeedBack } =
  ListFeedBackSlice.actions;

export default ListFeedBackSlice.reducer;
