import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IChatbot } from "../../../Components/Chatbot/IChatbot";

export const ChatbotSlicer = createSlice({
  name: "ChatbotSlicer",
  initialState: {
    data: { data: [] },
    isLoading: false,
  } as IReduxState<{ user: string; message: string }[]>,
  reducers: {
    chatbotStart: (state, action: PayloadAction<IChatbot>) => {
      state.isLoading = true;
      state.data?.data?.push({ user: "user", message: action.payload.chat });
    },
    successChatbot: (state, action) => {
      state.isLoading = false;
      state.data?.data?.push({ user: "chatbot", message: action.payload });
    },
    failureChatbot: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearChatbotStatus: (state) => {
      state.data = { data: [] };
    },
  },
});

export const {
  chatbotStart,
  successChatbot,
  failureChatbot,
  clearChatbotStatus,
} = ChatbotSlicer.actions;

export default ChatbotSlicer.reducer;
