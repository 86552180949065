import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IInstruction } from "../../../Pages/Instruction/IInstruction";

export const DeleteInstructionSlice = createSlice({
  name: "DeleteInstruction",
  initialState: {} as IReduxState<IInstruction>,
  reducers: {
    deleteInstruction: (state, _) => {
      state.isLoading = true;
    },
    successdeleteInstruction: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failuredeleteInstruction: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearDeleteInstructionResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  deleteInstruction,
  failuredeleteInstruction,
  successdeleteInstruction,
  clearDeleteInstructionResponse,
} = DeleteInstructionSlice.actions;

export default DeleteInstructionSlice.reducer;
