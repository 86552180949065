import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IUserAdmin } from "../../../Pages/UserAdmin/IUserAdmin";

export const ListUserAdminSlice = createSlice({
  name: "ListUserAdminSlice",
  initialState: {} as IReduxState<IUserAdmin[]>,
  reducers: {
    listUserAdmin: (state, _: PayloadAction<IUserAdmin>) => {
      state.isLoading = true;
    },
    successlistUserAdmin: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failurelistUserAdmin: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
  },
});

export const { failurelistUserAdmin, listUserAdmin, successlistUserAdmin } =
  ListUserAdminSlice.actions;

export default ListUserAdminSlice.reducer;
