import { Avatar } from "@mui/material";
import { FunctionComponent } from "react";
import { AvatarsProps } from "./IAvatars";

const Avatars: FunctionComponent<AvatarsProps> = (props) => {
  const { sx, children, src, alt, srcSet } = props;
  return (
    <Avatar alt={alt} src={src} sx={sx} srcSet={srcSet} {...props}>
      {children}
    </Avatar>
  );
};

export default Avatars;
