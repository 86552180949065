import { FunctionComponent, useEffect, useRef, useState } from "react";
import "./Menu.scss";
import Table from "../../Components/Table/Table";
import CustomToolbar from "../../Components/Table/components/CustomToolbar";
import ButtonField from "../../Components/Button/ButtonField";
import { Columns } from "../../Components/Table/ITable";
import {
  GridRenderCellParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import { IMenu } from "./IMenu";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import Dialog from "../../Components/Dialog/Dialog";
import MenuForm from "./MenuForm";
import ResponseDialog from "../../Components/ResponseDialog/ResponseDialog";
import TableSearch from "../../Components/Table/components/TableSearch";
import { ReactComponent as Delete } from "../../Assets/Images/delete.svg";
import { ReactComponent as Edit } from "../../Assets/Images/edit.svg";
import { IDeleteDialog } from "../../Components/DeleteDialog/IDeleteDialog";
import IconButtonField from "../../Components/Button/IconButtonField";
import { SvgIcon } from "@mui/material";
import DeleteDialog from "../../Components/DeleteDialog/DeleteDialog";
import { ReactComponent as RefreshButton } from "../../Assets/Images/RefreshButton.svg";
import { listMenu } from "../../Redux/Slices/Menu/ListMenuSlice";
import { clearAddMenuResponse } from "../../Redux/Slices/Menu/AddMenuSlice";
import {
  clearDeleteMenuResponse,
  deleteMenu,
} from "../../Redux/Slices/Menu/DeleteMenuSlice";
import { clearEditMenuResponse } from "../../Redux/Slices/Menu/EditMenuSlice";
import { ReactComponent as Exam } from "../../Assets/Images/exams.svg";
import { ReactComponent as Dashboard } from "../../Assets/Images/Dashboard.svg";
import { ReactComponent as Menuicon } from "../../Assets/Images/material-symbols--menu.svg";
import { ReactComponent as Instruction } from "../../Assets/Images/instruction.svg";
import { ReactComponent as Masters } from "../../Assets/Images/Masters.svg";
import { ReactComponent as Settings } from "../../Assets/Images/Settings.svg";
import { ReactComponent as UserPermission } from "../../Assets/Images/UserPermission.svg";
import { ReactComponent as UserMaster } from "../../Assets/Images/UserMaster.svg";
import { ReactComponent as Dashboardicon } from "../../Assets/Images/dashboard.svg";
import { ReactComponent as ExamModel } from "../../Assets/Images/ExamModel.svg";
import { ReactComponent as QuestionType } from "../../Assets/Images/ri--questionnaire-line.svg";
import { ReactComponent as question } from "../../Assets/Images/question.svg";
import { ReactComponent as Usericon } from "../../Assets/Images/User.svg";
import { ReactComponent as Package } from "../../Assets/Images/tabler--package.svg";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { listSideMenuPermission } from "../../Redux/Slices/SideMenuPermission/ListSideMenuPermissionSlice";

interface MenuProps {}

const iconComponents: Record<string, React.ElementType> = {
  Dashboard,
  ExamModel,
  QuestionType,
  Exam,
  UserMaster,
  Menuicon,
  Instruction,
  UserPermission,
  Usericon,
  question,
  Masters,
  Settings,
  Dashboardicon,
  AccountCircleIcon,
  Package,
};

const Menu: FunctionComponent<MenuProps> = () => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [deleteDialogData, setDeleteData] = useState<IDeleteDialog>({});
  const [initialValues, setInitialValues] = useState<IMenu | {}>({
    view: true,
  });
  const dispatch = useAppDispatch();
  const { data: listMenus } = useAppSelector((state) => state.ListMenuSlice);
  const { data: addMenu } = useAppSelector((state) => state.AddMenuSlice);
  const { data: EditMenu } = useAppSelector((state) => state.EditMenuSlice);
  const { data: deleteMenus } = useAppSelector((state) => state.DeleteMenu);
  // user permisssion
  const { data: userPermissionlist } = useAppSelector(
    (state) => state.SideMenuPermission
  );

  const to = "settings";

  const filteredList = userPermissionlist.Dashboard.filter(
    (item: any) => item.to === to
  );
  const fliterdata: any = filteredList[0]?.items;
  const finaldata = fliterdata[1];
  console.log(finaldata);

  const formRef = useRef<HTMLFormElement>(null);
  const columns: Columns = [
    {
      field: "index",
      renderHeader: () => <b>S.No</b>,
      width: 90,
      headerAlign: "center",
      align: "center",
    },
    // {
    //   field: "icon",
    //   renderHeader: () => <b>Icon</b>,
    //   flex: 1,
    //   type: "string",
    //   headerAlign: "left",
    //   renderCell: (params: GridRenderCellParams<any>) => {
    //     const Iconrow = params?.row?.icon;
    //     const IconComponent = iconComponents[Iconrow];

    //     return (
    //       <div title={Iconrow}>
    //         {IconComponent ? (
    //           <SvgIcon component={IconComponent} inheritViewBox />
    //         ) : (
    //           Iconrow
    //         )}
    //       </div>
    //     );
    //   },
    // },
    {
      field: "menuname",
      renderHeader: () => <b>Menu Name</b>,
      flex: 1,
      type: "string",
      headerAlign: "left",
    },
    {
      field: "parentMenuName",
      renderHeader: () => <b>Sub Menu</b>,
      flex: 1,
      type: "string",
      headerAlign: "left",
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            {params.row.parentMenuName === null
              ? "-"
              : params.row.parentMenuName}
          </>
        );
      },
    },
    {
      field: "path",
      renderHeader: () => <b>Path Name</b>,
      flex: 1,
      type: "string",
      headerAlign: "left",
    },
    {
      field: "portal",
      renderHeader: () => <b>Portal</b>,
      flex: 1,
      type: "string",
      headerAlign: "left",
    },
    {
      field: "view",
      renderHeader: () => <b>Status</b>,
      flex: 1,
      type: "string",
      headerAlign: "left",
      cellClassName: (params) =>
        `activename ${params.row.view ? "active" : "inactive"}`,

      valueFormatter: (params) =>
        params.value === true ? "Active" : "Inactive",
    },
    {
      field: "action",
      renderHeader: () => <b>Action</b>,
      flex: 1,
      type: "actions",
      renderCell: (params: GridRenderCellParams<IMenu>) => {
        return (
          <>
            <IconButtonField
              disabled={finaldata?.permissions?.edit === false}
              onClick={() => {
                setInitialValues({ ...params.row });
                setDialogOpen(true);
              }}
            >
              <SvgIcon sx={{ fill: "none" }} component={Edit} inheritViewBox />
            </IconButtonField>
            <IconButtonField
              disabled={finaldata?.permissions?.delete === false}
              onClick={() => {
                setDeleteData({ id: params.id, name: params?.row?.menuname });
                setDeleteDialogOpen(true);
              }}
            >
              <SvgIcon component={Delete} inheritViewBox />
            </IconButtonField>
          </>
        );
      },
    },
  ];

  const Menu = Array.isArray(listMenus?.data)
    ? listMenus?.data.map((user, index) => ({ ...user, index: index + 1 }))
    : [];

  useEffect(() => {
    dispatch(listMenu());
    dispatch(listSideMenuPermission());
  }, [addMenu, EditMenu, deleteMenus, dispatch]);
  useEffect(() => {
    return () => {
      dispatch(clearAddMenuResponse());
      dispatch(clearEditMenuResponse());
      dispatch(clearDeleteMenuResponse());
    };
  }, [dispatch]);
  return (
    <>
      <Table rows={Menu ?? []} columns={columns}>
        <CustomToolbar>
          <h6>Menu</h6>
          <TableSearch />
          <ButtonField
            disabled={finaldata?.permissions?.add === false}
            className="tabel_add_new_button"
            onClick={() => setDialogOpen(true)}
            toolTip="Add new"
          >
            Add new
          </ButtonField>
          <IconButtonField
            className="refresh_btn"
            toolTip="Refresh"
            onClick={() => dispatch(listMenu())}
          >
            <SvgIcon
              className="refresh_svg_icon"
              component={RefreshButton}
              inheritViewBox
            />
          </IconButtonField>
        </CustomToolbar>
      </Table>

      <Dialog
        open={dialogOpen}
        dialogClassName="dialog_double_input"
        Dialog_Title={`${(initialValues as IMenu).id ? "Update" : "Add"} Menu`}
        Dialog_Content={
          <MenuForm
            formRef={formRef}
            initialValues={initialValues}
            setInitialValues={setInitialValues}
          />
        }
        Dialog_Actions={
          <>
            <ButtonField
              className="form_cancel_button"
              onClick={() => setDialogOpen(false)}
            >
              Cancel
            </ButtonField>
            <ButtonField
              className="form_save_button"
              onClick={() => formRef.current?.requestSubmit()}
            >
              Save
            </ButtonField>
          </>
        }
      />
      <ResponseDialog response={addMenu} parentDialog={setDialogOpen} />
      <ResponseDialog response={EditMenu} parentDialog={setDialogOpen} />
      <DeleteDialog
        deleteableName={deleteDialogData.name ?? ""}
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        deleteFunction={() => {
          dispatch(deleteMenu(deleteDialogData.id));
        }}
        response={deleteMenus}
      />
    </>
  );
};

export default Menu;
