import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IPackagelist } from "../../../Pages/Package/IPackage";

export const AddPackageSlice = createSlice({
  name: "AddPackageSlice",
  initialState: {} as IReduxState<IPackagelist>,
  reducers: {
    addPackage: (state, _: PayloadAction<IPackagelist>) => {
      state.isLoading = true;
    },
    successaddPackage: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureaddPackage: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearAddPackageResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  addPackage,
  failureaddPackage,
  successaddPackage,
  clearAddPackageResponse,
} = AddPackageSlice.actions;

export default AddPackageSlice.reducer;
