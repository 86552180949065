import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IUser } from "../../../Pages/User/IUser";

export const ListUserSlice = createSlice({
  name: "ListUserSlice",
  initialState: {} as IReduxState<IUser[]>,
  reducers: {
    listUser: (state) => {
      state.isLoading = true;
    },
    successlistUser: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failurelistUser: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
  },
});

export const { failurelistUser, listUser, successlistUser } =
  ListUserSlice.actions;

export default ListUserSlice.reducer;
