import { FunctionComponent, useEffect } from "react";
import OnlineExam from "../../Components/OnlineExam/OnlineExam";
import "./Exam_participation.scss";
import { useAppDispatch } from "../../Redux/Store/Hooks";
import { listExamID } from "../../Redux/Slices/Exam/ListExamIDSlice";

interface ExamParticipationProps {}

const ExamParticipation: FunctionComponent<ExamParticipationProps> = () => {
  const dispatch = useAppDispatch();

  //   useEffect(() => {
  //     dispatch(listExamID({ exam_id: 29 }));
  //   }, []);
  return (
    <>
      <div style={{ height: "50px" }} />
      <OnlineExam />
    </>
  );
};

export default ExamParticipation;
