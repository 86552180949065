import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IQuestionType } from "../../../Pages/QuestionType/IQuestionType";

export const EditQuestionTypeSlice = createSlice({
  name: "EditQuestionTypeSlice",
  initialState: {} as IReduxState<IQuestionType>,
  reducers: {
    EditQuestionType: (state, _: PayloadAction<IQuestionType>) => {
      state.isLoading = true;
    },
    successEditQuestionType: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureEditQuestionType: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearEditQuestionTypeResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  EditQuestionType,
  failureEditQuestionType,
  successEditQuestionType,
  clearEditQuestionTypeResponse,
} = EditQuestionTypeSlice.actions;

export default EditQuestionTypeSlice.reducer;
