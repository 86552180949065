import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import api from "../../Axios/middleware";

import { PayloadAction } from "@reduxjs/toolkit";
import { IReViewExamlistID } from "../../../Pages/Exam/IExam";
import {
  failurelistReViewExamID,
  successlistReViewExamID,
} from "./ListReViewExamSlice";

// list exam id

const ListReViewExamIDApi = (action: PayloadAction<IReViewExamlistID>) => {
  return api.get("/v1/api/exam/reviewTest", { params: action.payload });
};

export function* ListReViewExamIDCall(
  action: PayloadAction<IReViewExamlistID>
) {
  try {
    const response: AxiosResponse = yield call(ListReViewExamIDApi, action);
    yield put(successlistReViewExamID(response.data));
  } catch (error) {
    yield put(failurelistReViewExamID(error));
  }
}
