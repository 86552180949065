import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IUserAdmin } from "../../../Pages/UserAdmin/IUserAdmin";

export const AddUserAdminSlice = createSlice({
  name: "AddUserAdminSlice",
  initialState: {} as IReduxState<IUserAdmin>,
  reducers: {
    addUserAdmin: (state, _: PayloadAction<IUserAdmin>) => {
      state.isLoading = true;
    },
    successaddUserAdmin: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureaddUserAdmin: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearAddUserAdminResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  addUserAdmin,
  failureaddUserAdmin,
  successaddUserAdmin,
  clearAddUserAdminResponse,
} = AddUserAdminSlice.actions;

export default AddUserAdminSlice.reducer;
