import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IPendingExamList } from "../../../Pages/Package/IPackage";

export const PendingExamList = createSlice({
  name: "PendingExamList",
  initialState: {} as IReduxState<IPendingExamList[]>,
  reducers: {
    pendingExamList: (state) => {
      state.isLoading = true;
    },
    successpendingExamList: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failurependingExamList: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  failurependingExamList,
  pendingExamList,
  successpendingExamList,
} = PendingExamList.actions;

export default PendingExamList.reducer;
