import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import api from "../../Axios/middleware";
import {
  failureaddQuestionType,
  successaddQuestionType,
} from "./AddQuestionTypeSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { IQuestionType } from "../../../Pages/QuestionType/IQuestionType";

import {
  failuredeleteQuestionType,
  successdeleteQuestionType,
} from "./DeleteQuestionTypeSlice";
import {
  failurelistQuestionType,
  successlistQuestionType,
} from "./ListQuestionTypeSlice";
import {
  failureEditQuestionType,
  successEditQuestionType,
} from "./EditQuestionTypeSlice";

// list
const ListQuestionTypeApi = () => {
  return api.get("/v1/api/questionType/questionType");
};

export function* ListQuestionTypeCall() {
  try {
    const response: AxiosResponse = yield call(ListQuestionTypeApi);
    yield put(successlistQuestionType(response.data));
  } catch (error) {
    yield put(failurelistQuestionType(error));
  }
}

// add
const AddQuestionTypeApi = (action: PayloadAction<IQuestionType>) => {
  return api.post("/v1/api/questionType/create-question", action.payload);
};

export function* AddQuestionTypeCall(action: PayloadAction<IQuestionType>) {
  try {
    const response: AxiosResponse = yield call(AddQuestionTypeApi, action);
    yield put(successaddQuestionType(response.data));
  } catch (error) {
    yield put(failureaddQuestionType(error));
  }
}

// edit
const EditQuestionTypeApi = (action: PayloadAction<IQuestionType>) => {
  return api.put("/v1/api/questionType/update/", action.payload, {
    params: { id: action.payload.id },
  });
};

export function* EditQuestionTypeCall(action: PayloadAction<IQuestionType>) {
  try {
    const response: AxiosResponse = yield call(EditQuestionTypeApi, action);
    yield put(successEditQuestionType(response.data));
  } catch (error) {
    yield put(failureEditQuestionType(error));
  }
}

// delete
const DeleteQuestionTypeApi = (action: PayloadAction<number>) => {
  return api.delete("/v1/api/questionType/delete/", {
    params: { id: action.payload },
  });
};

export function* DeleteQuestionTypeCall(action: PayloadAction<number>) {
  try {
    const response: AxiosResponse = yield call(DeleteQuestionTypeApi, action);
    yield put(successdeleteQuestionType(response.data));
  } catch (error) {
    yield put(failuredeleteQuestionType(error));
  }
}
