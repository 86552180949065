import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import { successChatbot, failureChatbot } from "./ChatbotSlicer";
import { IChatbot } from "../../../Components/Chatbot/IChatbot";
import apichatbot from "../../Axios/middlewareSecond";

const ChatbotApi = (action: PayloadAction<IChatbot>) => {
  return apichatbot.post("/v1/api/gpt/chat", action.payload);
};

export function* ChatbotCall(action: PayloadAction<IChatbot>) {
  try {
    const respons: AxiosResponse = yield call(ChatbotApi, action);
    yield put(successChatbot(respons.data));
  } catch (error) {
    yield put(failureChatbot(error));
  }
}
