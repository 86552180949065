import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IMenu } from "../../../Pages/Menu/IMenu";

export const ListMenuSlice = createSlice({
  name: "ListMenuSlice",
  initialState: {} as IReduxState<IMenu[]>,
  reducers: {
    listMenu: (state) => {
      state.isLoading = true;
    },
    successlistMenu: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failurelistMenu: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
  },
});

export const { failurelistMenu, listMenu, successlistMenu } =
  ListMenuSlice.actions;

export default ListMenuSlice.reducer;
