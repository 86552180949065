import { createSlice } from "@reduxjs/toolkit";
import { ITheme } from "./ITheme";

export const ThemeSlice = createSlice({
  name: "Theme",
  initialState: {
    data: {
      primary_color: "#ff000f",
      secondary_color: "#fff",
      tertiary_color: "#000",
      background_color: "#f5f5f5",
      text_color: "#b8a0a0",
      success_color: "#00b913",
      error_color: "#bd0000",
      warning_color: "#b99000",
      info_color: "#17a2b8",
      link_color: "rgba(36, 32, 209, 1)",
    },
  } as { data: ITheme },
  //   initialState: {} as IReduxState<string>,
  reducers: {
    themeUpdate: (state, action) => {
      //   state.isLoading = true;
      state.data = { ...state.data, ...action.payload };
    },
    successTheme: (state, action) => {
      //   state.data = action.payload;
      //   state.isLoading = false;
    },
    failureTheme: (state, action) => {
      //   state.data = action.payload;
      //   state.isLoading = false;
    },
    clearTheme: (state) => {
      //   state.data = {};
    },
  },
});

export const { failureTheme, themeUpdate, successTheme, clearTheme } =
  ThemeSlice.actions;

export default ThemeSlice.reducer;
