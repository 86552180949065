import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import api from "../../Axios/middleware";

import { PayloadAction } from "@reduxjs/toolkit";
import { IReExamlistID } from "../../../Pages/Exam/IExam";
import { failurelistReExamID, successlistReExamID } from "./ListReExamSlice";

// list exam id

const ListReExamIDApi = (action: PayloadAction<IReExamlistID>) => {
  return api.get("/v1/api/exam/retakeTest", { params: action.payload });
};

export function* ListReExamIDCall(action: PayloadAction<IReExamlistID>) {
  try {
    const response: AxiosResponse = yield call(ListReExamIDApi, action);
    yield put(successlistReExamID(response.data));
  } catch (error) {
    yield put(failurelistReExamID(error));
  }
}
