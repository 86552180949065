import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { create } from "lodash";

export const ForgotPassword = createSlice({
  name: "ForgotPassword",
  initialState: {} as IReduxState<{}>,
  reducers: {
    ForgotPasswordStart: (state, _) => {
      state.isLoading = true;
    },
    SuccessForgotPassword: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    FailureForgotPassword: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    ClearForgotPassword: (state) => {
      state.data = {};
    },
  },
});

export const {
  ClearForgotPassword,
  FailureForgotPassword,
  ForgotPasswordStart,
  SuccessForgotPassword,
} = ForgotPassword.actions;

export default ForgotPassword.reducer;
