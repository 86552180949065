import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import api from "../../Axios/middleware";
import { responseSideMenuPermission } from "./ListSideMenuPermissionSlice";
import { sessionExpired } from "../SessionExpired/SessionExpired";

const SideMenuPermissionApi = () => {
  return api.get("/v1/api/role-permission/get_menus");
};

export function* SideMenuPermissionCall() {
  try {    
    const response: AxiosResponse = yield call(SideMenuPermissionApi);

    yield put(responseSideMenuPermission(response.data.data));
  } catch (error) {
    yield put(responseSideMenuPermission(null));
    // yield put(sessionExpired(true));
  }
}
