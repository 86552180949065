import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IUserAdmin } from "../../../Pages/UserAdmin/IUserAdmin";

export const DeleteUserAdminSlice = createSlice({
  name: "DeleteUserAdmin",
  initialState: {} as IReduxState<IUserAdmin>,
  reducers: {
    deleteUserAdmin: (state, _) => {
      state.isLoading = true;
    },
    successdeleteUserAdmin: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failuredeleteUserAdmin: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearDeleteUserAdminResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  deleteUserAdmin,
  failuredeleteUserAdmin,
  successdeleteUserAdmin,
  clearDeleteUserAdminResponse,
} = DeleteUserAdminSlice.actions;

export default DeleteUserAdminSlice.reducer;
