import { FunctionComponent, memo, useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { IMenu } from "./IMenu";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import TextInputFieldC from "../../Components/TextInput/TextInputFieldC";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import { addMenu } from "../../Redux/Slices/Menu/AddMenuSlice";
import { EditMenu } from "../../Redux/Slices/Menu/EditMenuSlice";
import Select from "../../Components/Select/AutoComplete";
import { AutocompleteOption } from "../../Components/Select/ISelectProps";
import { listMenu } from "../../Redux/Slices/Menu/ListMenuSlice";

interface MenuFormProps {
  formRef: React.RefObject<HTMLFormElement>;
  initialValues: IMenu;
  setInitialValues: React.Dispatch<React.SetStateAction<IMenu>>;
}

const MenuForm: FunctionComponent<MenuFormProps> = (props) => {
  const dispatch = useAppDispatch();
  const { data: listMenus } = useAppSelector((state) => state.ListMenuSlice);
  const MenuList = Array.isArray(listMenus?.data)
    ? listMenus?.data.map((listMenus) => ({
        label: listMenus.menuname!,
        value: listMenus.id,
      }))
    : [];

  const userSchema = z.object({
    id: z.number().optional(),
    menuname: z
      .string({ message: "Please enter menu name" })
      .min(1, { message: "Please enter menu name" }),
    icon: z
      .string({ message: "Please enter icon name" })
      .min(1, { message: "Please enter icon name" }),
    path: z
      .string({ message: "Please enter path name" })
      .min(1, { message: "Please enter path name" }),
    portal: z.string({ message: "Please select portal" }),
    parentId: z.number().optional().nullable(),
    view: z.boolean({
      message: "Please select the status",
    }),
  });
  const methods = useForm<IMenu>({
    defaultValues: {
      ...props.initialValues,
      view: props.initialValues.id ? props.initialValues.view : true,
    },
    resolver: zodResolver(userSchema),
    mode: "onChange",
  });

  const onSubmit: SubmitHandler<IMenu> = (val) => {
    if (val.id) {
      dispatch(EditMenu(val));
    } else {
      dispatch(addMenu(val));
    }
  };

  useEffect(() => {
    // dispatch(listMenu());
    return () => {
      props.setInitialValues({});
    };
  }, [dispatch, props]);

  // Status list
  const MenuSatus = [
    {
      label: "Active",
      value: true,
    },

    {
      label: "Inactive",
      value: false,
    },
  ];

  // Status list
  const MenuPortal = [
    {
      label: "Admin",
      value: "ADMIN",
    },

    {
      label: "User",
      value: "USER",
    },
  ];

  return (
    <FormProvider {...methods}>
      <form
        ref={props.formRef}
        onSubmit={methods.handleSubmit(onSubmit)}
        className="form_double_input"
      >
        <TextInputFieldC name="menuname" label="Name" type="text" />
        <TextInputFieldC name="icon" label="Icon Name" type="text" />
        <TextInputFieldC name="path" label="Path Name" type="text" />
        <Select
          name="portal"
          label="Portal"
          options={MenuPortal}
          value={
            MenuPortal?.find(
              (role) => role.value === methods.getValues("portal")
            ) ?? null
          }
          onChange={(_, value) => {
            const val = value as AutocompleteOption;
            methods.setValue("portal", val?.value, { shouldValidate: true });
          }}
        />
        <Select
          name="parentId"
          label="Main Menu"
          options={MenuList}
          value={
            MenuList?.find(
              (role) => role.value! === methods.getValues("parentId")
            ) ?? null
          }
          onChange={(_, value) => {
            const val = value as AutocompleteOption;
            methods.setValue("parentId", val?.value!, { shouldValidate: true });
          }}
        />
        <Select
          name="view"
          label="Status"
          options={MenuSatus}
          value={
            MenuSatus?.find(
              (role) => role.value === methods.getValues("view")
            ) ?? null
          }
          onChange={(_, value) => {
            const val = value as AutocompleteOption;
            methods.setValue("view", val?.value, { shouldValidate: true });
          }}
        />
      </form>
    </FormProvider>
  );
};

export default memo(MenuForm);
