import { FunctionComponent, memo, useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { IExam } from "./IExam";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import TextInputFieldC from "../../Components/TextInput/TextInputFieldC";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import { addExam } from "../../Redux/Slices/Exam/AddExamSlice";
import { EditExam } from "../../Redux/Slices/Exam/EditExamSlice";
import Select from "../../Components/Select/AutoComplete";
import { AutocompleteOption } from "../../Components/Select/ISelectProps";
import TextAreaInputField from "../../Components/TextAreaInput/TextAreaInputField";
import {
  Button,
  TextField,
  IconButton,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import NumberInputField from "../../Components/NumberInput/NumberInputField";
interface ExamFormProps {
  formRef: React.RefObject<HTMLFormElement>;
  initialValues: IExam;
  setInitialValues: React.Dispatch<React.SetStateAction<IExam>>;
}

const ExamForm: FunctionComponent<ExamFormProps> = (props) => {
  const dispatch = useAppDispatch();

  const { data: listExams } = useAppSelector(
    (state) => state.ListExamModelSlice
  );

  const ExamList = Array.isArray(listExams?.data)
    ? listExams?.data.map((listExams) => ({
        label: listExams.model_name!,
        value: listExams.id,
      }))
    : [];
  const userSchema = z.object({
    id: z.number().optional(),
    name: z
      .string({ message: "Please enter exam name" })
      .min(1, { message: "Please enter exam name" }),
    exam_model_id: z.number({ message: "Please select exam model" }),
    description: z
      .string({ message: "Please enter description" })
      .min(1, { message: "Please enter description" }),
    status: z.boolean({
      message: "Please select the status",
    }),
    mock_exam: z.boolean().optional(),
    total_timing: z
      .number({ message: "Please enter the duration of the exam" })
      .refine((val) => val > 0, {
        message: "Exam duration must be greater than 0 mintues",
      }),
    instruction: z
      .array(
        z
          .string({ message: "Please enter the instruction" })
          .min(1, "Please enter the instruction"),
        { message: "Please enter the instruction" }
      )
      .min(1, "Add at least one instruction")
      .max(20, "Cannot add more than 20 instructions"), // Limit instructions
  });

  const methods = useForm<IExam>({
    defaultValues: {
      ...props.initialValues,
      mock_exam: props.initialValues.mock_exam ? true : false,
      status: props.initialValues.id ? props.initialValues.status : true,
    },
    resolver: zodResolver(userSchema),
    mode: "onChange",
  });

  const onSubmit: SubmitHandler<IExam> = (val) => {
    console.log(val);

    if (val.id) {
      dispatch(EditExam(val));
    } else {
      dispatch(addExam(val));
    }
  };

  useEffect(() => {
    return () => {
      props.setInitialValues({});
    };
  }, [dispatch, props]);

  // Status list
  const ExamSatus = [
    {
      label: "Active",
      value: true,
    },

    {
      label: "Inactive",
      value: false,
    },
  ];

  // Status exam list
  const ExamtypeSatus = [
    {
      label: "Mock Exam",
      value: true,
    },

    {
      label: "Not Mock Exam",
      value: false,
    },
  ];

  const { setValue, getValues } = methods;
  const [instructionInput, setInstructionInput] = useState<string>("");
  const [instructions, setInstructions] = useState<string[]>([]);

  // Add instruction handler
  const handleAddInstruction = () => {
    const currentInstructions = getValues("instruction") || []; // Ensure it's an array
    if (instructionInput!.trim() && currentInstructions!.length! < 20) {
      const updatedInstructions = [...currentInstructions, instructionInput];
      setInstructions(updatedInstructions); // Update local state
      setValue("instruction", updatedInstructions, { shouldValidate: true }); // Update instructions field
      setInstructionInput(""); // Clear input field
    }
  };

  // Delete instruction handler
  const handleDeleteInstruction = (index: number) => {
    const currentInstructions = getValues("instruction");
    const updatedInstructions =
      currentInstructions && currentInstructions?.filter((_, i) => i !== index);
    setInstructions(updatedInstructions!); // Update local state
    setValue("instruction", updatedInstructions); // Update instructions field
    methods.trigger("instruction"); // Force re-render to reflect changes
  };
  return (
    <FormProvider {...methods}>
      <form
        ref={props.formRef}
        onSubmit={methods.handleSubmit(onSubmit)}
        className="form_double_input"
      >
        <TextInputFieldC name="name" label="Exam Name" type="text" />
        <Select
          name="exam_model_id"
          label="Exam Model"
          options={ExamList ?? []}
          value={
            ExamList?.find(
              (list) => list.value === methods.getValues("exam_model_id")
            ) ?? null
          }
          onChange={(_, value) => {
            const val = value as AutocompleteOption;
            methods.setValue("exam_model_id", val?.value, {
              shouldValidate: true,
            });
          }}
        />
        <TextAreaInputField
          name="description"
          label="description"
          type="text"
        />

        <NumberInputField
          name="total_timing"
          label="Duration in Mintues"
          type="number"
          sx={{ width: "100%" }}
        />

        <Select
          name="status"
          label="Status"
          options={ExamSatus}
          value={
            ExamSatus?.find(
              (role) => role.value === methods.getValues("status")
            ) ?? null
          }
          onChange={(_, value) => {
            const val = value as AutocompleteOption;
            methods.setValue("status", val?.value, { shouldValidate: true });
          }}
        />
        <FormControlLabel
          control={
            <Checkbox
              sx={{
                marginLeft: "8px",
                transform: "scale(1.5)", // Scale the size
              }}
              checked={methods.getValues("mock_exam") === true}
              onChange={() => {
                methods.setValue("mock_exam", !methods.getValues("mock_exam"), {
                  shouldValidate: true,
                });
              }}
            />
          }
          label="Mock Exam"
        />
        {/* Instruction Input Field with TextArea */}
        <Box display="flex">
          <TextField
            label="Instruction"
            value={instructionInput}
            onChange={(e) => setInstructionInput(e.target.value)}
            fullWidth
            multiline
            error={!!methods.formState.errors.instruction}
            helperText={
              !!methods.formState.errors.instruction
                ? String(methods.formState.errors.instruction.message)
                : ""
            }
            minRows={1}
            maxRows={4} // Control how many rows the textarea will expand to
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddInstruction}
            sx={{
              ml: 1,
              width: "10px",
              height: "4rem",
              bgcolor: "primary.main", // Set the background color to primary
              ":hover": {
                bgcolor: "primary.main", // Keep the hover background color the same
              },
            }}
          >
            <AddIcon sx={{ color: "white" }} />
          </Button>
        </Box>
        {/* Render Instructions */}
        {getValues("instruction")?.map((instruction, index) => (
          <Box
            key={index}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            bgcolor="lightgrey"
            p={0.3}
            my={1}
            borderRadius="4px"
            sx={{
              marginBottom: "0px",
              marginTop: "0px",

              ":hover button": { visibility: "visible" }, // Visibility for smoother transitions
            }}
          >
            <Typography
              sx={{
                flexGrow: 1, // Make text take available space
                wordWrap: "break-word", // Ensure long text wraps
                overflow: "hidden",
                padding: "10px",
              }}
            >
              {instruction}
            </Typography>
            <IconButton onClick={() => handleDeleteInstruction(index)}>
              <DeleteIcon />
            </IconButton>
          </Box>
        ))}
      </form>
    </FormProvider>
  );
};

export default memo(ExamForm);
