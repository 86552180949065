import { FunctionComponent, useEffect, useRef, useState } from "react";
import "./Options.scss";
import Table from "../../Components/Table/Table";
import CustomToolbar from "../../Components/Table/components/CustomToolbar";
import ButtonField from "../../Components/Button/ButtonField";
import { Columns } from "../../Components/Table/ITable";
import {
  GridRenderCellParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import { IOptions } from "./IOptions";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import Dialog from "../../Components/Dialog/Dialog";
import OptionsForm from "./OptionsForm";
import ResponseDialog from "../../Components/ResponseDialog/ResponseDialog";
import TableSearch from "../../Components/Table/components/TableSearch";
import { ReactComponent as Delete } from "../../Assets/Images/delete.svg";
import { ReactComponent as Edit } from "../../Assets/Images/edit.svg";
import { IDeleteDialog } from "../../Components/DeleteDialog/IDeleteDialog";
import IconButtonField from "../../Components/Button/IconButtonField";
import { SvgIcon } from "@mui/material";
import DeleteDialog from "../../Components/DeleteDialog/DeleteDialog";
import { ReactComponent as RefreshButton } from "../../Assets/Images/RefreshButton.svg";
import { listOptions } from "../../Redux/Slices/Options/ListOptionsSlice";
import { clearAddOptionsResponse } from "../../Redux/Slices/Options/AddOptionsSlice";
import {
  clearDeleteOptionsResponse,
  deleteOptions,
} from "../../Redux/Slices/Options/DeleteOptionsSlice";
import { clearEditOptionsResponse } from "../../Redux/Slices/Options/EditOptionsSlice";
import { listQuestions } from "../../Redux/Slices/Questions/ListQuestionsSlice";

interface OptionsProps {}

const Options: FunctionComponent<OptionsProps> = () => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [deleteDialogData, setDeleteData] = useState<IDeleteDialog>({});
  const [initialValues, setInitialValues] = useState<IOptions | {}>({});
  const dispatch = useAppDispatch();
  const { data: listOptionss } = useAppSelector(
    (state) => state.ListOptionsSlice
  );
  const { data: addOptions } = useAppSelector((state) => state.AddOptionsSlice);
  const { data: EditOptions } = useAppSelector(
    (state) => state.EditOptionsSlice
  );
  const { data: deleteOptionss } = useAppSelector(
    (state) => state.DeleteOptions
  );
  const formRef = useRef<HTMLFormElement>(null);
  const columns: Columns = [
    {
      field: "index",
      renderHeader: () => <b>S.No</b>,
      width: 90,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "question_text",
      renderHeader: () => <b>Question</b>,
      flex: 1,
      type: "string",
      headerAlign: "left",
    },
    {
      field: "option_type",
      renderHeader: () => <b>Option Type</b>,
      flex: 1,
      type: "string",
      headerAlign: "left",
    },

    {
      field: "option",
      renderHeader: () => <b>Options</b>,
      flex: 1,
      type: "string",
      headerAlign: "left",
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            {params.row.option === null || params.row.option === ""
              ? "-"
              : params.row.option}
          </>
        );
      },
    },
    {
      field: "option_url",
      renderHeader: () => <b>Options URL</b>,
      flex: 1,
      type: "string",
      headerAlign: "left",
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            {params.row.option_url === null || params.row.option_url === ""
              ? "-"
              : params.row.option_url}
          </>
        );
      },
    },
    {
      field: "answer",
      renderHeader: () => <b>Answer</b>,
      flex: 1,
      type: "string",
      headerAlign: "left",
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            <div
              style={{
                color: params.row.answer === true ? "green" : "red",
                border:
                  params.row.answer === true
                    ? "2px solid green"
                    : "2px solid red",
                padding: "5px",
                borderRadius: "5px",
              }}
            >
              {params.row.answer === true ? "Correct" : "Wrong"}
            </div>
          </>
        );
      },
    },
    {
      field: "action",
      renderHeader: () => <b>Action</b>,
      flex: 1,
      type: "actions",
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            <IconButtonField
              onClick={() => {
                setInitialValues({ ...params.row });
                setDialogOpen(true);
              }}
            >
              <SvgIcon sx={{ fill: "none" }} component={Edit} inheritViewBox />
            </IconButtonField>
            <IconButtonField
              onClick={() => {
                setDeleteData({ id: params.id, name: params?.row?.name });
                setDeleteDialogOpen(true);
              }}
            >
              <SvgIcon component={Delete} inheritViewBox />
            </IconButtonField>
          </>
        );
      },
    },
  ];

  const Options = Array.isArray(listOptionss?.data)
    ? listOptionss?.data.map((user, index) => ({ ...user, index: index + 1 }))
    : [];

  useEffect(() => {
    dispatch(listOptions());
    dispatch(listQuestions());
  }, [addOptions, EditOptions, deleteOptionss, dispatch]);
  useEffect(() => {
    return () => {
      dispatch(clearAddOptionsResponse());
      dispatch(clearEditOptionsResponse());
      dispatch(clearDeleteOptionsResponse());
    };
  }, [dispatch]);
  return (
    <>
      <Table rows={Options ?? []} columns={columns}>
        <CustomToolbar>
          <h6>Options</h6>
          <TableSearch />
          <ButtonField
            className="tabel_add_new_button"
            onClick={() => setDialogOpen(true)}
            toolTip="Add new"
          >
            Add new
          </ButtonField>
          <IconButtonField
            className="refresh_btn"
            toolTip="Refresh"
            onClick={() => dispatch(listOptions())}
          >
            <SvgIcon
              className="refresh_svg_icon"
              component={RefreshButton}
              inheritViewBox
            />
          </IconButtonField>
        </CustomToolbar>
      </Table>

      <Dialog
        open={dialogOpen}
        dialogClassName="dialog"
        Dialog_Title={`${
          (initialValues as IOptions).id ? "Update" : "Add"
        } Options`}
        dialogContentClassName="dialog_content"
        Dialog_Content={
          <OptionsForm
            formRef={formRef}
            initialValues={initialValues}
            setInitialValues={setInitialValues}
          />
        }
        Dialog_Actions={
          <>
            <ButtonField
              className="form_cancel_button"
              onClick={() => setDialogOpen(false)}
            >
              Cancel
            </ButtonField>
            <ButtonField
              className="form_save_button"
              onClick={() => formRef.current?.requestSubmit()}
            >
              Save
            </ButtonField>
          </>
        }
      />
      <ResponseDialog response={addOptions} parentDialog={setDialogOpen} />
      <ResponseDialog response={EditOptions} parentDialog={setDialogOpen} />
      <DeleteDialog
        deleteableName={deleteDialogData.name ?? ""}
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        deleteFunction={() => {
          dispatch(deleteOptions(deleteDialogData.id));
        }}
        response={deleteOptionss}
      />
    </>
  );
};

export default Options;
