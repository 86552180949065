import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";

export const TestSlice = createSlice({
  name: "Test",
  initialState: {
    data: {},
    isLoading: false,
  } as IReduxState<null>,
  reducers: {
    testCall: (state, action: PayloadAction<number>) => {
      state.isLoading = !!action.payload;
    },
  },
});

export const { testCall } = TestSlice.actions;

export default TestSlice.reducer;
