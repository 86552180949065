import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IDashboardCards } from "../../../Pages/Dashboard/IDashboard";

export const DashboardCardSlice = createSlice({
  name: "DashboardCard",
  initialState: {} as IReduxState<IDashboardCards>,
  reducers: {
    dashboardCard: (state) => {
      state.isLoading = true;
    },
    successDashboardCard: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureDashboardCard: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearDashboardCard: (state) => {
      state.data = {};
    },
  },
});

export const {
  failureDashboardCard,
  successDashboardCard,
  clearDashboardCard,
  dashboardCard,
} = DashboardCardSlice.actions;

export default DashboardCardSlice.reducer;
