import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import api from "../../Axios/middleware";
import { failureaddCourses, successaddCourses } from "./AddCoursesSlice";
import { PayloadAction } from "@reduxjs/toolkit";

import {
  failuredeleteCourses,
  successdeleteCourses,
} from "./DeleteCoursesSlice";
import { failurelistCourses, successlistCourses } from "./ListCoursesSlice";
import { failureEditCourses, successEditCourses } from "./EditCoursesSlice";
import { ICourseslist } from "../../../Pages/Courses/ICourses";

// list
const ListCoursesApi = () => {
  return api.get("/v1/api/package/courses");
};

export function* ListCoursesCall() {
  try {
    const response: AxiosResponse = yield call(ListCoursesApi);
    yield put(successlistCourses(response.data));
  } catch (error) {
    yield put(failurelistCourses(error));
  }
}

// add
const AddCoursesApi = (action: PayloadAction<ICourseslist>) => {
  return api.post("/v1/api/package/courses/add", action.payload);
};

export function* AddCoursesCall(action: PayloadAction<ICourseslist>) {
  try {
    const response: AxiosResponse = yield call(AddCoursesApi, action);
    yield put(successaddCourses(response.data));
  } catch (error) {
    yield put(failureaddCourses(error));
  }
}

// edit
const EditCoursesApi = (action: PayloadAction<ICourseslist>) => {
  return api.put("/v1/api/package/course/update", action.payload, {
    params: { id: action.payload.id },
  });
};

export function* EditCoursesCall(action: PayloadAction<ICourseslist>) {
  try {
    const response: AxiosResponse = yield call(EditCoursesApi, action);
    yield put(successEditCourses(response.data));
  } catch (error) {
    yield put(failureEditCourses(error));
  }
}

// delete
const DeleteCoursesApi = (action: PayloadAction<number>) => {
  return api.delete("/v1/api/package/course/delete", {
    params: { id: action.payload },
  });
};

export function* DeleteCoursesCall(action: PayloadAction<number>) {
  try {
    const response: AxiosResponse = yield call(DeleteCoursesApi, action);
    yield put(successdeleteCourses(response.data));
  } catch (error) {
    yield put(failuredeleteCourses(error));
  }
}
