import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IQuestionType } from "../../../Pages/QuestionType/IQuestionType";

export const DeleteQuestionTypeSlice = createSlice({
  name: "DeleteQuestionType",
  initialState: {} as IReduxState<IQuestionType>,
  reducers: {
    deleteQuestionType: (state, _) => {
      state.isLoading = true;
    },
    successdeleteQuestionType: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failuredeleteQuestionType: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearDeleteQuestionTypeResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  deleteQuestionType,
  failuredeleteQuestionType,
  successdeleteQuestionType,
  clearDeleteQuestionTypeResponse,
} = DeleteQuestionTypeSlice.actions;

export default DeleteQuestionTypeSlice.reducer;
