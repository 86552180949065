import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { ILoginResponse } from "../../../Pages/Login/ILogin";

export const GoogleLoginSlicer = createSlice({
  name: "GoogleLoginSlicer",
  initialState: {
    data: {},
    isLoading: false,
  } as IReduxState<ILoginResponse>,
  reducers: {
    GoogleloginStart: (state, _) => {
      state.isLoading = true;
    },
    successGoogleLogin: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    failureGoogleLogin: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearGoogleLoginStatus: (state) => {
      state.data = {};
    },
  },
});

export const {
  GoogleloginStart,
  successGoogleLogin,
  failureGoogleLogin,
  clearGoogleLoginStatus,
} = GoogleLoginSlicer.actions;

export default GoogleLoginSlicer.reducer;
