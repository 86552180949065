import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IOptions } from "../../../Pages/Options/IOptions";

export const AddOptionsSlice = createSlice({
  name: "AddOptionsSlice",
  initialState: {} as IReduxState<IOptions>,
  reducers: {
    addOptions: (state, _: PayloadAction<IOptions>) => {
      state.isLoading = true;
    },
    successaddOptions: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureaddOptions: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearAddOptionsResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  addOptions,
  failureaddOptions,
  successaddOptions,
  clearAddOptionsResponse,
} = AddOptionsSlice.actions;

export default AddOptionsSlice.reducer;
